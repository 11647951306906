import instance from "../../api/axiosInstance";
import { createFormData } from "../../utils/common";


export const fetchConsumersDetails=(data)=>{
    // return instance.get("consumer/get_all")
    let _payload=createFormData({limit:data.itemsPerPage,offset:data.currentPage})
    return instance.post("consumer/getdataforpagination",_payload)

} 
export const saveConsumersDetails=(payload)=>{
    let _payload=createFormData(payload)
    return instance.post("consumer/save",_payload)
} 
export const getConsumerDetailsById=(payload)=>{
    return instance.post("consumer/getById",payload)
} 
export const deleteConsumerDetailsById=(payload)=>{
    let _payload=createFormData(payload)
    return instance.post("consumer/deleteList",_payload)
} 
export const editConsumersDetails=(payload)=>{
    let _payload=createFormData(payload)
    return instance.post("consumer/editList",_payload)
    
} 

export const getConsumerListSearch=(data)=>{
    let _payload=createFormData({limit:data.itemsPerPage,offset:data.currentPage,number:data.meterNumber})
    return instance.post("meter/getmeterdatafordisplaysearch",_payload)
} 




