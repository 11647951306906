import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { deleteTechnicianDetailsById, editTechniciansDetails, fetchTechniciansDetails, saveDocs, saveTechniciansDetails } from "./technician.api";

const initialState={
    loading:false,
    error:"",
    technicians: [],
    isDeletedSuccessful:false,
    isSavedSuccessful:false,
    filterTechnicianListData:[]
    

   }
export const fetchTechnicians=createAsyncThunk(
     "technicians/fetchTechnicians",()=>fetchTechniciansDetails()
   );

export const saveTechnician=createAsyncThunk(
    "technicians/saveTechnicians",(data)=>saveTechniciansDetails(data)
  );
  export const deleteTechnician=createAsyncThunk(
    "technicians/deleteTechnicians",(id)=>deleteTechnicianDetailsById(id)
  )

  export const editTechnician=createAsyncThunk(
    "technicians/editTechnicians",(id)=>editTechniciansDetails(id)
  )
  export const uploadDocs=createAsyncThunk(
    "technicians/uploadCover",(path)=>saveDocs(path)
  )
  


const technicianSlice=createSlice({
name:'technician',
initialState,
reducers:{
  setTechnicianFilter:(state,action)=>{
      state.technicians= action.payload;
  },

},
extraReducers: (builder) => {
  builder
    .addCase(fetchTechnicians.pending,(state)=>{
      state.loading=true;
      state.error="";
    })
    .addCase(fetchTechnicians.fulfilled,(state,action)=> {
      state.error = "";
        state.loading = false;
        state.isDeletedSuccessful=false;
        state.isSavedSuccessful=false;
        state.technicians = action.payload?.data.data;
        state.filterTechnicianListData=action.payload?.data.data;
    })
    .addCase(fetchTechnicians.rejected, (state, action) =>{
      state.loading = false;
      state.error = action.error.message || "Something went wrong!";
    })  
//Save technician
   .addCase(saveTechnician.pending,(state)=>{
        state.loading=true;
        state.error="";
      })
      .addCase(saveTechnician.fulfilled,(state,action)=> {
          state.loading = false;
          state.isSavedSuccessful=true;
         
      })
      .addCase(saveTechnician.rejected, (state, action) =>{
        state.loading = false;
        state.error = action.error.message || "Something went wrong!";
      }) 
//Delete Technician
      .addCase(deleteTechnician.pending,(state)=>{
        state.loading=true;
        state.error="";
      })
      .addCase(deleteTechnician.fulfilled,(state,action)=> {
          state.loading = false;
          state.isDeletedSuccessful=true;
         
      })
      .addCase(deleteTechnician.rejected, (state, action) =>{
        state.loading = false;
        state.error = action.error.message || "Something went wrong!";
      }) 
      //Edit Technician
      .addCase(editTechnician.pending,(state)=>{
        state.loading=true;
        state.error="";
      })
      .addCase(editTechnician.fulfilled,(state,action)=> {
          state.loading = false;
          state.isSavedSuccessful=true;
         
      })
      .addCase(editTechnician.rejected, (state, action) =>{
        state.loading = false;
        state.error = action.error.message || "Something went wrong!";
      }) 

      // save Cover image

      .addCase(uploadDocs.pending,(state)=>{
        state.coverImageSaved=false;
        state.error="";
      })
      .addCase(uploadDocs.fulfilled,(state,action)=> {
      
          state.coverImageSaved = true;
          state.coverImagePath=action.payload.data.data;
         
      })
      .addCase(uploadDocs.rejected, (state, action) =>{
        state.coverImageSaved = false;
        state.error = action.error.message || "Something went wrong!";
      }) 
}
})
export default technicianSlice.reducer;
export const {setTechnicianFilter } = technicianSlice.actions
