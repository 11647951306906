import instance from "../../api/axiosInstance";
import { createFormData } from "../../utils/common";


export const fetchDiscomlabDetails=()=>{
    return instance.get("discomlab/get_all")
} 
export const saveDiscomlabDetails=(payload)=>{
    let _payload=createFormData(payload)
    return instance.post("discomlab/save",_payload)
} 
export const getDiscomlabDetailsById=(payload)=>{
    return instance.post("discomlab/getById",payload)
} 
export const deleteDetailsById=(payload)=>{
    let _payload=createFormData(payload)
    return instance.post("discomlab/deleteList",_payload)
} 
export const editDiscomlabDetails=(payload)=>{
    let _payload=createFormData(payload)
    return instance.post("discomlab/editList",_payload)
} 

export const getDiscomoperationsdatafordisplay=(payload)=>{
    return instance.post("discomoperations/getDiscomoperationsdatafordisplay",payload)
} 