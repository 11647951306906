import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { bulkUpload,getMeterListSearch,getMeterTrackById, deleteMeterDetailsById, editMetersDetails,getDiscomoperationsdatafordisplay, fetchMetersDetails, saveMetersDetails, getWarehouseStock, discomBulkUpload, getMeterDataAsPerStage, getStatusWiseMeter } from "./meter.api";

const initialState={
    loading:false,
    error:"",
    meter: [],
    isDeletedSuccessful:false,
    isSavedSuccessful:false,
    filterMeterListData:[],
    meterTrackingDetails:null,
    isMeterTrackingDetailsFetched:false,
    warehouseStockList:[],
    discomlabStockList:[],
    meterByStageList:[],
    statusWiseMeterCount:[],

    isDiscomBulkUploadDone:false
    

   }
export const fetchMeter=createAsyncThunk(
     "meter/fetchMeter",(data)=>fetchMetersDetails(data)
   );

export const saveMeter=createAsyncThunk(
    "meter/saveMeter",(data)=>saveMetersDetails(data)
  );
  export const deleteMeter=createAsyncThunk(
    "meter/deleteMeter",(id)=>deleteMeterDetailsById(id)
  )

  export const editMeter=createAsyncThunk(
    "meter/editMeter",(id)=>editMetersDetails(id)
  )
  export const bulkUploadMeter=createAsyncThunk(
    "meter/bulkSave",(payload)=>bulkUpload(payload)
  )

  export const getMeterTrackingById=createAsyncThunk(
    "meter/track",(meterId)=>getMeterTrackById(meterId)
  )

  export const getMeterListBySearch=createAsyncThunk(
    "meter/search",(data)=>getMeterListSearch(data)
  )
  export const getWarehouseStockList=createAsyncThunk(
    "meter/getWarehouseStock",(id)=>getWarehouseStock(id)
  )

  export const bulkUploadMeterDiscom=createAsyncThunk(
    "meter/discomBulkUpload",(payload)=>discomBulkUpload(payload)
  )

  export const getDiscomoperationsdatafordisplayAPI=createAsyncThunk(
    "discomoperations/getDiscomoperationsdatafordisplay",(payload)=>getDiscomoperationsdatafordisplay(payload)
  )
  export const getMeterDataAsPerStageAPI=createAsyncThunk(
    "meter/getmeterdataasperstage",(payload)=>getMeterDataAsPerStage(payload)
  )
  export const getStatusWiseMeterAPI=createAsyncThunk(
    "meter/getstatuswisemeter",()=>getStatusWiseMeter()
  )
  




const meterSlice=createSlice({
name:'meter',
initialState,
reducers:{
  setMeterFilter:(state,action)=>{
      state.meter= action.payload;
  },

  selectAllMeter:(state,action)=>{

    state.warehouseStockList=state.warehouseStockList.map((item)=>{
      return {...item,isSelected:action.payload}
    })
 


  },
  selectAllDiscomlabMeter:(state,action)=>{

    state.discomlabStockList=state.discomlabStockList.map((item)=>{
      return {...item,isSelected:action.payload}
    })
 


  },
  selectAllMeterByStage:(state,action)=>{

    state.meterByStageList=state.meterByStageList.map((item)=>{
      return {...item,isSelected:action.payload}
    })
 


  },

  makeBlank:(state,action)=>{
  state.warehouseStockList=[]
  },
  makeBlankDiscomlabStock:(state,action)=>{
    state.discomlabStockList=[]
    },
    makeBlankMeterByStage:(state,action)=>{
      state.meterByStageList=[]
      },
  selectMeter:(state,action)=>{
  
    let _item=action.payload;
    state.warehouseStockList=state.warehouseStockList.map((item)=>{
      if(item.id==_item?.id){
        return {...item,isSelected:!item.isSelected}
      }
      else{
        return item
      }
     
     
    })
  },

  selectDiscomlabStock:(state,action)=>{
  
    let _item=action.payload;
    state.discomlabStockList=state.discomlabStockList.map((item)=>{
      if(item.id==_item?.id){
        return {...item,isSelected:!item.isSelected}
      }
      else{
        return item
      }
     
     
    })
  },
  
  selectMeterByStage:(state,action)=>{
  
    let _item=action.payload;
    state.meterByStageList=state.meterByStageList.map((item)=>{
      if(item.id==_item?.id){
        return {...item,isSelected:!item.isSelected}
      }
      else{
        return item
      }
     
     
    })
  },
  setFalse:(state,action)=>{
   state.isDiscomBulkUploadDone=false;
  }

},
extraReducers: (builder) => {
  builder
    .addCase(fetchMeter.pending,(state)=>{
      state.loading=true;
      state.error="";
    })
    .addCase(fetchMeter.fulfilled,(state,action)=> {
      state.error = "";
        state.loading = false;
        state.isDeletedSuccessful=false;
        state.isSavedSuccessful=false;
   
        if(Array.isArray(action.payload?.data.data)){
            state.meter = action.payload?.data.data;
          }
          else{
            state.meter = []
          }
         
        state.filterMeterListData=action.payload?.data.data;
    })
    .addCase(fetchMeter.rejected, (state, action) =>{
      state.loading = false;
      state.error = action.error.message || "Something went wrong!";
    })  

    .addCase(getWarehouseStockList.pending,(state)=>{
      state.loading=true;
      state.error="";
    })
    .addCase(getDiscomoperationsdatafordisplayAPI.pending,(state)=>{
      state.loading=true;
      state.error="";
    })
    .addCase(getMeterDataAsPerStageAPI.pending,(state)=>{
      state.loading=true;
      state.error="";
    })
    .addCase(getStatusWiseMeterAPI.pending,(state)=>{
      state.loading=true;
      state.error="";
    })
    .addCase(getWarehouseStockList.fulfilled,(state,action)=> {

      state.error = "";
        state.loading = false;
        if(Array.isArray(action.payload?.data.data)){
            state.warehouseStockList = action.payload?.data.data.map((item)=>{
            return {...item,isSelected:false}
            });
          }
          else{
            state.warehouseStockList = []
          }
   
    })
    .addCase(getDiscomoperationsdatafordisplayAPI.fulfilled,(state,action)=> {

      state.error = "";
        state.loading = false;
        if(Array.isArray(action.payload?.data.data)){
            state.discomlabStockList = action.payload?.data.data.map((item)=>{
            return {...item,isSelected:false}
            });
          }
          else{
            state.discomlabStockList = []
          }
   
    })
    .addCase(getMeterDataAsPerStageAPI.fulfilled,(state,action)=> {

      state.error = "";
        state.loading = false;
        if(Array.isArray(action.payload?.data.data)){
            state.meterByStageList = action.payload?.data.data;
            state.meter=action.payload?.data.data.map((item)=>{
              return {...item,isSelected:false}
              });
          }
          else{
            state.meterByStageList = []
          }
   
    })

    .addCase(getStatusWiseMeterAPI.fulfilled,(state,action)=> {

      state.error = "";
        state.loading = false;
        if(Array.isArray(action.payload?.data.data)){
            state.statusWiseMeterCount = action.payload?.data.data;
           
          }
          else{
            state.statusWiseMeterCount = []
          }
   
    })
    .addCase(getWarehouseStockList.rejected, (state, action) =>{
      state.loading = false;
      state.error = action.error.message || "Something went wrong!";
    })
    .addCase(getDiscomoperationsdatafordisplayAPI.rejected, (state, action) =>{
      state.loading = false;
      state.error = action.error.message || "Something went wrong!";
    }) 
    .addCase(getMeterDataAsPerStageAPI.rejected, (state, action) =>{
      state.loading = false;
      state.error = action.error.message || "Something went wrong!";
    }) 
    .addCase(getStatusWiseMeterAPI.rejected, (state, action) =>{
      state.loading = false;
      state.error = action.error.message || "Something went wrong!";
    }) 




//search by Meter Id

.addCase(getMeterListBySearch.pending,(state)=>{
  state.loading=true;
  state.error="";
})
.addCase(getMeterListBySearch.fulfilled,(state,action)=> {
  state.error = "";
    state.loading = false;
    state.isDeletedSuccessful=false;
    state.isSavedSuccessful=false;

    if(Array.isArray(action.payload?.data.data)){
        state.meter = action.payload?.data.data;
      }
      else{
        state.meter = []
      }
     
    state.filterMeterListData=action.payload?.data.data;
})
.addCase(getMeterListBySearch.rejected, (state, action) =>{
  state.loading = false;
  state.error = action.error.message || "Something went wrong!";
}) 

.addCase(getMeterTrackingById.pending,(state)=>{
  state.isMeterTrackingDetailsFetched=true;
  state.error="";
})
.addCase(getMeterTrackingById.fulfilled,(state,action)=> {
  state.error = "";
    state.isMeterTrackingDetailsFetched = false;


    if(Array.isArray(action.payload?.data.data)){
        state.meterTrackingDetails = action.payload?.data.data;
      }
      else{
        state.meterTrackingDetails = []
      }
     
    state.meterTrackingDetails=action.payload?.data.data;
})
.addCase(getMeterTrackingById.rejected, (state, action) =>{
  state.isMeterTrackingDetailsFetched = false;
  state.error = action.error.message || "Something went wrong!";
}) 



//Save meter
   .addCase(saveMeter.pending,(state)=>{
        state.loading=true;
        state.error="";
      })
      .addCase(saveMeter.fulfilled,(state,action)=> {
          state.loading = false;
          state.isSavedSuccessful=true;
         
      })
      .addCase(saveMeter.rejected, (state, action) =>{
        state.loading = false;
        state.error = action.error.message || "Something went wrong!";
      }) 
//Delete meter
      .addCase(deleteMeter.pending,(state)=>{
        state.loading=true;
        state.error="";
      })
      .addCase(deleteMeter.fulfilled,(state,action)=> {
          state.loading = false;
          state.isDeletedSuccessful=true;
         
      })
      .addCase(deleteMeter.rejected, (state, action) =>{
        state.loading = false;
        state.error = action.error.message || "Something went wrong!";
      }) 
      //Edit meter
      .addCase(editMeter.pending,(state)=>{
        state.loading=true;
        state.error="";
      })
      .addCase(editMeter.fulfilled,(state,action)=> {
          state.loading = false;
          state.isSavedSuccessful=true;
         
      })
      .addCase(editMeter.rejected, (state, action) =>{
        state.loading = false;
        state.error = action.error.message || "Something went wrong!";
      }) 


       //bulk upload meter
       .addCase(bulkUploadMeter.pending,(state)=>{
        state.loading=true;
        state.error="";
      })
      .addCase(bulkUploadMeter.fulfilled,(state,action)=> {
          state.loading = false;
          state.isSavedSuccessful=true;
         
      })
      .addCase(bulkUploadMeter.rejected, (state, action) =>{
        state.loading = false;
        state.error = action.error.message || "Something went wrong!";
      }) 

        //discom bulk upload meter
        .addCase(bulkUploadMeterDiscom.pending,(state)=>{
          state.loading=true;
          state.error="";
        })
        .addCase(bulkUploadMeterDiscom.fulfilled,(state,action)=> {
            state.loading = false;
            state.isDiscomBulkUploadDone=true;
           
        })
        .addCase(bulkUploadMeterDiscom.rejected, (state, action) =>{
          state.loading = false;
          state.error = action.error.message || "Something went wrong!";
        }) 
}
})
export default meterSlice.reducer;
export const {setMeterFilter,selectAllMeter,selectMeter,setFalse,makeBlank,selectAllDiscomlabMeter,selectAllMeterByStage,selectMeterByStage, makeBlankMeterByStage } = meterSlice.actions
