import React, { useEffect, useState } from 'react';
import { VerticalTimeline, VerticalTimelineElement }  from 'react-vertical-timeline-component';
import 'react-vertical-timeline-component/style.min.css';

import {
    MDBBtn,
    MDBModal,
    MDBModalDialog,
    MDBModalContent,
    MDBModalHeader,
    MDBModalTitle,
    MDBModalBody,
    MDBIcon,
  } from 'mdb-react-ui-kit';
import {  ShimmerCircularImage, ShimmerThumbnail } from 'react-shimmer-effects';
import { iconsImgs } from '../utils/images';
import { useDispatch, useSelector } from 'react-redux';
import { getMeterTrackingById } from '../features/meter/meterSlice';
import { formatDate } from '../utils/common';


const TimeLine = ({id,basicModal,setBasicModal,number}) => {

  const {
    meterTrackingDetails,
    isMeterTrackingDetailsFetched
  } = useSelector((state) => state.meters);
const toggleOpen = () => setBasicModal(!basicModal);
const dispatch = useDispatch();
   useEffect(() => {
 if(id){
  dispatch(getMeterTrackingById(id))

 }
   
     return () => {
       console.log("modal close")
     }
   }, [id])
   
  
  return (
    <div>
       
      <MDBModal open={basicModal} setOpen={setBasicModal} tabIndex='-1'>
        <MDBModalDialog centered size='fullscreen'>
          <MDBModalContent>
            <MDBModalHeader>
              <MDBModalTitle>Meter Status check - {number}</MDBModalTitle>
              <MDBBtn className='btn-close' color='none' onClick={toggleOpen}></MDBBtn>
            </MDBModalHeader>
            <MDBModalBody >
         {!isMeterTrackingDetailsFetched?<div className="">
         
      <VerticalTimeline lineColor="#9dde9d">
        
        <VerticalTimelineElement
        className="vertical-timeline-element--work"
      iconStyle={{ background: '#00afff', color: '#fff' }}
        icon={<div style={{display:"flex",color: 'black',alignItems:"center",height:"100%",justifyContent:"center",color: 'white',fontWeight:800,fontSize:12 }}>
        START
        </div>}
    
      >
       
      </VerticalTimelineElement>
     
     {meterTrackingDetails?.length>0 && meterTrackingDetails.map((item,i)=>{
return (
  <VerticalTimelineElement
        className="vertical-timeline-element--work"
        date={formatDate(item.date)}
        contentArrowStyle={{ borderRight: '7px solid  rgb(190 206 219)' }}
        iconStyle={{ background: 'rgb(57 78 203)', color: '#fff' }}
        contentStyle={(i==(meterTrackingDetails.length-1))? { background: 'rgb(205 213 251)', color: 'black',fontWeight:800,fontSize:20 }:
        { background: 'rgb(198 239 203)', color: 'black',fontWeight:800,fontSize:20 }
      }
        icon={<div style={{display:"flex",alignItems:"center",height:"100%",justifyContent:"center"}}>
       {i==(meterTrackingDetails.length-1) ? <img src={iconsImgs.smartMeter} width={52} height={52} style={{borderRadius:"50%"}}/>:<>
       <div className='d-flex justify-content-center align-items-center flex-column' style={{fontSize:11}}>
       <div>   Stage</div>
       
       <div>{i+1}</div>

       </div>
    
       </>
    
       } 
        </div>}
      >
        <h3 className="vertical-timeline-element-title">Meter No: <span className='badge bg-teal-lt'>  {number}</span></h3>
        {/* <h4 className="vertical-timeline-element-subtitle"></h4> */}
        <p>
        {item.description}
        </p>
      </VerticalTimelineElement>
)

     })}
      
      {/* <VerticalTimelineElement
        className="vertical-timeline-element--education"
        contentStyle={{background:"#1d1836",color:"#fff"}}
        contentArrowStyle={{borderRight:"7px solid #232631"}}
    
        date="April 2013"
        iconStyle={{ background: 'green', color: '#fff' }}
        icon={<div style={{display:"flex",alignItems:"center",height:"100%",justifyContent:"center"}}>
        <img src={iconsImgs.smartMeter} width={55} height={55} style={{borderRadius:"50%"}}/>
        </div>}
      >
        <h3 className="vertical-timeline-element-title">Content Marketing for Web, Mobile and Social Media</h3>
        <h4 className="vertical-timeline-element-subtitle">Online Course</h4>
        <p>
          Strategy, Social Media
        </p>
      </VerticalTimelineElement>

     */}
      <VerticalTimelineElement
        iconStyle={{ background: 'rgb(246 154 154)', color: 'black',fontWeight:800,fontSize:14 }}
        icon={<div style={{display:"flex",alignItems:"center",height:"100%",justifyContent:"center"}}>
        END
        </div>}
      />
    </VerticalTimeline>
             </div>
             
             
             
             :





             <div>
             <div className='d-flex justify-content-around align-items-center my-2'>
              
                <ShimmerCircularImage size={100} />
 
                </div> 


                <div className='d-flex justify-content-around align-items-center my-2'>
           
                <div style={{height:200,width:500,backgroundColorC:"transparent"}}></div>
                <ShimmerCircularImage size={100} />
                <ShimmerThumbnail height={200} width={500} className="m-0" rounded />    

                </div> 
                
                <div className='d-flex justify-content-around align-items-center my-2'>
           
                <ShimmerThumbnail height={200} width={500} className="m-0" rounded />       
                <ShimmerCircularImage size={100} />
                <div style={{height:200,width:500,backgroundColorC:"transparent"}}></div>

                </div> 
                <div className='d-flex justify-content-around align-items-center my-2'>
           
           <div style={{height:200,width:500,backgroundColorC:"transparent"}}></div>
           <ShimmerCircularImage size={100} />
           <ShimmerThumbnail height={200} width={500} className="m-0" rounded />    

           </div> 
           
           <div className='d-flex justify-content-around align-items-center my-2'>
      
           <ShimmerThumbnail height={200} width={500} className="m-0" rounded />       
           <ShimmerCircularImage size={100} />
           <div style={{height:200,width:500,backgroundColorC:"transparent"}}></div>

           </div> 





                <div className='d-flex justify-content-around align-items-center my-2'>
                <ShimmerCircularImage size={100} />
  
                </div> 
                </div>}
            

            </MDBModalBody>

           
          </MDBModalContent>
        </MDBModalDialog>
      </MDBModal>

    </div>
  )
}

export default TimeLine