import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import "./SignIn.css";
import { personsImgs } from '../../../utils/images';
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import { useDispatch, useSelector } from 'react-redux';
import { logInUser } from '../authSlice';
import { errorMessage } from '../../../utils/tostify';


function SignIn() {
  const {loading,isLoginSuccessful,error} = useSelector((state) => state.logInUser);
  const dispatch = useDispatch();


  useEffect(() => {
    if (error) {
      errorMessage(error);
    }
  }, [error]);

    const initialValue = {
        email: "",
        password: "",
      };
      const validationSchema = Yup.object({
        email: Yup.string()
          .email("Invalid email address")
          .required("Email is required"),
        password: Yup.string()
          // .matches(/[A-Z]/, "Password must contain at least one capital letter")
          // .matches(/[a-z]/, "Password must contain at least one small letter")
          // .matches(
          //   /[!@#$%^&*(),.?":{}|<>]/,
          //   "Password must contain at least one special character"
          // )
          // .min(8, "Password must be at least 8 characters")
          .required("Password is required"),
      });
      const onSubmit = (values, { resetForm, setSubmitting }) => {

        console.log("Submitted:", values);
       dispatch(logInUser(values))
       setSubmitting(false);
        resetForm();
      };

      const [showPassword, setShowPassword] = useState(false);

      const togglePasswordVisibility = (e) => {
        e.preventDefault();
        e.stopPropagation();
        setShowPassword(!showPassword);
      };
      
  return (
    <div  className="d-flex flex-column">
    <div className="page page-center">
      <div className="container container-tight my-2">
        <div className="text-center">
          <a href="/" className="navbar-brand navbar-brand-autodark m-3">
            <img src={personsImgs.logo} height="106" alt=""  style={{borderRadius:"50%"}}/>

            </a>
        </div>
        <div className="card card-md">
          <div className="card-body">
            <h2 className="h2 text-center mb-4">Login to your account</h2>
            <Formik
              initialValues={initialValue}
              validationSchema={validationSchema}
              onSubmit={onSubmit}
            >
              {({ isSubmitting }) => (
          
            <Form noValidate>
                   
              <div className="mb-3">
              <label className="form-label" htmlFor="email">
                        Email address
                      </label>
                      <Field
                        type="email"
                        id="email"
                        name="email"
                        className="form-control"
                        placeholder="Enter email"
                      />
                      <ErrorMessage
                        name="email"
                        component="div"
                        className="error"
                      /></div>
              <div className="mb-2">
                <label className="form-label">
                  Password
                  <span className="form-label-description">
                    <a href="./forgot-password.html">I forgot password</a>
                  </span>
                </label>
                <div className="input-group input-group-flat">
                        <Field
                         type={showPassword ? 'text' : 'password'}
                          id="password"
                          name="password"
                          className="form-control"
                          placeholder="Enter password"
                          autoComplete="on"
                        />
                        <div className="input-group-text" >
                          <a
                     onClick={(e)=>{togglePasswordVisibility(e)}}
                            className="link-secondary cursor-pointer"
                            title="Show password"
                            data-bs-toggle="tooltip"
                          >
                           {!showPassword ? (
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
            <circle cx="12" cy="12" r="4"/>
            <path d="M1 12c2.7 4.2 7.8 7.2 11 7.2s8.3-3 11-7.2"/>
            <path d="M1 12c2.7-4.2 7.8-7.2 11-7.2s8.3 3 11 7.2"/>
          </svg>
        ) : (
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
            <path d="M12 15a3 3 0 0 1-3-3 3 3 0 0 1 6 0 3 3 0 0 1-3 3z"/>
            <path d="M19.84 16.02a10 10 0 0 1-14.46 0"/>
            <line x1="1" y1="1" x2="23" y2="23"/>
          </svg>
        )}
</a>
                        </div>
                       
                    </div>
                    <ErrorMessage
                        name="password"
                        component="div"
                        className="error"
                        style={{marginTop:10}}
                      />
              </div>
           
              <div className="form-footer">
                <button type="submit"
                 disabled={isSubmitting} 
                 className="btn btn-primary w-100">Sign in</button>
              </div>
            </Form>)}
            </Formik>
          </div>
          {/* <div className="hr-text">or</div>
          <div className="card-body">
            <div className="row">
              <div className="col"><a href="#" className="btn w-100">
             
              <svg  xmlns="http://www.w3.org/2000/svg"  width="24"  height="24"  viewBox="0 0 24 24"  fill="none"  stroke="currentColor"  stroke-width="2"  stroke-linecap="round"  stroke-linejoin="round"  class="icon icon-tabler icons-tabler-outline icon-tabler-brand-google"><path stroke="none" d="M0 0h24v24H0z" fill="none"/><path d="M20.945 11a9 9 0 1 1 -3.284 -5.997l-2.655 2.392a5.5 5.5 0 1 0 2.119 6.605h-4.125v-3h7.945z" /></svg>    Login with Google
                </a></div>
              <div className="col"><a href="#" className="btn w-100">
          
              <svg  xmlns="http://www.w3.org/2000/svg"  width="24"  height="24"  viewBox="0 0 24 24"  fill="none"  stroke="currentColor"  stroke-width="2"  stroke-linecap="round"  stroke-linejoin="round"  class="icon icon-tabler icons-tabler-outline icon-tabler-brand-facebook"><path stroke="none" d="M0 0h24v24H0z" fill="none"/><path d="M7 10v4h3v7h4v-7h3l1 -4h-4v-2a1 1 0 0 1 1 -1h3v-4h-3a5 5 0 0 0 -5 5v2h-3" /></svg>  Login with Facebook
                </a></div>
            </div>
          </div> */}
        </div>
        {/* <div className="text-center text-muted mt-3">
          Don't have account yet? <Link className=" mt-3" to="/auth/sign-up" tabIndex="-1">Sign up</Link>
        </div> */}
      </div>
    </div>

  </div>
  )
}

export default SignIn