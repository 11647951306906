import ReactApexChart from "react-apexcharts";

function BarChart(statusWiseMeterCount) {
  let categories=[];
  let countdata=[];
  for(let item of statusWiseMeterCount.statusWiseMeterCount){
    categories.push(item.stage);
    countdata.push(item.count);
  }
    const series = [
        {
          name: "Stage Wise Meter Count ",
          data: countdata,
        },
      ];
      
    const options = {
        chart: {
          height: 350,
          width: "100%",
          type: 'bar',
          zoom: {
            enabled: false,
          },
          animations: {
            enabled: true,
            easing: 'easein',
            speed: 1000,
            animateGradually: {
                enabled: true,
                delay: 150
            }
          },
        },
        plotOptions: {
          bar: {
            borderRadius: 10,
            dataLabels: {
              position: 'top', // top, center, bottom
            },
            distributed: true,
            columnWidth: '20%',

          },
          
        },
        grid: {
          show: false,
        },
        dataLabels: {
          enabled: true,
          formatter: function (val) {
            return val ;
          },
          offsetY: -20,
          style: {
            fontSize: '12px',
            colors: ["#304758"]
          }
        },
        
        
        xaxis: {
          
          categories: categories,
          position: 'bottom',
          axisBorder: {
            show: true
          },
          axisTicks: {
            show: false
          },
          crosshairs: {
            fill: {
              type: 'gradient',
              gradient: {
                colorFrom: '#D8E3F0',
                colorTo: '#BED1E6',
                stops: [0, 100],
                opacityFrom: 0.4,
                opacityTo: 0.5,
              }
            }
          },
          tooltip: {
            enabled: true,
          }
        },
        yaxis: {
          axisBorder: {
            show: true
          },
          axisTicks: {
            show: false,
          },
          labels: {
            show: true,
            formatter: function (val) {
              return val ;
            }
          }
        },
        
    }
      
  return (
    <div id="chart">
      <ReactApexChart options={options} series={series} type="bar" height={495} />
    </div>
  );
}

export default BarChart;
