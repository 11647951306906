import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { editUsersDetails, fetchUsersDetails, saveUsersDetails ,deleteUserDetailsById, saveCover, fetchUsersDetailsByType} from "./user.api";

const initialState={
    loading:false,
    error:"",
    users: [],
    isDeletedSuccessful:false,
    isSavedSuccessful:false,
    filterUsersListData:[],
    coverImagePath:"",
    coverImageSaved:false,
   
   }
// export const fetchUsers=createAsyncThunk(
//      "users/fetchUsers",()=>fetchUsersDetails()
//    );
export const fetchUsers=createAsyncThunk(
  "users/fetchUsers",(id)=>fetchUsersDetailsByType(id)
);




export const saveUser=createAsyncThunk(
    "users/saveUsers",(data)=>saveUsersDetails(data)
  );
  export const deleteUser=createAsyncThunk(
    "users/deleteUsers",(id)=>deleteUserDetailsById(id)
  )

  export const editUser=createAsyncThunk(
    "users/editUsers",(payload)=>editUsersDetails(payload)
  )
  export const uploadCoverImg=createAsyncThunk(
    "users/uploadCover",(path)=>saveCover(path)
  )
  


const userSlice=createSlice({
name:'users',
initialState,
reducers:{
  setUsersFilter:(state,action)=>{
      state.users= action.payload;
  },

},
extraReducers: (builder) => {
  builder
    .addCase(fetchUsers.pending,(state)=>{
      state.loading=true;
      state.error="";
    })
    .addCase(fetchUsers.fulfilled,(state,action)=> {
        state.loading = false;
        state.isDeletedSuccessful=false;
        state.isSavedSuccessful=false;
        if(Array.isArray(action.payload?.data.data)){
          state.users = action.payload?.data.data;
        }
        else{
          state.users = []
        }
       
        state.filterUsersListData=action.payload?.data.data;
    })
    .addCase(fetchUsers.rejected, (state, action) =>{
      state.loading = false;
      state.error = action.error.message || "Something went wrong!";
    })  
//Save users
   .addCase(saveUser.pending,(state)=>{
        state.loading=true;
        state.error="";
      })
      .addCase(saveUser.fulfilled,(state,action)=> {
          state.loading = false;
          state.isSavedSuccessful=true;
         
      })
      .addCase(saveUser.rejected, (state, action) =>{
        state.loading = false;
        state.error = action.error.message || "Something went wrong!";
      }) 
//Delete users
      .addCase(deleteUser.pending,(state)=>{
        state.loading=true;
        state.error="";
      })
      .addCase(deleteUser.fulfilled,(state,action)=> {
          state.loading = false;
          state.isDeletedSuccessful=true;
         
      })
      .addCase(deleteUser.rejected, (state, action) =>{
        state.loading = false;
        state.error = action.error.message || "Something went wrong!";
      }) 
      //Edit users
      .addCase(editUser.pending,(state)=>{
        state.loading=true;
        state.error="";
      })
      .addCase(editUser.fulfilled,(state,action)=> {
          state.loading = false;
          state.isSavedSuccessful=true;
         
      })
      .addCase(editUser.rejected, (state, action) =>{
        state.loading = false;
        state.error = action.error.message || "Something went wrong!";
      }) 
      // save Cover image

      .addCase(uploadCoverImg.pending,(state)=>{
        state.coverImageSaved=false;
        state.error="";
      })
      .addCase(uploadCoverImg.fulfilled,(state,action)=> {
      
          state.coverImageSaved = true;
          state.coverImagePath=action.payload.data.data;
         
      })
      .addCase(uploadCoverImg.rejected, (state, action) =>{
        state.coverImageSaved = false;
        state.error = action.error.message || "Something went wrong!";
      }) 
}
})
export default userSlice.reducer;
export const {setUsersFilter } = userSlice.actions
