import instance from "../../api/axiosInstance";
import { createFormData } from "../../utils/common";


export const fetchSupervisorDetails=()=>{
    return instance.get("supervisor/get_all")
} 
export const saveSupervisorDetails=(payload)=>{
    let _payload=createFormData(payload)
    return instance.post("supervisor/save",_payload)
} 
export const getSupervisorDetailsById=(payload)=>{
    return instance.post("supervisor/getById",payload)
} 
export const deleteSupervisorDetailsById=(payload)=>{
    let _payload=createFormData(payload)
    return instance.post("supervisor/deleteList",_payload)
} 
export const editSupervisorDetails=(payload)=>{
    let _payload=createFormData(payload)
    return instance.post("supervisor/editList",_payload)
} 




