// import React, { useState } from 'react';
// import * as XLSX from 'xlsx';

// const ExcelToJsonConverter = () => {
//   const [jsonData, setJsonData] = useState(null);

//   const handleFileUpload = (e) => {
//     const file = e.target.files[0];
//     const reader = new FileReader();

//     reader.onload = (event) => {
//       const data = new Uint8Array(event.target.result);
//       const workbook = XLSX.read(data, { type: 'array' });

//       const sheetName = workbook.SheetNames[0];
//       const worksheet = workbook.Sheets[sheetName];

//       const jsonResult = XLSX.utils.sheet_to_json(worksheet, { header: 1 });

//       // Remove header row if needed
//     //   jsonResult.shift();

//       setJsonData(jsonResult);
//     };

//     reader.readAsArrayBuffer(file);
//   };

//   return (
//     <div>
//       <input type="file" onChange={handleFileUpload} />
//       {jsonData && (
//         <pre>{JSON.stringify(jsonData, null, 2)}</pre>
//       )}
//     </div>
//   );
// };

// export default ExcelToJsonConverter;

// import React, { useState } from 'react';
// import * as XLSX from 'xlsx';

// const ExcelToJsonConverter = () => {
//   const [jsonData, setJsonData] = useState(null);

//   const handleFileUpload = (e) => {
//     const file = e.target.files[0];
//     const reader = new FileReader();

//     reader.onload = (event) => {
//       const data = new Uint8Array(event.target.result);
//       const workbook = XLSX.read(data, { type: 'array' });

//       const sheetName = workbook.SheetNames[0];
//       const worksheet = workbook.Sheets[sheetName];

//       // Convert sheet to JSON
//       const jsonResult = XLSX.utils.sheet_to_json(worksheet, { header: 1 });

//       // Remove the first column (assuming it's the ID/index column)
//       const headers = jsonResult[0].slice(1); // Extract headers, skipping the first column
//       const dataRows = jsonResult.slice(1); // Exclude the first row (headers)

//       // Convert to key-value pairs
//       const jsonData = dataRows.map(row => {
//         const rowData = {};
//         headers.forEach((header, index) => {
//           rowData[header] = row[index + 1]; // Skip the first column
//         });
//         return rowData;
//       });

//       setJsonData(jsonData);
//     };

//     reader.readAsArrayBuffer(file);
//   };

//   return (
//     <div>
//       <input type="file" onChange={handleFileUpload} />
//       {jsonData && (
//         <pre>{JSON.stringify(jsonData, null, 2)}</pre>
//       )}
//     </div>
//   );
// };

// export default ExcelToJsonConverter;



// import React, { useState } from 'react';
// import * as XLSX from 'xlsx';

// const ExcelToJsonConverter = () => {
//   const [jsonData, setJsonData] = useState([]);

//   const handleFileUpload = (e) => {
//     const file = e.target.files[0];
//     const reader = new FileReader();

//     reader.onload = (event) => {
//       const data = new Uint8Array(event.target.result);
//       const workbook = XLSX.read(data, { type: 'array' });

//       const sheetNames = workbook.SheetNames;

//       // Process each sheet
//       const sheetData = sheetNames.map(sheetName => {
//         const worksheet = workbook.Sheets[sheetName];
//         const jsonResult = XLSX.utils.sheet_to_json(worksheet, { header: 1 });

//         // Assuming the first row contains headers
//         const headers = jsonResult[0];
//         const dataRows = jsonResult.slice(1);

//         // Convert to key-value pairs
//         const jsonData = dataRows.map(row => {
//           const rowData = {};
//           headers.forEach((header, index) => {
//             rowData[header] = row[index];
//           });
//           return rowData;
//         });

//         return { sheetName, data: jsonData };
//       });

//       setJsonData(sheetData);
//     };

//     reader.readAsArrayBuffer(file);
//   };

//   return (
//     <div>
//       <input type="file" onChange={handleFileUpload} />
//       {jsonData.map((sheet, index) => (
//         <div key={index}>
//           <h3>{sheet.sheetName}</h3>
//           <pre>{JSON.stringify(sheet.data, null, 2)}</pre>
//         </div>
//       ))}
//     </div>
//   );
// };

// export default ExcelToJsonConverter;




import React, { useState } from 'react';
import * as XLSX from 'xlsx';

const ExcelToJsonConverter = React.forwardRef((props,ref) => {
  const [sheetData, setSheetData] = useState([]);

  const handleFileUpload = (e) => {
 
    const file = e.target.files[0];
    const reader = new FileReader();

    reader.onload = (event) => {
      const data = new Uint8Array(event.target.result);
      const workbook = XLSX.read(data, { type: 'array',dateNF: 'dd/mm/yyyy'  });

      const sheetNames = workbook.SheetNames;

      // Process each sheet
      const newData = sheetNames.map(sheetName => {
        const worksheet = workbook.Sheets[sheetName];
        const jsonResult = XLSX.utils.sheet_to_json(worksheet, { header: 1 });

        // Assuming the first row contains headers
        const headers = jsonResult[0];
        const dataRows = jsonResult.slice(1);

        // Convert to key-value pairs
        const jsonData = dataRows.map(row => {
          const rowData = {};
          headers.forEach((header, index) => {
           
            if (header.includes('date') || header.includes('Date')) {
              // const dateValue = (row[index]- (25567 + 1)) * 86400 * 1000-(new Date().getTimezoneOffset() * 60000);
              // rowData[header] = dateValue ? new Date(dateValue).toISOString() : ''; 
              const dateValue = XLSX.SSF.parse_date_code(row[index]);
              rowData[header] = new Date(Date.UTC(dateValue.y, dateValue.m - 1, dateValue.d, dateValue.H, dateValue.M, dateValue.S)).toISOString(); // Convert to ISO string
            
            } else {
              rowData[header] = row[index];
            }
          
          });
          return rowData;
        });

        return { sheetName, data: jsonData };
      });

      setSheetData(newData);
      props.getJsonData(newData[0].data)
    };

    reader.readAsArrayBuffer(file);
  };

  return (
    <div>
      <input className='form-field' type="file" onChange={handleFileUpload} accept=".xlsx, .xls" ref={ref} hidden/>
      {/* {sheetData.length > 0 && (
        <table>
          <thead>
            <tr>
              {sheetData.map(sheet => (
                <th key={sheet.sheetName}>{sheet.sheetName}</th>
              ))}
            </tr>
          </thead>
          <tbody>
            {Array.from(
              { length: Math.max(...sheetData.map(sheet => sheet.data.length)) },
              (_, index) => (
                <tr key={index}>
                  {sheetData.map(sheet => (
                    <td key={sheet.sheetName}>
                      {sheet.data[index] ? JSON.stringify(sheet.data[index]) : ''}
                    </td>
                  ))}
                </tr>
              )
            )}
          </tbody>
        </table>
      )} */}
    </div>
  );
});

export default ExcelToJsonConverter;
