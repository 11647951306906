import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  deleteGlobalSetting,
  editGlobalSetting,
  fetchGlobalSetting,
  saveGlobalSetting,
  getGlobalVeriablesByTypeDetailsAPI,
} from "./globalSetting.slice";
import { errorMessage, successMessage } from "../../utils/tostify";
import { iconsImgs } from "../../utils/images";
import DeleteDialog from "../../components/DeleteDialog";

function Setting() {
  const dispatch = useDispatch();
  const [details, setDetails] = React.useState(null);
  const [deletedId, setDeletedId] = React.useState(null);

  const [typeId, setTypeId] = React.useState(null);
  const [value, setvalue] = React.useState("");
  const [message, setMessage] = React.useState("");
  const closeModal = () => {
    const button = document.getElementById("closeGlobal");
    button.click();
  };
  const {
    globalSetting: globalSettingList,
    typeWiseList,
    typeWiseListDropdown,
    loading,
    isDeletedSuccessful,
    isSavedSuccessful,
    filterGlobalSettingListData,
    error,
  } = useSelector((state) => state.globalSetting);

  useEffect(() => {
    if(globalSettingList.length>0) return
      dispatch(fetchGlobalSetting());
   // dispatch(getGlobalVeriablesByTypeDetailsAPI({type:119}))

  }, []);

  
  useEffect(() => {
    if (isDeletedSuccessful) {
      dispatch(fetchGlobalSetting());
      successMessage(" Deleted Successfully");
      closeModal();
    } else if (isSavedSuccessful) {
      dispatch(fetchGlobalSetting());

      successMessage(message);
      closeModal();
    }

    return () => {};
  }, [isDeletedSuccessful, isSavedSuccessful]);

  useEffect(() => {
    if (error) {
      errorMessage(error);
    }
  }, [error]);

  const handleSubmit = (event) => {
    event.preventDefault();
    let payload;
    if (!value) errorMessage("Please enter value!");
    if (details?.id) {
      setMessage(" Updated Successfully");

      payload = { type: typeId, name: value, id: details?.id };
      dispatch(editGlobalSetting(payload));
    } else {
      setMessage("Added Successfully");
      payload = { type: typeId, name: value };
      dispatch(saveGlobalSetting(payload));
    }
  };

  const onDelete = (cb) => {
    dispatch(deleteGlobalSetting({ id: deletedId }));
    cb();
  };
  // dispatch(getGlobalVeriablesByTypeDetailsAPI({type:119}))
  // console.log(typeWiseList);
  // console.log(typeWiseListDropdown);



  return (
    <div>

    <div className="d-flex justify-content-end align-items-baseline gap-2">
    <div className="input-icon mb-3">
      <input type="text"  className="form-control" placeholder="Search…" />
      <span className="input-icon-addon">
   
        <svg
          xmlns="http://www.w3.org/2000/svg"
          className="icon"
          width="24"
          height="24"
          viewBox="0 0 24 24"
          strokeWidth="2"
          stroke="currentColor"
          fill="none"
          strokeLinecap="round"
          strokeLinejoin="round"
        >
          <path stroke="none" d="M0 0h24v24H0z" fill="none" />
          <path d="M10 10m-7 0a7 7 0 1 0 14 0a7 7 0 1 0 -14 0" />
          <path d="M21 21l-6 -6" />
        </svg>
      </span>
    </div>
    <button className="btn btn-primary"
    
    data-bs-toggle="modal"
    data-bs-target="#globalSettingFormModal"
    onClick={() => {
      setTypeId(1);
    }}
    
    > Add New Global Variable</button>

    </div>

      <div className="row row-cards">
        {globalSettingList.map((item, index) => (
          <div className="col-md-4" key={index}>
            <div className="card">
              <div className="d-flex justify-content-between">
                <h4 className="text-center p-2 ms-2 mb-0 text-capitalize">
                  {item.name} (ID -{" "}
                  <span className="text-success">{item.id}</span>)
                </h4>
                <button
                  className="btn btn-outline-info my-2 me-2 "
                  data-bs-toggle="modal"
                  data-bs-target="#globalSettingFormModal"
                  onClick={() => {
                    setTypeId(item?.id);
                  }}
                >
                  Add
                </button>

               { !item?.children && <a  
                                  data-bs-toggle="modal"
                                  data-bs-target="#global-delete"
                                  className="btn my-2 me-2 btn-icon text-danger"
                                  aria-label="delete"
                                  title="Delete"
                                  onClick={() => setDeletedId(item?.id)}
                                >
                                  <img
                                    color="red"
                                    src={iconsImgs.deleteIcon}
                                    width={20}
                                    height={20}
                                    alt=""
                                  />
                                </a>
}
              </div>

              <hr className="p-0 m-0" />

              <div className="card-body" style={{height:245,overflow:"auto"}}>
                <div className="table-responsive">
                  <table className="table table-striped ">
                    <thead>
                      <tr>
                        <th>ID</th>
                        <th>Name</th>

                        <th className="w-1"></th>
                      </tr>
                    </thead>
                    <tbody>
                      {item?.children?.map((option) => {
                        return (
                          <tr key={option.id}>
                            <td>{option.id}</td>
                            <td className="text-muted">{option.name}</td>

                            <td>
                              <div className="d-flex">
                                <a
                                  data-bs-toggle="modal"
                                  data-bs-target="#globalSettingFormModal"
                                  className="btn  w-25 text-white  btn-icon mx-2 text-primary"
                                  onClick={() => {
                                    setDetails(option);
                                    setTypeId(option.type);
                                    setvalue(option.name);
                                  }}
                                >
                                  <img
                                    src={iconsImgs.edit}
                                    width={20}
                                    height={20}
                                    alt=""
                                  />
                                </a>
                                <a
                                  data-bs-toggle="modal"
                                  data-bs-target="#global-delete"
                                  className="btn w-25  mx-2 btn-icon text-danger"
                                  aria-label="delete"
                                  title="Delete"
                                  onClick={() => setDeletedId(option?.id)}
                                >
                                  <img
                                    color="red"
                                    src={iconsImgs.deleteIcon}
                                    width={20}
                                    height={20}
                                    alt=""
                                  />
                                </a>
                              </div>
                            </td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        ))}
      </div>

      <div
        className="modal modal-blur fade"
        id={"globalSettingFormModal"}
        tabIndex="-1"
        role="dialog"
        aria-hidden="true"
        data-bs-backdrop="static"
      >
        <div
          className="modal-dialog modal-lg modal-dialog-centered"
          role="document"
        >
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title">Global Variable </h5>
              {typeId && <span>Type Id: {typeId}</span>}
              {/* close modal */}
              <button
                onClick={() => {
                  setTypeId(null);
                  setDetails(null);
                  setvalue("");
                }}
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
                id="closeGlobal"
              ></button>
            </div>
            <form onSubmit={handleSubmit}>
              <div className="m-3  p-2">
                <label className="col-3 col-form-label required">Value</label>
                <input
                  type="text"
                  value={value}
                  className="form-control"
                  aria-describedby="emailHelp"
                  placeholder="Enter value"
                  onChange={(e) => setvalue(e.target.value)}
                />

                <button type="submit" className="btn btn-primary my-2 ">
                  Submit
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>

      <DeleteDialog
        modalId="global-delete"
        onDeleteCall={onDelete}
        closeId={"deleteNoButtonGlobal"}
        message={
          "Are you sure you want to delete this item? This action cannot be undone."
        }
      />
    </div>
  );
}

export default Setting;
