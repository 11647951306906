import React from 'react'

const SearchBox = ({onChange}) => {
  return (
    <div>
      <div className="input-icon ">
<input
  type="text"
  className="form-control"
  placeholder="Search…"
  onChange={onChange}
/>
<span className="input-icon-addon">
  <svg
    xmlns="http://www.w3.org/2000/svg"
    className="icon"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    strokeWidth="2"
    stroke="currentColor"
    fill="none"
    strokeLinecap="round"
    strokeLinejoin="round"
  >
    <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
    <path d="M10 10m-7 0a7 7 0 1 0 14 0a7 7 0 1 0 -14 0"></path>
    <path d="M21 21l-6 -6"></path>
  </svg>
</span>
</div>
    </div>
  )
}

export default SearchBox