import { Outlet, RouterProvider, useNavigate } from "react-router-dom";
import "./App.css";


function App({router}) {

  return <>
   <RouterProvider router={router} >
   <Outlet />
    </RouterProvider>
   
  </> 
}

export default App;
