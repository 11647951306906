import instance from "../../api/axiosInstance";
import { createFormData } from "../../utils/common";



export const login=(payload)=>{
    let _payload=createFormData(payload)
    return instance.post("users/loginUser",_payload)
} 
export const signup=(payload)=>{
    return instance.post("users/signup",payload)
} 
