import React, { useEffect, useState } from "react";
import { iconsImgs } from "../../utils/images";
import JSONToExcel from "../../components/JsonToExcel";
import SearchBox from "../../components/SearchBox";
import ShimmerUITable from "../../components/shimmerUi/ShimmerTable";
import { useDispatch, useSelector } from "react-redux";
import DeleteDialog from "../../components/DeleteDialog";
import { errorMessage, successMessage } from "../../utils/tostify";
import { debounce } from "../../utils/common";
import { fetchSupervisors, setSupervisorFilter } from "./supervisorSlice";
import SupervisorForm from "./SupervisorForm";
import { ActiveInactive, ActiveInactiveID } from "../../data/dropdown";
import { deleteSupervisorDetailsById } from "./supervisor.api";
import { useParams } from "react-router-dom";
import { setPageTitle } from "../setting/globalSetting.slice";

const initialValues = {
  id: "",
  name: "",
  userid: "",
  status: "",
  manager: "",
  planner: "",
  mobile: "",
  imei: "",
  address: "",
  email: "",
};

function Supervisor() {
  let { status } = useParams();

  const dispatch = useDispatch();
  const [isEdit, setIsEdit] = useState(false);
  const [editItemDetails, setEditItemDetails] = useState({});
  const [deletedId, setDeletedId] = useState(null);
  const {
    supervisors:supervisorList,
    loading,
    isDeletedSuccessful,
    isSavedSuccessful,
    filterSupervisorListData,
    error,
  } = useSelector((state) => state.supervisors);
  const {
    dropdownList,pageTitle
  } = useSelector((state) => state.globalSetting);

  const [showModal, setShowModal] = useState(false);
  let message = isEdit? "Supervisor Updated Successfully" : "Supervisor Added Successfully";

  useEffect(() => {
    if (isDeletedSuccessful) {
      dispatch(fetchSupervisors());
      successMessage("Supervisor Deleted Successfully");
    } else if (isSavedSuccessful) {
      dispatch(fetchSupervisors());
      successMessage(message);
    }

    return () => {};
  }, [isDeletedSuccessful, isSavedSuccessful]);

  useEffect(()=>{
    if(Object.keys(dropdownList).length>0 && status){
      dispatch(setPageTitle(status));
      dispatch(fetchSupervisors(status));

    } 
  },[dropdownList,status])

  useEffect(() => {
    dispatch(fetchSupervisors());
  }, []);

  useEffect(() => {
    console.log(error);
    if (error) {
      errorMessage(error);
    }
  }, [error]);

  const onDelete = (cb) => {
    dispatch(deleteSupervisorDetailsById({ id: deletedId }));
    cb();
  };

  const filterData = (query, keys) => {
    const filteredData = filterSupervisorListData.filter((item) =>
      keys.some((key) => item[key].toLowerCase().includes(query.toLowerCase()))
    );
    dispatch(setSupervisorFilter(filteredData));
  };

  const debouncedFilterData = debounce(filterData, 800);

  return (
    <div>
      <div className="card">
        {/* Page Header */}
        <div className="card-header d-flex justify-content-between">
          <h3 className="card-title">Supervisor Master Table</h3>
          <div>
            <a
              className="btn btn-bitbucket text-white"
              data-bs-toggle="modal"
              data-bs-target="#supervisorFormModal"
              onClick={(e) => {
                setShowModal(true);
                setEditItemDetails(initialValues);
                setIsEdit(false);
              }}
            >
              <img src={iconsImgs.plus} width={20} height={20} alt="Add" />
              Add
            </a>

            <JSONToExcel data={supervisorList} fileName="supervisor" />
          </div>
        </div>

        <div className="card-body border-bottom py-3">
          <div className="d-flex">
            <div className="text-muted">
              Show
              <div className="mx-2 d-inline-block">
                <select className="form-control ">
                  <option className="dropdown-item">5</option>
                  <option className="dropdown-item">10</option>
                  <option className="dropdown-item">25</option>
                </select></div>
              entries
            </div>
            <div className="ms-auto text-muted">
              <SearchBox
                onChange={(e) => {
                  debouncedFilterData(e.target.value, [
                    "name",
                    "userid",
                    "manager",
                    "planner",
                    "mobile",
                    "email",
                  ]);
                }}
              />
            </div>
          </div>
        </div>
        {loading? (
          <ShimmerUITable row={4} />
        ) : (
          <div className="table-responsive">
            <table className="table card-table table-vcenter text-nowrap datatable">
              <thead>
                <tr>
                  {/* <th className="w-1">Id.</th> */}
                  <th>Name</th>
                  <th>Status</th>
                  <th>User ID</th>
                  <th>Manager</th>
                  <th>Planner</th>
                  <th>Mobile</th>
                  <th>Email</th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                {supervisorList?.map((supervisor) => {
                  return (
                    <tr key={supervisor.id}>
                      {/* <td>
                        <span className="text-muted">{supervisor.id}</span>
                      </td> */}
                      <td>{supervisor.name}</td>
                      <td>
                        {ActiveInactiveID.active.includes(Number(supervisor.status)) ? (
                          <span className="status-dot status-dot-animated  bg-success me-2"></span>
                        ) : (
                          <span className=" status-dot status-dot-animated  bg-danger me-2"></span>
                        )}

                        {ActiveInactive[supervisor.status] || "Others"}
                      </td>
                      <td>{supervisor.userid}</td>
                     
                   


                      <td>{supervisor.manager}</td>
                      <td>{supervisor.planner}</td>
                      <td>{supervisor.mobile}</td>
                      <td>{supervisor.email}</td>
                      <td className="text-end">
                        <a
                          className="btn  w-25 text-white btn-icon mx-2 text-primary"
                          aria-label="Facebook"
                          data-bs-toggle="modal"
                          data-bs-target="#supervisorFormModal"
                          title="Edit"
                          onClick={() => {
                            setEditItemDetails(supervisor);
                            setIsEdit(true);
                            setShowModal(true);
                          }}
                        >
                          <img
                            src={iconsImgs.edit}
                            width={20}
                            height={20}
                            alt=""
                          />
                        </a>
                        <a
                          data-bs-toggle="modal"
                          data-bs-target="#supervisor-delete"
                          className="btn w-25  mx-2 btn-icon text-danger"
                          aria-label="delete"
                          title="Delete"
                          onClick={() => {
                            setDeletedId(supervisor.id);
                          }}
                        >
                          <img
                            color="red"
                            src={iconsImgs.deleteIcon}
                            width={20}
                            height={20}
                            alt=""
                          />
                        </a>
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
        )}
        <div className="card-footer d-flex align-items-center">
          <p className="m-0 text-muted">
            Showing <span>1</span> to <span>8</span> of{" "}
            <span>{supervisorList?.length}</span> entries
          </p>
        
        </div>
      </div>
      <DeleteDialog
        modalId="supervisor-delete"
        onDeleteCall={onDelete}
        closeId={"deleteNoButtonSupervisor"}
        message={
          "Are you sure you want to delete this item? This action cannot be undone."
        }
      />
      <SupervisorForm
        id="supervisorFormModal"
        editItemDetails={editItemDetails}
        isEdit={isEdit}
        setShowModal={setShowModal}
        showModal={showModal}
        dropdownList={dropdownList}

      />


    </div>
  );
}

export default Supervisor;