import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import {
  deleteDetailsById,
  editGlobalSettingDetails,
  fetchGlobalSettingDetails,
  saveGlobalSettingDetails,
  getGlobalVeriablesByTypeDetails,
} from "./globalSetting.api";
import { convertToNested, filterAndGroupById } from "../../utils/common";


const initialState = {
  loading: false,
  error: "",
  globalSetting: [],
  isDeletedSuccessful: false,
  isSavedSuccessful: false,
  filterGlobalSettingListData: [],
  filterGlobalSettingTypewiseListData: [],
  originalData:[],
  dropdownList:{},
  pageTitle:"",

  typeWiseListDropdown:{},
  typeWiseList:[],

};
export const fetchGlobalSetting = createAsyncThunk(
  "globalSetting/fetchGlobalSetting",
  () => fetchGlobalSettingDetails()
);

export const saveGlobalSetting = createAsyncThunk(
  "globalSetting/saveGlobalSetting",
  (data) => saveGlobalSettingDetails(data)
);
export const deleteGlobalSetting = createAsyncThunk(
  "globalSetting/deleteGlobalSetting",
  (id) => deleteDetailsById(id)
);

export const editGlobalSetting = createAsyncThunk(
  "globalSetting/editGlobalSetting",
  (payload) => editGlobalSettingDetails(payload)
);
export const getGlobalVeriablesByTypeDetailsAPI = createAsyncThunk(
  "globalvariable/getByType",
  (payload) => getGlobalVeriablesByTypeDetails(payload)
);

const globalSettingSlice = createSlice({
  name: "globalSetting",
  initialState,
  reducers: {
    setGlobalSettingFilter: (state, action) => {
      state.globalSetting = action.payload;
    },
    setPageTitle(state,action){
   
    let pageName=state.originalData.find((x)=> x.id==action.payload)?.name;
    state.pageTitle= pageName

    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchGlobalSetting.pending, (state) => {
        state.loading = true;
        state.error = "";
      })
      .addCase(getGlobalVeriablesByTypeDetailsAPI.pending, (state) => {
          state.loading = true;
          state.error = "";
        })
      .addCase(fetchGlobalSetting.fulfilled, (state, action) => {
        state.error = "";
        state.isDeletedSuccessful = false;
        state.isSavedSuccessful = false;
        state.originalData=action.payload?.data.data;
        state.globalSetting = convertToNested(action.payload?.data.data)[0]?.children;
        state.dropdownList=filterAndGroupById(action.payload?.data.data)
        state.loading = false;


        state.filterGlobalSettingListData = action.payload?.data.data;
      })
      .addCase(getGlobalVeriablesByTypeDetailsAPI.fulfilled, (state, action) => {
          state.error = "";
          state.isDeletedSuccessful = false;
          state.isSavedSuccessful = false;
          state.originalData=action.payload?.data.data;
          state.typeWiseList = convertToNested(action.payload?.data.data)[0]?.children;
          state.typeWiseListDropdown=filterAndGroupById(action.payload?.data.data)
          state.loading = false;
          state.typeWiseList=action.payload?.data.data;
          state.filterGlobalSettingTypewiseListData = action.payload?.data.data;
        })
      .addCase(fetchGlobalSetting.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message || "Something went wrong!";
      })
      .addCase(getGlobalVeriablesByTypeDetailsAPI.rejected, (state, action) => {
          state.loading = false;
          state.error = action.error.message || "Something went wrong!";
        })
      //Save globalSetting
      .addCase(saveGlobalSetting.pending, (state) => {
        state.loading = true;
        state.error = "";
      })
      .addCase(saveGlobalSetting.fulfilled, (state, action) => {
        state.loading = false;
        state.isSavedSuccessful = true;
      })
      .addCase(saveGlobalSetting.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message || "Something went wrong!";
      })
      //Delete globalSetting
      .addCase(deleteGlobalSetting.pending, (state) => {
        state.loading = true;
        state.error = "";
      })
      .addCase(deleteGlobalSetting.fulfilled, (state, action) => {
        state.loading = false;
        state.isDeletedSuccessful = true;
      })
      .addCase(deleteGlobalSetting.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message || "Something went wrong!";
      })
      //Edit globalSetting
      .addCase(editGlobalSetting.pending, (state) => {
        state.loading = true;
        state.error = "";
      })
      .addCase(editGlobalSetting.fulfilled, (state, action) => {
        state.loading = false;
        state.isSavedSuccessful = true;
      })
      .addCase(editGlobalSetting.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message || "Something went wrong!";
      });

      //Get by type
     
    

  },
});

export default globalSettingSlice.reducer;
export const { setGlobalSettingFilter,setPageTitle } = globalSettingSlice.actions;
