import React from "react";
import { iconsImgs } from "../../utils/images";
import Chart from "react-apexcharts";
import TimeLine from "../../components/TimeLine";


const graphDetails={
  options: {
    chart: {
      type: 'line',
         
          sparkline: {
            enabled: true
          },
        
    },
    colors: ['#DCE6EC'],
    
    tooltip: {
      fixed: {
        enabled: false
      },
      x: {
        show: false
      },
      y: {
        title: {
          formatter: function (seriesName) {
            return ''
          }
        }
      },
    },
    marker: {
      show: false
    }


  },
  series: [
    {
      name: "series-1",
      data: [10, 90, 55, 5, 20, 90, 10, 91,9,8,88,77,66,5]
    }
  ]
}; 

function Home() {
  return (
    <>
    <h1>Dashboard Empty</h1>
    </>
  )

  // return (
  //   <div>
  //     <div className="page-header d-print-none my-3">
  //         <div className="container-xl">
  //           <div className="row g-2 align-items-center">
  //             <div className="col">
        
  //               <div className="page-pretitle">
  //                 Overview
  //               </div>
  //               <h2 className="page-title">
  //                Dashboard
  //               </h2>
  //             </div>
         
  //             <div className="col-auto ms-auto d-print-none">
  //               <div className="btn-list">
  //                 <span className="d-none d-sm-inline">
  //                   <a className="btn">
  //                     New view
  //                   </a>
  //                 </span>
  //                 <a  className="btn btn-primary text-white d-none d-sm-inline-block">
              
  //                   <svg xmlns="http://www.w3.org/2000/svg" className="icon" width="24" height="24" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round"><path stroke="none" d="M0 0h24v24H0z" fill="none"></path><path d="M12 5l0 14"></path><path d="M5 12l14 0"></path></svg>
  //                   Create new report
  //                 </a>
  //                 <a  className="btn btn-primary text-white d-sm-none btn-icon"aria-label="Create new report">
                 
  //                   <svg xmlns="http://www.w3.org/2000/svg" className="icon" width="24" height="24" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round"><path stroke="none" d="M0 0h24v24H0z" fill="none"></path><path d="M12 5l0 14"></path><path d="M5 12l14 0"></path></svg>
  //                 </a>
  //               </div>
  //             </div>
  //           </div>
  //         </div>
  //       </div>
  //     <div className="container-xl">
  //       <div className="row row-deck row-cards">
  //         <div className="col-sm-6 col-lg-4">
  //           <div className="card">
  //             <div className="card-body">
  //               <div className="d-flex align-items-center">
  //                 <div className="subheader">Sales</div>
  //                 <div className="ms-auto lh-1">
  //                   <div className="dropdown">
  //                     <a
  //                       className="dropdown-toggle text-muted"
  //                       href="#"
  //                       data-bs-toggle="dropdown"
  //                       aria-haspopup="true"
  //                       aria-expanded="false"
  //                     >
  //                       Last 7 days
  //                     </a>
  //                     <div className="dropdown-menu dropdown-menu-end" >
  //                       <a className="dropdown-item active" href="#">
  //                         Last 7 days
  //                       </a>
  //                       <a className="dropdown-item" href="#">
  //                         Last 30 days
  //                       </a>
  //                       <a className="dropdown-item" href="#">
  //                         Last 3 months
  //                       </a>
  //                     </div>
  //                   </div>
  //                 </div>
  //               </div>
  //               <div className="h1 mb-3">75%</div>
  //               <div className="d-flex mb-2">
  //                 <div>Conversion rate</div>
  //                 <div className="ms-auto">
  //                   <span className="text-green d-inline-flex align-items-center lh-1">
  //                     7%
  //                     <svg
  //                       xmlns="http://www.w3.org/2000/svg"
  //                       className="icon ms-1"
  //                       width="24"
  //                       height="24"
  //                       viewBox="0 0 24 24"
  //                       stroke-width="2"
  //                       stroke="currentColor"
  //                       fill="none"
  //                       stroke-linecap="round"
  //                       stroke-linejoin="round"
  //                     >
  //                       <path
  //                         stroke="none"
  //                         d="M0 0h24v24H0z"
  //                         fill="none"
  //                       ></path>
  //                       <path d="M3 17l6 -6l4 4l8 -8"></path>
  //                       <path d="M14 7l7 0l0 7"></path>
  //                     </svg>
  //                   </span>
  //                 </div>
  //               </div>
  //               <div className="progress progress-sm">
  //                 <div
  //                   className="progress-bar bg-primary"
  //                   style= {{width:"75%"}}
  //                   role="progressbar"
  //                   aria-valuenow="75"
  //                   aria-valuemin="0"
  //                   aria-valuemax="100"
  //                   aria-label="75% Complete"
  //                 >
  //                   <span className="visually-hidden">75% Complete</span>
  //                 </div>
  //               </div>
  //             </div>
  //           </div>
  //         </div>
  //         <div className="col-sm-6 col-lg-4">
  //         <div className="card">
  //                 <div className="card-body">
  //                   <div className="d-flex align-items-center">
  //                     <div className="subheader">Revenue</div>
  //                     <div className="ms-auto lh-1">
  //                       <div className="dropdown">
  //                         <a className="dropdown-toggle text-muted" href="#" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">Last 7 days</a>
  //                         <div className="dropdown-menu dropdown-menu-end" >
  //                           <a className="dropdown-item active" href="#">Last 7 days</a>
  //                           <a className="dropdown-item" href="#">Last 30 days</a>
  //                           <a className="dropdown-item" href="#">Last 3 months</a>
  //                         </div>
  //                       </div>
  //                     </div>
  //                   </div>
  //                   <div className="d-flex align-items-baseline pb-3">
  //                     <div className="h1 mb-0 me-2">$4,300</div>
  //                     <div className="me-auto">
  //                       <span className="text-green d-inline-flex align-items-center lh-1">
  //                         8% 
  //                         <svg xmlns="http://www.w3.org/2000/svg" className="icon ms-1" width="24" height="24" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round"><path stroke="none" d="M0 0h24v24H0z" fill="none"></path><path d="M3 17l6 -6l4 4l8 -8"></path><path d="M14 7l7 0l0 7"></path></svg>
  //                       </span>
  //                     </div>
                     
  //                   </div>
  //                   <Chart
  //             options={graphDetails.options}
  //             series={graphDetails.series}
  //             type="area"
  //             height={35}
  //           />
                  
  //                 </div> 
  //               </div>
  //         </div>
  //         <div className="col-sm-6 col-lg-4">
  //         <div className="card">
  //                 <div className="card-body">
  //                   <div className="d-flex align-items-center">
  //                     <div className="subheader">Revenue</div>
  //                     <div className="ms-auto lh-1">
  //                       <div className="dropdown">
  //                         <a className="dropdown-toggle text-muted" href="#" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">Last 7 days</a>
  //                         <div className="dropdown-menu dropdown-menu-end" >
  //                           <a className="dropdown-item active" href="#">Last 7 days</a>
  //                           <a className="dropdown-item" href="#">Last 30 days</a>
  //                           <a className="dropdown-item" href="#">Last 3 months</a>
  //                         </div>
  //                       </div>
  //                     </div>
  //                   </div>
  //                   <div className="d-flex align-items-baseline pb-3">
  //                     <div className="h1 mb-0 me-2">$4,300</div>
  //                     <div className="me-auto">
  //                       <span className="text-green d-inline-flex align-items-center lh-1">
  //                         8% 
  //                         <svg xmlns="http://www.w3.org/2000/svg" className="icon ms-1" width="24" height="24" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round"><path stroke="none" d="M0 0h24v24H0z" fill="none"></path><path d="M3 17l6 -6l4 4l8 -8"></path><path d="M14 7l7 0l0 7"></path></svg>
  //                       </span>
  //                     </div>
  //                   </div>
  //                   <Chart
  //             options={graphDetails.options}
  //             series={graphDetails.series}
  //             type="bar"
  //             height={35}
              
  //           />
  //                 </div>
  //               </div>
  //         </div>

  //         <div className="col-12">
  //               <div className="row row-cards">
  //                 <div className="col-sm-6 col-lg-3">
  //                   <div className="card card-sm">
  //                     <div className="card-body">
  //                       <div className="row align-items-center">
  //                         <div className="col-auto">
  //                           <span className="bg-primary text-white avatar">
  //                             <svg xmlns="http://www.w3.org/2000/svg" className="icon" width="24" height="24" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round"><path stroke="none" d="M0 0h24v24H0z" fill="none"></path><path d="M16.7 8a3 3 0 0 0 -2.7 -2h-4a3 3 0 0 0 0 6h4a3 3 0 0 1 0 6h-4a3 3 0 0 1 -2.7 -2"></path><path d="M12 3v3m0 12v3"></path></svg>
  //                           </span>
  //                         </div>
  //                         <div className="col">
  //                           <div className="font-weight-medium">
  //                             132 Sales
  //                           </div>
  //                           <div className="text-muted">
  //                             12 waiting payments
  //                           </div>
  //                         </div>
  //                       </div>
  //                     </div>
  //                   </div>
  //                 </div>
  //                 <div className="col-sm-6 col-lg-3">
  //                   <div className="card card-sm">
  //                     <div className="card-body">
  //                       <div className="row align-items-center">
  //                         <div className="col-auto">
  //                           <span className="bg-green text-white avatar">
  //                             <svg xmlns="http://www.w3.org/2000/svg" className="icon" width="24" height="24" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round"><path stroke="none" d="M0 0h24v24H0z" fill="none"></path><path d="M6 19m-2 0a2 2 0 1 0 4 0a2 2 0 1 0 -4 0"></path><path d="M17 19m-2 0a2 2 0 1 0 4 0a2 2 0 1 0 -4 0"></path><path d="M17 17h-11v-14h-2"></path><path d="M6 5l14 1l-1 7h-13"></path></svg>
  //                           </span>
  //                         </div>
  //                         <div className="col">
  //                           <div className="font-weight-medium">
  //                             78 Orders
  //                           </div>
  //                           <div className="text-muted">
  //                             32 shipped
  //                           </div>
  //                         </div>
  //                       </div>
  //                     </div>
  //                   </div>
  //                 </div>
  //                 <div className="col-sm-6 col-lg-3">
  //                   <div className="card card-sm">
  //                     <div className="card-body">
  //                       <div className="row align-items-center">
  //                         <div className="col-auto">
  //                           <span className="text-success avatar">
  //                           <img src={iconsImgs.newgears} width={30} height={30} /> </span>
  //                         </div>
  //                         <div className="col">
  //                           <div className="font-weight-medium">
  //                           Total MI to be done
  //                           </div>
  //                           <div className="text-muted">
  //                             160 per today
  //                           </div>
  //                         </div>
  //                       </div>
  //                     </div>
  //                   </div>
  //                 </div>
  //                 <div className="col-sm-6 col-lg-3">
  //                   <div className="card card-sm">
  //                     <div className="card-body">
  //                       <div className="row align-items-center">
  //                         <div className="col-auto">
  //                           <span className="bg-facebook text-white avatar">
  //         <img src={iconsImgs.newgears} width={30} height={30} ></img>

  //                          </span>
  //                         </div>
  //                         <div className="col">
  //                           <div className="font-weight-medium">
  //                           Total MI to be done
  //                           </div>
  //                           <div className="text-muted">
  //                            5666
  //                           </div>
  //                         </div>
  //                       </div>
  //                     </div>
  //                   </div>
  //                 </div>
  //               </div>
  //             </div>
  //         </div>
  //     </div>



  //  </div>
  // );
}

export default Home;

   