import React, { useEffect, useRef, useState } from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import { useDispatch } from "react-redux";
import { editOEM, saveOEM, uploadCoverImg } from "./oemSlice";
import { personsImgs } from "../../utils/images";
import { OptionsMaster } from "../../data/dropdown";

function OEMForm({dropdownList, id, editItemDetails, isEdit, showModal, setShowModal }) {
  const dispatch = useDispatch();
  const modalRef = useRef(null);
  const formRef = useRef();
  const editLogoRef = useRef();
  const [isLogoPicChanged, setIsLogoPicChanged] = useState(false);
  const [logoImg, setLogoImg] = useState(null);

  useEffect(() => {
    console.log(editItemDetails);
    if (editItemDetails?.logo) {
      setLogoImg(editItemDetails?.logo);
    }

    return () => {};
  }, [editItemDetails]);

  const closeModal = () => {
    const button = document.getElementById("closeEditModalBtnOEM");
    button.click();
  };

  const validationSchema = Yup.object({
    name: Yup.string().required("Name is required"),
    address: Yup.string().required("Address is required"),
    contactnumber: Yup.string().required("Contact Number is required"),
    email: Yup.string().required("Email is required"),
    status: Yup.string().required("Status is required"),
  });

  const onSubmit = async (values, { resetForm, setSubmitting }) => {
    debugger
    try {
      if (isEdit) {
        if (isLogoPicChanged) {
          const originalPromiseResult = await dispatch(
            uploadCoverImg({ userfile: logoImg })
          ).unwrap();
           values.logo = originalPromiseResult.data.data;
        }
        dispatch(editOEM(values));
      } else {
        const originalPromiseResult = await dispatch(
          uploadCoverImg({ userfile: logoImg })
        ).unwrap();

        values.logo = originalPromiseResult.data.data;
        dispatch(saveOEM(values));
      }
      setSubmitting(false);
      resetForm();
      closeModal();
    } catch (rejectedValueOrSerializedError) {}
  };

  return (
    <div
      className="modal modal-blur fade"
      id={id}
      tabIndex="-1"
      role="dialog"
      aria-hidden="true"
      ref={modalRef}
      data-bs-backdrop="static"
    >
      <div
        className="modal-dialog modal-lg modal-dialog-centered"
        role="document"
      >
        <div className="modal-content">
          {showModal && (
            <Formik
              initialValues={{
                id: editItemDetails?.id || "",
                name: editItemDetails?.name || "",
                address: editItemDetails?.address || "",
                contactnumber: editItemDetails?.contactnumber || "",
                email: editItemDetails?.email || "",
                status: editItemDetails?.status || "",
                logo: editItemDetails?.logo || "",
              }}
              validationSchema={validationSchema}
              onSubmit={onSubmit}
            >
              {({ isSubmitting, resetForm, setFieldValue }) => (
                <Form ref={formRef}>
                  <div className="modal-header">
                    <h5 className="modal-title">OEM Form</h5>
                    <button
                      onClick={() => {
                        setShowModal(false);
                        resetForm();
                        setLogoImg("");
                        setIsLogoPicChanged(false);
                      }}
                      type="button"
                      className="btn-close"
                      data-bs-dismiss="modal"
                      aria-label="Close"
                    ></button>
                  </div>
                  <div className="modal-body">
                    <div className="row">
                      <div className="col-lg-6">
                        <div className="mb-3">
                          <label htmlFor="name">Name</label>
                          <Field
                            type="text"
                            id="name"
                            name="name"
                            className="form-control"
                          />
                          <ErrorMessage
                            name="name"
                            component="div"
                            className="error"
                          />
                        </div>
                      </div>
                      <div className="col-lg-6">
                        <div className="mb-3">
                          <label htmlFor="address">Address</label>
                          <Field
                           as="textarea"
                            id="address"
                            name="address"
                            className="form-control"
                          />
                          <ErrorMessage
                            name="address"
                            component="div"
                            className="error"
                          />
                        </div>
                      </div>

                      <div className="col-lg-6">
                        <div className="mb-3">
                          <label htmlFor="contactnumber">
                            Contact Number
                          </label>
                          <Field
                            type="text"
                            id="contactnumber"
                            name="contactnumber"
                            className="form-control"
                          />
                          <ErrorMessage
                            name="contactnumber"
                            component="div"
                            className="error"
                          />
                        </div>
                      </div>
                      <div className="col-lg-6">
                        <div className="mb-3">
                          <label htmlFor="email">Email</label>
                          <Field
                            type="email"
                            id="email"
                            name="email"
                            className="form-control"
                          />
                          <ErrorMessage
                            name="email"
                            component="div"
                            className="error"
                          />
                        </div>
                      </div>
                    </div>

                    <div className="row">
                      <div className="col-lg-6">
                        <div className="mb-3">
                          <label htmlFor="status">Status</label>
                          <Field
                            as="select"
                            id="status"
                            name="status"
                            className="form-control"
                          >
                            <option value="">Select Status</option>
                            {dropdownList[OptionsMaster.status].map((item) => {
                              return (
                                <option value={item.id} key={item.id}>
                                  {item.name}
                                </option>
                              );
                            })}


                          </Field>
                          <ErrorMessage
                            name="status"
                            component="div"
                            className="error"
                          />
                        </div>
                      </div>

                      <div className="col-lg-6">
                        {!isEdit ? (
                          <div className="mb-3">
                            {logoImg ? (
                              <>
                                <img
                                  src={URL.createObjectURL(logoImg)}
                                  className="rounded-circle"
                                  style={{ width: 60, height: 60 }}
                                  alt="Logo"
                                />
                              </>
                            ) : (
                              <>
                                <label htmlFor="logo">Logo</label>
                                <Field
                                  type="file"
                                  id="logo"
                                  name="filename"
                                  accept="image/*"
                                  className="form-control"
                                  onChange={(event) => {
                                    event.preventDefault();
                                    const formData = new FormData(
                                      formRef.current
                                    );
                                    const file = formData.get("filename");
                                    console.log(file);
                                    setLogoImg(file);
                                  }}
                                />

                                <ErrorMessage
                                  name="logo"
                                  component="div"
                                  className="error"
                                />
                              </>
                            )}
                          </div>
                        ) : (
                          <>
                            {isLogoPicChanged ? (
                              <img
                                src={URL.createObjectURL(logoImg)}
                                className="rounded-circle"
                                style={{ width: 60, height: 60 }}
                                alt="Logo"
                              />
                            ) : (
                              <img
                                onError={(e) =>
                                  (e.target.src = personsImgs.person_three)
                                }
                                src={`http://apiinnovel.apiitech.co.in/uploads/${logoImg}`}
                                className="rounded-circle"
                                style={{ width: 60 }}
                                alt="Logo"
                              />
                            )}

                            <Field
                              innerRef={editLogoRef}
                              type="file"
                              id="logo"
                              name="filename"
                              accept="image/*"
                              className="form-control"
                              onChange={(event) => {
                                event.preventDefault();
                                const formData = new FormData(formRef.current);
                                const file = formData.get("filename");
                                setLogoImg(file);
                                setIsLogoPicChanged(true);
                              }}
                              hidden
                            />

                            <button
                              className="btn mx-4 btn-outline-info"
                              onClick={(e) => {
                                e.stopPropagation();
                                e.preventDefault();
                                console.log(editLogoRef);
                                editLogoRef.current.click();
                              }}
                            >
                              Change Picture
                            </button>
                          </>
                        )}
                      </div>
                    </div>
                  </div>
                  <div className="modal-footer">
                    <a
                      className="btn ouline-secondary"
                      data-bs-dismiss="modal"
                      id="closeEditModalBtnOEM"
                      onClick={() => {
                        setLogoImg("");
                        setShowModal(false);
                        resetForm();
                        setIsLogoPicChanged(false);
                      }}
                    >
                      Cancel
                    </a>
                    <button
                      disabled={isSubmitting}
                      type="submit"
                      className="btn btn-primary ms-auto text-white"
                    >
                      {isEdit ? "Update OEM" : "Create New OEM"}
                    </button>
                  </div>
                </Form>
              )}
            </Formik>
          )}
        </div>
      </div>
    </div>
  );
}

export default OEMForm;