export const ActiveInactive={
    11: 'Active',
    12: 'Inactive',
    105: "Hyper Active"
}
export const ActiveInactiveID={
  active:[11,105],
  inactive:[12]
}

export const OptionsMaster={
    gender:2,
    status:10,
    usertype:15,
    documents:19
 }
 
 