import React, { useEffect, useState } from "react";
import {
  MDBBtn,
  MDBModalDialog,
  MDBModalContent,
  MDBModalHeader,
  MDBModalTitle,
  MDBModalBody,
  MDBModalFooter,
  MDBIcon,
  MDBTooltip,
  MDBModal,
} from "mdb-react-ui-kit";
import Select from "react-select";
import { useDispatch, useSelector } from "react-redux";
import {
    bulkUploadMeterDiscom,
  getWarehouseStockList,
  makeBlank,
  selectAllMeter,
  selectMeter,
  setFalse,
} from "../features/meter/meterSlice";
import ShimmerUITable from "../components/shimmerUi/ShimmerTable";
import { fetchdiscomlabs } from "../features/discomLab/discomlabSlice";
import { errorMessage, successMessage } from "../utils/tostify";

const MaterialOutwardMovement = ({ warehouseList,getFetchedData }) => {
  const { discomlab: discomlabList = [] } = useSelector(
    (state) => state.discomlab
  );
  const dispatch = useDispatch();
  const { warehouseStockList, loading, error,isDiscomBulkUploadDone } = useSelector(
    (state) => state.meters
  );
  console.log(warehouseStockList);
  useEffect(() => {
    if (discomlabList.length == 0) dispatch(fetchdiscomlabs());

    return () => {};
  }, []);

  const [staticModal, setStaticModal] = useState(false);
  const [warehouseValue, setWarehouseValue] = useState("");
  const [discomValue, setDiscomValue] = useState("");
  const [isAllChecked, setIsAllChcked] = useState(false);

  useEffect(() => {
    if (isDiscomBulkUploadDone) {
      successMessage("Upload successful");
      setStaticModal(false);
      getFetchedData();
      dispatch(setFalse());
      dispatch(makeBlank())
      setDiscomValue("")
      setWarehouseValue("");
      




    }
  }, [isDiscomBulkUploadDone]);

  useEffect(() => {
    if (error) {
      errorMessage(error);
    }
  }, [error]);

  const toggleOpen = () => setStaticModal(!staticModal);
  return (
    <>
      <MDBTooltip
        tag="a"
        wrapperProps={{ color: "secondary" }}
        placement="left"
        title="Material Outward Movement"
      >
        <button onClick={toggleOpen} className="btn btn-outline-primary">
          <MDBIcon fas icon="luggage-cart" size="2x" />
        </button>
      </MDBTooltip>

      <MDBModal
        staticBackdrop
        tabIndex="-1"
        open={staticModal}
        onClose={() => setStaticModal(false)}
      >
        <MDBModalDialog centered size="fullscreen">
          <MDBModalContent>
            <MDBModalHeader>
              <MDBModalTitle>Material Outward Movement</MDBModalTitle>
              <MDBBtn
                className="btn-close"
                color="none"
                onClick={toggleOpen}
              ></MDBBtn>
            </MDBModalHeader>
            <MDBModalBody>
              <div className="row">
                <div className="col-md-4">
                  <div className="mb-4 ">
                    <label className="form-label required">Warehouse</label>

                    <Select
                      placeholder={"select warehouse..."}
                      options={warehouseList.map((item) => ({
                        value: item.id,
                        label: `${item.name}`,
                      }))}
                      onChange={(e) => {
                        setWarehouseValue(e);
                        if (e?.value) dispatch(getWarehouseStockList(e?.value));
                      }}
                      value={warehouseValue}
                      isClearable={true}
                    />
                  </div>
                </div>
                <div className="row ">
                  <div className="col-md-6 border">
                    {loading ? (
                      <ShimmerUITable row={4} />
                    ) : (
                      <div className="table-responsive">
                        <table className="table card-table table-vcenter text-nowrap datatable">
                          <thead>
                            <tr>
                              {warehouseStockList.length > 0 && (
                                <th className="w-1">
                                  <input
                                    className="form-check-input m-0 align-middle"
                                    type="checkbox"
                                    aria-label="Select all invoices"
                                    checked={isAllChecked}
                                    onChange={(e) => {
                                      setIsAllChcked(!isAllChecked);
                                      dispatch(
                                        selectAllMeter(e.target.checked)
                                      );
                                    }}
                                  />
                                </th>
                              )}
                              <th className="w-1">Id.</th>
                              <th>NIC No.</th>

                              <th>Number</th>
                            </tr>
                          </thead>
                          <tbody>
                            {warehouseStockList.map((meter,i) => {
                              return (
                                <tr key={i}>
                                  <td>
                                    <input
                                      className="form-check-input m-0 align-middle"
                                      type="checkbox"
                                      checked={meter?.isSelected}
                                      aria-label="Select all invoices"
                                      onChange={(e) => {
                                        setIsAllChcked(false);
                                        dispatch(selectMeter(meter));
                                      }}
                                    />
                                  </td>
                                  <td>{meter.id}</td>
                                  <td>{meter.nicno}</td>
                                  <td>{meter.number}</td>
                                </tr>
                              );
                            })}
                          </tbody>
                        </table>
                        {warehouseStockList.length == 0 ? (
                          <div
                            className="alert alert-danger text-center my-4"
                            role="alert"
                          >
                            No Data Found
                          </div>
                        ) : (
                          ""
                        )}
                      </div>
                    )}
                  </div>
                  <div className="col-md-2 d-flex justify-content-center">
                    <MDBIcon fas icon="angle-double-right" size="4x" />
                  </div>
                  <div className="col-md-4">
                    <label className="form-label required">Discom Lab</label>

                    <Select
                      placeholder={"select discomlab..."}
                      options={discomlabList.map((item) => ({
                        value: item.id,
                        label: `${item.name}`,
                      }))}
                      onChange={(option) => {
                        console.log(option);

                        setDiscomValue(option);
                      }}
                      value={discomValue}
                      isClearable={true}
                    />
                  </div>
                </div>
              </div>
            </MDBModalBody>
            <MDBModalFooter>
              <button
                className="btn btn-outline-secoundary"
                onClick={toggleOpen}
              >
                Close
              </button>
              <button
                className="btn btn-outline-info"
                disabled={!warehouseValue ||!discomValue ||warehouseStockList.length==0}
                onClick={() => {
                  let JSONData = warehouseStockList.filter(
                    (item) => item.isSelected
                  );
                  if (JSONData.length > 0) {
                   let payload= JSONData.map((item) => {
                      return {
                        warehouse: warehouseValue.value,
                        warehousename: warehouseValue.label,

                        discomlab: discomValue.value,
                        discomlabname: discomValue.label,

                        discomstage: 133,
                        meter:item.id,
                        date: new Date(),
                      };
                  
                    });
         
                    dispatch(bulkUploadMeterDiscom(payload))
                  }
                }}
              >
                Send
              </button>
            </MDBModalFooter>
          </MDBModalContent>
        </MDBModalDialog>
      </MDBModal>
    </>
  );
};

export default MaterialOutwardMovement;
