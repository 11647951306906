import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { Provider } from 'react-redux';
import { store } from './app/store';
import {createBrowserRouter, redirect } from 'react-router-dom';
import ErrorPage from './pages/errorPage/ErrorPage';
import Vendor from './features/vendor/Vendor';
import Warehouse from './features/warehouse/Warehouse';

import Home from './features/home/Home';
import Setting from './features/setting/Setting';
import SignIn from './features/auth/signIn/SignIn';
import SignUp from './features/auth/signUp/SignUp';

import HomePage from './pages/HomePage';
import AuthPage from './pages/AuthPage';
import { ToastContainer} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
// import 'mdb-react-ui-kit/dist/css/mdb.min.css';
import "@fortawesome/fontawesome-free/css/all.min.css";
import Users from './features/users/Users';
import UserWrapper from './features/users/UserWrapper';
import Technician from './features/technician/Technician';
import OEM from './features/OEM/OEM';
import Supervisor from './features/supervisor/Supervisor';
import Meter from './features/meter/Meter';
import Consumer from './features/consumers/Consumer';
import DiscomLab from './features/discomLab/DiscomLab';


const router = createBrowserRouter([
  {
    path: "/",
    element: <HomePage />,
    errorElement: <ErrorPage />,
    children:[
      {
        path: "",
        element: <Home/>,
        errorElement: <ErrorPage />,
      },
      {
        path: "vendor",
        element: <Vendor/>,
        errorElement: <ErrorPage />,
      },
      {
        path: "setting",
        element: <Setting/>,
        errorElement: <ErrorPage />,
      },
      {
        path: "warehouse",
        element: <Warehouse/>,
        errorElement: <ErrorPage />,
      },
      {
        path: "discomlab",
        element: <DiscomLab/>,
        errorElement: <ErrorPage />,
      },
      {
        path: "technician",
        element: <Technician/>,
        errorElement: <ErrorPage />,
      },
      {
        path: "oem",
        element: <OEM/>,
        errorElement: <ErrorPage />,
      },{
        path: "supervisor",
        element: <Supervisor/>,
        errorElement: <ErrorPage />,
      },
      ,{
        path: "consumers",
        element: <Consumer/>,
        errorElement: <ErrorPage />,
      },
      {
        path: "meters",
        element: <Meter/>,
        errorElement: <ErrorPage />,
      },
      {
        path: "users",
        element: <UserWrapper/>,
        errorElement: <ErrorPage />,
        children:[
          {
            path: ":userType",
            element: <Users/>,
            errorElement: <ErrorPage />,
          }]
      }
    ]

  },{
    path: "/auth",
    element: <AuthPage />,
    errorElement: <ErrorPage />,
    children:[
      {
        path: "",
        element: <SignIn/>,
        errorElement: <ErrorPage />,
      },
      {
        path: "sign-in",
        element: <SignIn/>,
        errorElement: <ErrorPage />,
      },
      {
        path: "sign-up",
        element: <SignUp/>,
        errorElement: <ErrorPage />,
      },
    ]
  }
]);


const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <Provider store={store}>
   <ToastContainer
position="top-center"
autoClose={5000}
hideProgressBar={false}
newestOnTop={false}
closeOnClick
rtl={false}
pauseOnFocusLoss
draggable
pauseOnHover
theme="light"
/>
   <App router={router}/>

  </Provider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
