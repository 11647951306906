import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { login } from "./auth.api";

const initialState = {
  loading: false,
  error: "",
  loginUserDetails: null,
  isLoginSuccessful: false,
  token: null,
};

export const logInUser = createAsyncThunk("consumer/saveConsumer", (data) =>
  login(data)
);

const logInUserSlice = createSlice({
  name: "loginUser",
  initialState,
  reducers: {
    setLoginUserDetails: (state, action) => {
      state.loginUserDetails = action.payload;
    },
    setLoginUserToken: (state, action) => {
      state.token = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(logInUser.pending, (state) => {
        state.loading = true;
        state.error = "";
      })
      .addCase(logInUser.fulfilled, (state, action) => {
        state.loading = false;
        state.isLoginSuccessful = true;

        switch (action.payload.data.status) {
          case 200:
            state.loginUserDetails = action.payload.data.data?.data;
            state.token=action.payload.data?.data?.token;
            localStorage.setItem("innvelToken", action.payload.data?.data?.token);
            localStorage.setItem("innvelLoginUser", JSON.stringify(action.payload.data.data?.data));

            break;

          case 500:
            state.error =
              action.payload?.data?.data?.message || "Something went wrong!";
            break;

          default:
            break;
        }
      })
      .addCase(logInUser.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message || "Something went wrong!";
      });
  },
});
export default logInUserSlice.reducer;
export const { setLoginUserToken, setLoginUserDetails } = logInUserSlice.actions;
