import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { iconsImgs } from "../../utils/images";
import JSONToExcel from "../../components/JsonToExcel";
import ShimmerUITable from "../../components/shimmerUi/ShimmerTable";
import DeleteDialog from "../../components/DeleteDialog";
import ConsumerForm from "./ConsumerForm"; // Assuming ConsumerForm is the form component for adding/editing consumers
import { deleteConsumer, fetchConsumer, setConsumerFilter } from "./consumerSlice"; // Assuming corresponding slice functions
import { errorMessage, successMessage } from "../../utils/tostify"; // Assuming toast notification utility function
import { debounce } from "../../utils/common"; // Assuming debounce utility function
import { ActiveInactive, ActiveInactiveID } from "../../data/dropdown";
import { MDBIcon, MDBInput } from "mdb-react-ui-kit";
import { getGlobalVeriablesByTypeDetailsAPI } from "../setting/globalSetting.slice";


const Consumer = () => {
  const [searchText,setSearchText]=useState("");
  const itemsPerPageList = [
    { id: 1, name: "5", value: 5 },
    { id: 2, name: "10", value: 10 },
    { id: 3, name: "15", value: 15 },
  ];
  const [itemsPerPage, setItemPerPage] = useState(itemsPerPageList[0].value);
  const [currentPage, setCurrentPage] = useState(1);
  const { dropdownList } = useSelector((state) => state.globalSetting);
  const dispatch = useDispatch();
  const [isEdit, setIsEdit] = useState(false);
  const [editItemDetails, setEditItemDetails] = useState({});
  const [deletedId, setDeletedId] = useState(null);
  const {
    consumer: consumerList,
    loading,
    isDeletedSuccessful,
    isSavedSuccessful,
    isUpdatedSuccessful,
    filterConsumerListData,
    error,
  } = useSelector((state) => state.consumers);

  const {
    globalSetting:typeWiseList
  } = useSelector((state) => state.globalSetting);

 
  const [showModal, setShowModal] = useState(false);
  let message = isEdit ? "Consumer Updated Successfully" : "Consumer Added Successfully";
  useEffect(() => {
    if (isDeletedSuccessful) {
      dispatch(fetchConsumer({ itemsPerPage, currentPage }));
      successMessage("Consumer Deleted Successfully");
    } else if (isSavedSuccessful) {
      dispatch(fetchConsumer({ itemsPerPage, currentPage }));
      successMessage(message);
    }else if(isUpdatedSuccessful){
      dispatch(fetchConsumer({ itemsPerPage, currentPage }));
      successMessage(message);
    }else{}
  }, [isDeletedSuccessful, isSavedSuccessful, isUpdatedSuccessful]);

  const getInitialCall=()=>{
    dispatch(fetchConsumer({ itemsPerPage, currentPage }));
    dispatch(getGlobalVeriablesByTypeDetailsAPI({type:119}));

  }


  useEffect(() => {
    getInitialCall();
  }, [itemsPerPage, currentPage]);

  useEffect(() => {
    if (error) {
      errorMessage(error);
    }
  }, [error]);

  const onDelete = (cb) => {
    dispatch(deleteConsumer({ id: deletedId }));
    cb();
  };

let typewiseMeterTypeList=(typeWiseList.filter((x)=> x.id==140))[0]?.children;
let typewiseConsumerTypeList=(typeWiseList.filter((x)=> x.id==134))[0]?.children;
let typewiseDivisionTypeList=(typeWiseList.filter((x)=> x.id==137))[0]?.children;

  
  return (
    <div>
      <div className="card">
        <div className="card-header d-flex justify-content-between">
          <h3 className="card-title">Consumer Master Table</h3>
          <div>
            <a
              className="btn btn-bitbucket text-white"
              data-bs-toggle="modal"
              data-bs-target="#consumerFormModal"
              onClick={(e) => {
                setShowModal(true);
                setEditItemDetails({
                  id: "",
                  status: "",
                  type: "",
                  planner: "",
                  circle: "",
                  division: "",
                  town: "",
                  zone: "",
                  feeder: "",
                  dtrlocation: "",
                  dtrcapacity: "",
                  dtrcode: "",
                  ivrs: "",
                  name: "",
                  address: "",
                  mobile: "",
                  metertype: "",
                  meterno: "",
                  metercategory: "",
                  sactionload: "",
                  meterlocation: "",
                  servicecablecondition: "",
                  meterreading: "",
                  inbillfile: "",
                  latitude: "",
                  longitude: "",
                  remarks: ""
                });
                setIsEdit(false);
              }}
            >
              <img src={iconsImgs.plus} width={20} height={20} alt="Add" />
              Add
            </a>
            <JSONToExcel data={consumerList} fileName="consumer" />
          </div>
        </div>

        <div className="card-body border-bottom py-3">
          <div className="d-flex">
            <div className="text-muted">
              Show
              <div className="mx-2 d-inline-block">
              <select
                  className="form-control "
                  onChange={(e) => setItemPerPage(e.target.value)}
                >
                  {itemsPerPageList.map((item, i) => (
                    <option
                      className="dropdown-item cursor-pointer"
                      value={item.value}
                    >
                      {item.name}
                    </option>
                  ))}
                </select>
              </div>
              entries
            </div>
            <div className="ms-auto text-muted">
              <div className="mb-3">
                <div className="row g-2">
                  <div className="col">
                    <input type="text" className="form-control" placeholder="Search for consumer..." onChange={(e)=>setSearchText(e.target.value)}/>
                  </div>
                  <div className="col-auto">
                    <a  className="btn btn-icon" aria-label="Button">
                    <MDBIcon fas icon="search" size="1x" />
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {loading ? (
          <ShimmerUITable row={4} />
        ) : (
          <div className="table-responsive">
            <table className="table card-table table-vcenter text-nowrap datatable">
              <thead>
                <tr>
                  <th className="w-1">Id.</th>
                  <th>Status</th>
                  <th>Type</th>
                  {/* <th>Planner</th>
                  <th>Circle</th> */}
                  <th>Division</th>
                  {/* <th>Town</th>
                  <th>Zone</th>
                  <th>Feeder</th>
                  <th>DTR Location</th>
                  <th>DTR Capacity</th> */}
                  <th>DTR Code</th>
                  <th>IVRS</th>
                  <th>Name</th>
                  {/* <th>Address</th> */}
                  <th>Mobile</th>
                  <th>Meter Type</th>
                  <th>Meter No.</th>
                  {/* <th>Meter Category</th>
                  <th>Action Load</th>
                  <th>Meter Location</th>
                  <th>Service Cable Condition</th>
                  <th>Meter Reading</th>
                  <th>In Bill File</th>
                  <th>Latitude</th>
                  <th>Longitude</th>
                  <th>Remarks</th> */}
                  <th></th>
                </tr>
              </thead>
              <tbody>
                {consumerList?.map((consumer) => {
                  return (
                    <tr key={consumer.id}>
                      <td>
                        <span className="text-muted">{consumer.id}</span>
                      </td>
                      <td>
                        {ActiveInactiveID.active.includes(Number(consumer.status)) ? (
                          <span className="status-dot status-dot-animated  bg-success me-2"></span>
                        ) : (
                          <span className=" status-dot status-dot-animated  bg-danger me-2"></span>
                        )}

                        {ActiveInactive[consumer.status] || "Others"}
                      </td>

                      <td>{ ((typewiseConsumerTypeList.filter((x)=>x.id==consumer.type))[0]?.name) }</td>
                      {/* <td>{consumer.planner}</td>
                      <td>{consumer.circle}</td> */}
                      <td>{ ((typewiseDivisionTypeList.filter((x)=>x.id==consumer.division))[0]?.name) }</td>
                      {/* <td>{consumer.town}</td>
                      <td>{consumer.zone}</td>
                      <td>{consumer.feeder}</td>
                      <td>{consumer.dtrlocation}</td>
                      <td>{consumer.dtrcapacity}</td> */}
                      <td>{consumer.dtrcode}</td>
                      <td>{consumer.ivrs}</td>
                      <td>{consumer.name}</td>
                      {/* <td>{consumer.address}</td> */}
                      <td>{consumer.mobile}</td>
                      <td>{ ((typewiseMeterTypeList.filter((x)=>x.id==consumer.metertype))[0]?.name) }</td>
                      <td>{consumer.meterno}</td>
                      {/* <td>{consumer.metercategory}</td>
                      <td>{consumer.sactionload}</td>
                      <td>{consumer.meterlocation}</td>
                      <td>{consumer.servicecablecondition}</td>
                      <td>{consumer.meterreading}</td>
                      <td>{consumer.inbillfile}</td>
                      <td>{consumer.latitude}</td>
                      <td>{consumer.longitude}</td>
                      <td>{consumer.remarks}</td> */}
                      <td className="text-end">
                        <a
                          className="btn w-25 text-white btn-icon mx-2 text-primary"
                          aria-label="Edit"
                          data-bs-toggle="modal"
                          data-bs-target="#consumerFormModal"
                          title="Edit"
                          onClick={() => {
                            setEditItemDetails(consumer);
                            setIsEdit(true);
                            setShowModal(true);
                          }}
                        >
                          <img
                            src={iconsImgs.edit}
                            width={20}
                            height={20}
                            alt="Edit"
                          />
                        </a>
                        <a
                          data-bs-toggle="modal"
                          data-bs-target="#consumer-delete"
                          className="btn w-25 mx-2 btn-icon text-danger"
                          aria-label="Delete"
                          title="Delete"
                          onClick={() => {
                            setDeletedId(consumer.id);
                          }}
                        >
                          <img
                            src={iconsImgs.deleteIcon}
                            width={20}
                            height={20}
                            alt="Delete"
                          />
                        </a>
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
        )}
          {/* <div className="col-12">
                  <div className="__card">
                    <div className="card-body">
                      <ul className="pagination ">
                        <li className="page-item page-prev disabled">
                          <a className="page-link" tabindex="-1" aria-disabled="true">
                            <div className="page-item-subtitle btn btn-outline-primary">previous</div>
                  
                          </a>
                        </li>
                        <li className="page-item page-next">
                          <a className="page-link" >
                            <div className="page-item-subtitle btn btn-outline-primary">next</div>
                    
                          </a>
                        </li>
                      </ul>
                    </div>
                  </div>
          </div> */}
        <div className=" card-footer d-flex align-items-center justify-content-between">
            <div className="col-12">
              <div className="__card">
                <div className="card-body">
                  <ul className="pagination ">
                    <li
                      className={`page-item page-prev ${
                        currentPage <= 1 ? "disabled" : ""
                      } `}
                    >
                      <a className="page-link">
                        <div
                          className="page-item-subtitle btn btn-outline-info"
                          onClick={() => setCurrentPage((prev) => prev - 1)}
                        >
                          previous
                        </div>
                      </a>
                    </li>

                    <li
                      className={`page-item page-next ${
                        itemsPerPage > consumerList.length ? "disabled" : ""
                      } `}
                    >
                      <a className="page-link">
                        <div
                          className="page-item-subtitle btn btn-outline-info"
                          onClick={() => setCurrentPage((prev) => prev + 1)}
                        >
                          next
                        </div>
                        {/* <div className="page-item-title w-25 my-2">
                          Page no {currentPage}
                        </div> */}
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
        </div>
      
      </div>

      <DeleteDialog
        modalId="consumer-delete"
        onDeleteCall={onDelete}
        closeId={"deleteNoButtonConsumer"}
        message={
          "Are you sure you want to delete this item? This action cannot be undone."
        }
      />
      <ConsumerForm
        id="consumerFormModal"
        editItemDetails={editItemDetails}
        isEdit={isEdit}
        setShowModal={setShowModal}
        showModal={showModal}
        dropdownList={dropdownList}
        typewiseMeterTypeList={typewiseMeterTypeList}
        typewiseConsumerTypeList={typewiseConsumerTypeList}
        typewiseDivisionTypeList={typewiseDivisionTypeList}
      />
    </div>
  );
};

export default Consumer;
