import React, { useEffect, useRef, useState } from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import { useDispatch } from "react-redux";
import {
  editSupervisor,
  saveSupervisor
} from "./supervisorSlice";
import { OptionsMaster } from "./../../data/dropdown";

function SupervisorForm({dropdownList, id, editItemDetails, isEdit, showModal, setShowModal }) {
  const dispatch = useDispatch();
  const modalRef = useRef(null);

  useEffect(() => {
    return () => {
      setShowModal(false);
    };
  }, []);

  const closeModal = () => {
    const button = document.getElementById("closeEditModalBtnSupervisor");
    button.click();
  };

  const validationSchema = Yup.object({
    name: Yup.string().required("Name is required"),
    userid: Yup.string().required("User ID is required"),
    status: Yup.string().required("Status is required"),
    manager: Yup.string().required("Manager is required"),
    planner: Yup.string().required("Planner is required"),
    mobile: Yup.string().required("Mobile is required"),
    imei: Yup.string().required("IMEI is required"),
    address: Yup.string().required("Address is required"),
    email: Yup.string().required("Email is required"),
  });

  const onSubmit = async (values, { resetForm, setSubmitting }) => {
    try {
      if (isEdit) {
        dispatch(editSupervisor(values));
      } else {
        dispatch(saveSupervisor(values));
      }
      setSubmitting(false);
      resetForm();
      closeModal();
    } catch (rejectedValueOrSerializedError) {}
  };

  return (
    <div
      className="modal modal-blur fade"
      id={id}
      tabIndex="-1"
      role="dialog"
      aria-hidden="true"
      ref={modalRef}
      data-bs-backdrop="static"
    >
      <div
        className="modal-dialog modal-lg modal-dialog-centered"
        role="document"
      >
        <div className="modal-content">
          {showModal && (
            <Formik
              initialValues={{
                id: editItemDetails?.id || "",
                name: editItemDetails?.name || "",
                userid: editItemDetails?.userid || "",
                status: editItemDetails?.status || "",
                manager: editItemDetails?.manager || "",
                planner: editItemDetails?.planner || "",
                mobile: editItemDetails?.mobile || "",
                imei: editItemDetails?.imei || "",
                address: editItemDetails?.address || "",
                email: editItemDetails?.email || "",
              }}
              validationSchema={validationSchema}
              onSubmit={onSubmit}
            >
              {({ isSubmitting, resetForm, setFieldValue }) => (
                <Form>
                  <div className="modal-header">
                    <h5 className="modal-title">Supervisor Form</h5>
                    <button
                      onClick={() => {
                        setShowModal(false);
                        resetForm();
                      }}
                      type="button"
                      className="btn-close"
                      data-bs-dismiss="modal"
                      aria-label="Close"
                    ></button>
                  </div>
                  <div className="modal-body">
                  <div className="row">
                      <div className="col-lg-6">
                    <div className="mb-3">
                      <label htmlFor="name">Name</label>
                      <Field
                        type="text"
                        className="form-control"
                        id="name"
                        name="name"
                      />
                      <ErrorMessage name="name" component="div"   className="error"/>
                    </div>
                    </div>
                    <div className="col-lg-6">
                    <div className="mb-3">
                      <label htmlFor="userid">User ID</label>
                      <Field
                        type="text"
                        className="form-control"
                        id="userid"
                        name="userid"
                      />
                      <ErrorMessage name="userid" component="div"   className="error"/>
                    </div>
                    </div>
                    <div className="col-lg-6">
                    <div className="mb-3">
                      <label htmlFor="status">Status</label>
                      <Field
                        as="select"
                        className="form-control"
                        id="status"
                        name="status"
                      >
                        <option value="">Select Status</option>
                        {dropdownList[OptionsMaster.status].map((item) => {
                              return (
                                <option value={item.id} key={item.id}>
                                  {item.name}
                                </option>
                              );
                            })}

                      </Field>
                      <ErrorMessage name="status" component="div"   className="error"/>
                    </div>
                    </div>
                    <div className="col-lg-6">
                    <div className="mb-3">
                      <label htmlFor="manager">Manager</label>
                      <Field
                        type="text"
                        className="form-control"
                        id="manager"
                        name="manager"
                      />
                      <ErrorMessage name="manager" component="div"   className="error"/>
                    </div>
                    </div>
                    <div className="col-lg-6">
                    <div className="mb-3">
                      <label htmlFor="planner">Planner</label>
                      <Field
                        type="text"
                        className="form-control"
                        id="planner"
                        name="planner"
                      />
                      <ErrorMessage name="planner" component="div"   className="error"/>
                    </div>
                    </div>
                    <div className="col-lg-6">
                    <div className="mb-3">
                      <label htmlFor="mobile">Mobile</label>
                      <Field
                        type="text"
                        className="form-control"
                        id="mobile"
                        name="mobile"
                      />
                      <ErrorMessage name="mobile" component="div"   className="error"/>
                    </div>
                    </div>
                    <div className="col-lg-6">
                    <div className="mb-3">
                      <label htmlFor="imei">IMEI</label>
                      <Field
                        type="text"
                        className="form-control"
                        id="imei"
                        name="imei"
                      />
                      <ErrorMessage name="imei" component="div"   className="error"/>
                    </div>
                    </div>
                    <div className="col-lg-6">
                    <div className="mb-3">
                      <label htmlFor="email">Email</label>
                      <Field
                        type="email"
                        className="form-control"
                        id="email"
                        name="email"
                      />
                      <ErrorMessage name="email" component="div"   className="error"/>
                    </div>
                    </div>
                    <div className="col-lg-8">
                    <div className="mb-3">
                      <label htmlFor="address">Address</label>
                      <Field
                        as="textarea"
                        className="form-control"
                        id="address"
                        name="address"
                      />
                      <ErrorMessage name="address" component="div"   className="error"/>
                    </div>
                    </div>
                    
                    </div>
                  </div>
                  <div className="modal-footer">
                    <button
                      type="button"
                      id="closeEditModalBtnSupervisor"
                     
                      onClick={() => {
                        setShowModal(false);
                        resetForm();
                      }}
                      className="btn ouline-secondary"
                      data-bs-dismiss="modal"
                    >
                      Cancel
                    </button>
                    <button
                      type="submit"
                      disabled={isSubmitting}
                      className="btn btn-primary ms-auto text-white"
                    >
                      {isEdit ? "Update Supervisor" : "Create New Supervisor"}
                    </button>
                  </div>
                </Form>
              )}
            </Formik>
          )}
        </div>
      </div>
    </div>
  );
}

export default SupervisorForm;