import { useEffect, useState } from "react";
import { navigationLinks } from "../../data/data";
import "./Sidebar.css";
import { personsImgs } from "../../utils/images";
import {  NavLink } from "react-router-dom";
import { useSelector } from "react-redux";
import { getMatchingItemsById } from "../../utils/common";
import { OptionsMaster } from "../../data/dropdown";
import { iconsImgs } from "../../utils/images";


const Sidebar = () => {
  const [navigation,setNavigation]=useState([]);
  const {
    originalData,
    loading,
    error,
  } = useSelector((state) => state.globalSetting);
   useEffect(() => {
  if(originalData.length>0){

    let userPathList=getMatchingItemsById(originalData,OptionsMaster.usertype)
    navigationLinks.map((item)=>{
      if(item.id==4){
      item.children=userPathList
    }
    })
    console.log(navigationLinks)
    setNavigation(navigationLinks)
  }


  return () => {
   
  }
}, [originalData])


  return (
    <aside className="navbar navbar-vertical navbar-expand-lg _navbar">
       
      <div className="container-fluid">
      
        <button
          className="navbar-toggler collapsed text-white"
          type="button"
          data-bs-toggle="collapse"
          data-bs-target="#sidebar-menu"
          aria-controls="sidebar-menu"
          aria-expanded="false"
          aria-label="Toggle navigation"
          id="__sidebar"
        >
          <span className="navbar-toggler-icon"></span>
        </button>
        <h1 className="navbar-brand navbar-brand-autodark">
          <a>
            <div className="user-info">
              <div className="info-img rounded-circle">
                <img
                  className="rounded-circle"
                  src={personsImgs.logo}
                  height={80}
                  alt="profile image"
                />
              </div>
            </div>
          </a>
        </h1>
        <div className="navbar-nav  flex-row d-lg-none">
          <div className="nav-item dropdown">
            <div className="dropdown-menu dropdown-menu-end dropdown-menu-arrow">
              <div className="dropdown-divider"></div>
              <a className="dropdown-item">Settings</a>
              <a className="dropdown-item">Logout</a>
            </div>
          </div>
        </div>
       
        <div className="navbar-collapse collapse px-2" id="sidebar-menu">
          <ul className="navbar-nav pt-lg-3">
            {navigation.length}
            { !loading && navigation.map((navigationLink) => {
              return (
                <>
                {(navigationLink.children && navigationLink.children.length>0)?
                  <li className="nav-item dropdown my-1"  key={navigationLink.id} 
                 
                  >
                  <NavLink
                    className={({ isActive }) =>
                      `_nav-link dropdown-toggle `
                    }
                    // to={navigationLink.to}
                   
                    data-bs-toggle="dropdown"
                    data-bs-auto-close="false"
                    role="button"
                    aria-expanded="false"
                  >
                    <span className="nav-link-icon d-md-none d-lg-inline-block">
                    <img
                          src={navigationLink.image}
                          className="nav-link-icon"
                          alt={navigationLink.title}
                        />
                    </span>
                    <span className="nav-link-title">{navigationLink.title}</span>
                  </NavLink>
                  <div className="dropdown-menu"   style={{backgroundColor:"#33469c"}}>
                    {navigationLink.children.map((child)=>{
                      return (<NavLink  
                         onClick={(e)=>{
                    e.stopPropagation()
                    document.getElementById("__sidebar").click()
                  }}
                               key={child.id}
                               to={`${navigationLink.to}/${child.to}`}
                              
                               className="dropdown-item _nav-link my-1"
                             >
                              <span className="nav-link-icon">   
                              <img src={child.image?child.image:iconsImgs.userplus} className="nav-dropdown-link-icon" alt={child.title} />    {child.title}</span>
                       
                             </NavLink>)
                      }
                    )}
                   
                  </div>
                </li>
                  
:
      <li className="nav-item my-1" key={navigationLink.id}
      
      onClick={(e)=>{
        e.stopPropagation()
        document.getElementById("__sidebar").click()
      }}
      >
                    <NavLink
                      to={navigationLink.to}
                      className={({ isActive }) =>
                        `_nav-link ${isActive ? "active" : null}`
                      }
                    >
                      <span className="nav-link-icon d-md-none d-lg-inline-block">
                        <img
                          src={navigationLink.image}
                          className="nav-link-icon"
                          alt={navigationLink.title}
                        />
                      </span>
                      <span className="nav-link-title">
                        {navigationLink.title}
                      </span>
                    </NavLink>
                  </li>
                
            }
                </>
              );
            })}
          </ul>
        </div>
      </div>
    </aside>
  );
};

export default Sidebar;
