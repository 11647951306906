import React, { useEffect, useRef, useState } from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import { useDispatch } from "react-redux";
import { MDBIcon } from "mdb-react-ui-kit";
import { ShimmerTable } from "react-shimmer-effects";
import { editConsumer, saveConsumer } from "./consumerSlice";
import { OptionsMaster } from "../../data/dropdown";

function ConsumerForm({dropdownList, id, editItemDetails, isEdit, showModal, setShowModal, typewiseMeterTypeList, typewiseConsumerTypeList, typewiseDivisionTypeList }) {
  const dispatch = useDispatch();
  const modalRef = useRef(null);
  const closeModal = () => {
    const button = document.getElementById("closeEditModalBtnConsumer");
    button.click();
  };

  const validationSchema = Yup.object({
    // status: Yup.number().required("Status is required"),
    // type: Yup.number().required("Type is required"),
    // planner: Yup.number().required("Planner is required"),
    // circle: Yup.number().required("Circle is required"),
    // division: Yup.number().required("Division is required"),
    // town: Yup.number().required("Town is required"),
    // zone: Yup.number().required("Zone is required"),
    // feeder: Yup.number().required("Feeder is required"),
    // dtrlocation: Yup.string().required("DTR Location is required"),
    // dtrcapacity: Yup.string().required("DTR Capacity is required"),
    // dtrcode: Yup.string().required("DTR Code is required"),
    // ivrs: Yup.string().required("IVRS is required"),
    // name: Yup.string().required("Name is required"),
    // address: Yup.string().required("Address is required"),
    // mobile: Yup.string().required("Mobile is required"),
    // metertype: Yup.number().required("Meter Type is required"),
    // meterno: Yup.string().required("Meter No is required"),
    // metercategory: Yup.number().required("Meter Category is required"),
    // sactionload: Yup.string().required("Saction Load is required"),
    // meterlocation: Yup.string().required("Meter Location is required"),
    // servicecablecondition: Yup.string().required("Service Cable Condition is required"),
    // meterreading: Yup.string().required("Meter Reading is required"),
    // inbillfile: Yup.number().required("In Bill File is required"),
    // latitude: Yup.string().required("Latitude is required"),
    // longitude: Yup.string().required("Longitude is required"),
    // remarks: Yup.string().required("Remarks is required"),
  });

  const onSubmit = (values, { resetForm, setSubmitting }) => {
    console.log(values);
    if (isEdit) {
      dispatch(editConsumer(values));
    } else {
      dispatch(saveConsumer(values));
    }
    setSubmitting(false);
    resetForm();
    closeModal();
  };

  return (
    <div
      className="modal modal-blur fade"
      id={id}
      tabIndex="-1"
      role="dialog"
      aria-hidden="true"
      ref={modalRef}
      data-bs-backdrop="static"
    >
      <div className="modal-dialog modal-full-width modal-dialog-centered" role="document">
        <div className="modal-content consumermodal">
          {showModal && (
            <Formik
              initialValues={{
                id:editItemDetails?.id || "",
                status: editItemDetails?.status || "",
                type: editItemDetails?.type || "",
                planner: editItemDetails?.planner || "",
                circle: editItemDetails?.circle || "",
                division: editItemDetails?.division || "",
                town: editItemDetails?.town || "",
                zone: editItemDetails?.zone || "",
                feeder: editItemDetails?.feeder || "",
                dtrlocation: editItemDetails?.dtrlocation || "",
                dtrcapacity: editItemDetails?.dtrcapacity || "",
                dtrcode: editItemDetails?.dtrcode || "",
                ivrs: editItemDetails?.ivrs || "",
                name: editItemDetails?.name || "",
                address: editItemDetails?.address || "",
                mobile: editItemDetails?.mobile || "",
                metertype: editItemDetails?.metertype || "",
                meterno: editItemDetails?.meterno || "",
                metercategory: editItemDetails?.metercategory || "",
                sactionload: editItemDetails?.sactionload || "",
                meterlocation: editItemDetails?.meterlocation || "",
                servicecablecondition: editItemDetails?.servicecablecondition || "",
                meterreading: editItemDetails?.meterreading || "",
                inbillfile: editItemDetails?.inbillfile || "",
                latitude: editItemDetails?.latitude || "",
                longitude: editItemDetails?.longitude || "",
                remarks: editItemDetails?.remarks || "",
              }}
              validationSchema={validationSchema}
              onSubmit={onSubmit}
            >
              {({ isSubmitting, resetForm }) => (
                <Form>
                  <div className="modal-header">
                    <h5 className="modal-title">Consumer Form</h5>
                    <button
                      onClick={() => {
                        setShowModal(false);
                        resetForm();
                      }}
                      type="button"
                      className="btn-close"
                      data-bs-dismiss="modal"
                      aria-label="Close"
                    ></button>
                  </div>
                  <div className="modal-body">
                    <div className="row">
                      <div className="col-lg-3">
                        <div className="mb-3">
                          <label htmlFor="status">Status</label>
                          <Field
                            as="select"
                            id="status"
                            name="status"
                            className="form-control"
                          >
                            <option value="">Select Status</option>
                            {dropdownList[OptionsMaster.status].map((item) => {
                              return (
                                <option value={item.id} key={item.id}>
                                  {item.name}
                                </option>
                              );
                            })}
                          </Field>
                          <ErrorMessage
                            name="status"
                            component="div"
                            className="error"
                          />
                        </div>
                      </div>

                      <div className="col-lg-3">
                      <div className="mb-3">
                          <label htmlFor="status">Consumer Type</label>
                            <Field
                              as="select"
                              type="text"
                              id="type"
                              name="type"
                              className="form-control"
                            >
                              <option value="">Select Consumer Type</option>
                              {typewiseConsumerTypeList.map(
                                (item) => {
                                  return (
                                    <option value={item.id} key={item.id}>
                                      {item.name}
                                    </option>
                                  );
                                }
                              )}
                            </Field>
                            <ErrorMessage
                              name="type"
                              component="div"
                              className="error"
                            />
                          </div>
                        
                      </div>
                  
                      <div className="col-lg-3">
                        <div className="mb-3">
                          <label htmlFor="planner">Planner</label>
                          <Field
                            type="text"
                            id="planner"
                            name="planner"
                            className="form-control"
                          />
                          <ErrorMessage
                            name="planner"
                            component="div"
                            className="error"
                          />
                        </div>
                      </div>

                      <div className="col-lg-3">
                        <div className="mb-3">
                          <label htmlFor="circle">Circle</label>
                          <Field
                            type="text"
                            id="circle"
                            name="circle"
                            className="form-control"
                          />
                          <ErrorMessage
                            name="circle"
                            component="div"
                            className="error"
                          />
                        </div>
                      </div>
                    
                      <div className="col-lg-3">
                        <div className="mb-3">
                          <label htmlFor="status">Division</label>
                            <Field
                              as="select"
                              type="text"
                              id="division"
                              name="division"
                              className="form-control"
                            >
                              <option value="">Select Division Type</option>
                              {typewiseDivisionTypeList.map(
                                (item) => {
                                  return (
                                    <option value={item.id} key={item.id}>
                                      {item.name}
                                    </option>
                                  );
                                }
                              )}
                            </Field>
                            <ErrorMessage
                              name="division"
                              component="div"
                              className="error"
                            />
                        </div>
                      
                      </div>

                      <div className="col-lg-3">
                        <div className="mb-3">
                          <label htmlFor="town">Town</label>
                          <Field
                            type="text"
                            id="town"
                            name="town"
                            className="form-control"
                          />
                          <ErrorMessage
                            name="town"
                            component="div"
                            className="error"
                          />
                        </div>
                      </div>
                    
                      <div className="col-lg-3">
                        <div className="mb-3">
                          <label htmlFor="zone">Zone</label>
                          <Field
                            type="text"
                            id="zone"
                            name="zone"
                            className="form-control"
                          />
                          <ErrorMessage
                            name="zone"
                            component="div"
                            className="error"
                          />
                        </div>
                      </div>

                      <div className="col-lg-3">
                        <div className="mb-3">
                          <label htmlFor="feeder">Feeder</label>
                          <Field
                            type="text"
                            id="feeder"
                            name="feeder"
                            className="form-control"
                          />
                          <ErrorMessage
                            name="feeder"
                            component="div"
                            className="error"
                          />
                        </div>
                      </div>
                   
                      <div className="col-lg-3">
                        <div className="mb-3">
                          <label htmlFor="dtrlocation">DTR Location</label>
                          <Field
                            type="text"
                            id="dtrlocation"
                            name="dtrlocation"
                            className="form-control"
                          />
                          <ErrorMessage
                            name="dtrlocation"
                            component="div"
                            className="error"
                          />
                        </div>
                      </div>

                      <div className="col-lg-3">
                        <div className="mb-3">
                          <label htmlFor="dtrcapacity">DTR Capacity</label>
                          <Field
                            type="text"
                            id="dtrcapacity"
                            name="dtrcapacity"
                            className="form-control"
                          />
                          <ErrorMessage
                            name="dtrcapacity"
                            component="div"
                            className="error"
                          />
                        </div>
                      </div>
                  
                      <div className="col-lg-3">
                        <div className="mb-3">
                          <label htmlFor="dtrcode">DTR Code</label>
                          <Field
                            type="text"
                            id="dtrcode"
                            name="dtrcode"
                            className="form-control"
                          />
                          <ErrorMessage
                            name="dtrcode"
                            component="div"
                            className="error"
                          />
                        </div>
                      </div>

                      <div className="col-lg-3">
                        <div className="mb-3">
                          <label htmlFor="ivrs">IVRS</label>
                          <Field
                            type="text"
                            id="ivrs"
                            name="ivrs"
                            className="form-control"
                          />
                          <ErrorMessage
                            name="ivrs"
                            component="div"
                            className="error"
                          />
                        </div>
                      </div>
                   
                      <div className="col-lg-3">
                        <div className="mb-3">
                          <label htmlFor="name">Name</label>
                          <Field
                            type="text"
                            id="name"
                            name="name"
                            className="form-control"
                          />
                          <ErrorMessage
                            name="name"
                            component="div"
                            className="error"
                          />
                        </div>
                      </div>

                      <div className="col-lg-9">
                        <div className="mb-3">
                          <label htmlFor="address">Address</label>
                          <Field
                            type="text"
                            id="address"
                            name="address"
                            className="form-control"
                          />
                          <ErrorMessage
                            name="address"
                            component="div"
                            className="error"
                          />
                        </div>
                      </div>
                    
                      <div className="col-lg-3">
                        <div className="mb-3">
                          <label htmlFor="mobile">Mobile</label>
                          <Field
                            type="text"
                            id="mobile"
                            name="mobile"
                            className="form-control"
                          />
                          <ErrorMessage
                            name="mobile"
                            component="div"
                            className="error"
                          />
                        </div>
                      </div>

                      <div className="col-lg-3">
                        <div className="mb-3">
                          <label htmlFor="status">Meter Type</label>
                            <Field
                              as="select"
                              type="number"
                              id="metertype"
                              name="metertype"
                              className="form-control"
                            >
                              <option value="">Select Meter Type</option>
                              {typewiseMeterTypeList.map(
                                (item) => {
                                  return (
                                    <option value={item.id} key={item.id}>
                                      {item.name}
                                    </option>
                                  );
                                }
                              )}
                            </Field>
                            <ErrorMessage
                              name="metertype"
                              component="div"
                              className="error"
                            />
                          </div>
                       
                      </div>
               
                      <div className="col-lg-3">
                        <div className="mb-3">
                          <label htmlFor="meterno">Meter No</label>
                          <Field
                            type="text"
                            id="meterno"
                            name="meterno"
                            className="form-control"
                          />
                          <ErrorMessage
                            name="meterno"
                            component="div"
                            className="error"
                          />
                        </div>
                      </div>

                      <div className="col-lg-3">
                        <div className="mb-3">
                          <label htmlFor="metercategory">Meter Category</label>
                          <Field
                            type="number"
                            id="metercategory"
                            name="metercategory"
                            className="form-control"
                          />
                          <ErrorMessage
                            name="metercategory"
                            component="div"
                            className="error"
                          />
                        </div>
                      </div>
              
                      <div className="col-lg-3">
                        <div className="mb-3">
                          <label htmlFor="sactionload">Saction Load</label>
                          <Field
                            type="text"
                            id="sactionload"
                            name="sactionload"
                            className="form-control"
                          />
                          <ErrorMessage
                            name="sactionload"
                            component="div"
                            className="error"
                          />
                        </div>
                      </div>

                      <div className="col-lg-3">
                        <div className="mb-3">
                          <label htmlFor="meterlocation">Meter Location</label>
                          <Field
                            type="text"
                            id="meterlocation"
                            name="meterlocation"
                            className="form-control"
                          />
                          <ErrorMessage
                            name="meterlocation"
                            component="div"
                            className="error"
                          />
                        </div>
                      </div>
                  
                      <div className="col-lg-3">
                        <div className="mb-3">
                          <label htmlFor="servicecablecondition">Service Cable Condition</label>
                          <Field
                            type="text"
                            id="servicecablecondition"
                            name="servicecablecondition"
                            className="form-control"
                          />
                          <ErrorMessage
                            name="servicecablecondition"
                            component="div"
                            className="error"
                          />
                        </div>
                      </div>

                      <div className="col-lg-3">
                        <div className="mb-3">
                          <label htmlFor="meterreading">Meter Reading</label>
                          <Field
                            type="text"
                            id="meterreading"
                            name="meterreading"
                            className="form-control"
                          />
                          <ErrorMessage
                            name="meterreading"
                            component="div"
                            className="error"
                          />
                        </div>
                      </div>
                  
                      <div className="col-lg-3">
                        <div className="mb-3">
                          <label htmlFor="inbillfile">In Bill File</label>
                          <Field
                            type="number"
                            id="inbillfile"
                            name="inbillfile"
                            className="form-control"
                          />
                          <ErrorMessage
                            name="inbillfile"
                            component="div"
                            className="error"
                          />
                        </div>
                      </div>

                      <div className="col-lg-3">
                        <div className="mb-3">
                          <label htmlFor="latitude">Latitude</label>
                          <Field
                            type="text"
                            id="latitude"
                            name="latitude"
                            className="form-control"
                          />
                          <ErrorMessage
                            name="latitude"
                            component="div"
                            className="error"
                          />
                        </div>
                      </div>
                   
                      <div className="col-lg-3">
                        <div className="mb-3">
                          <label htmlFor="longitude">Longitude</label>
                          <Field
                            type="text"
                            id="longitude"
                            name="longitude"
                            className="form-control"
                          />
                          <ErrorMessage
                            name="longitude"
                            component="div"
                            className="error"
                          />
                        </div>
                      </div>

                      <div className="col-lg-3">
                        <div className="mb-3">
                          <label htmlFor="remarks">Remarks</label>
                          <Field
                            type="text"
                            id="remarks"
                            name="remarks"
                            className="form-control"
                          />
                          <ErrorMessage
                            name="remarks"
                            component="div"
                            className="error"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="modal-footer">
                    <a
                      className="btn ouline-secondary"
                      data-bs-dismiss="modal"
                      id="closeEditModalBtnConsumer"
                      onClick={() => {
                        setShowModal(false);
                        resetForm();
                      }}
                    >
                      Cancel
                    </a>
                    <button
                      disabled={isSubmitting}
                      type="submit"
                      className="btn btn-primary ms-auto text-white"
                    >
                      {isEdit ? "Update Consumer" : "Create New Consumer"}
                    </button>
                  </div>
                </Form>
              )}
            </Formik>
          )}
        </div>
      </div>
    </div>
  );
}

export default ConsumerForm;
