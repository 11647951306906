import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { deleteOEMDetailsById, editOEMDetails, fetchOEMDetails, saveOEMDetails } from "./OEM.api";
import { saveCover } from "../users/user.api";

const initialState={
    loading:false,
    error:"",
    OEM: [],
    isDeletedSuccessful:false,
    isSavedSuccessful:false,
    filterOEMListData:[],
    coverImagePath:"",
    coverImageSaved:false
   }
export const fetchOEM=createAsyncThunk(
     "OEM/fetchOEM",()=>fetchOEMDetails()
   );

export const saveOEM=createAsyncThunk(
    "OEM/saveOEM",(data)=>saveOEMDetails(data)
  );
  export const deleteOEM=createAsyncThunk(
    "OEM/deleteOEM",(id)=>deleteOEMDetailsById(id)
  )

  export const editOEM=createAsyncThunk(
    "OEM/editOEM",(payload)=>editOEMDetails(payload)
  )
  export const uploadCoverImg=createAsyncThunk(
    "OEM/uploadCover",(path)=>saveCover(path)
  )
  


const OEMSlice=createSlice({
name:'OEM',
initialState,
reducers:{
  setOEMFilter:(state,action)=>{
      state.OEM= action.payload;
  },

},
extraReducers: (builder) => {
  builder
    .addCase(fetchOEM.pending,(state)=>{
      state.loading=true;
      state.error="";
    })
    .addCase(fetchOEM.fulfilled,(state,action)=> {
        state.loading = false;
        state.isDeletedSuccessful=false;
        state.isSavedSuccessful=false;
        if(Array.isArray(action.payload?.data.data)){
          state.OEM = action.payload?.data.data;
        }
        else{
          state.OEM = []
        }
       
        state.filterOEMListData=action.payload?.data.data;
    })
    .addCase(fetchOEM.rejected, (state, action) =>{
      state.loading = false;
      state.error = action.error.message || "Something went wrong!";
    })  
//Save OEM
   .addCase(saveOEM.pending,(state)=>{
        state.loading=true;
        state.error="";
      })
      .addCase(saveOEM.fulfilled,(state,action)=> {
          state.loading = false;
          state.isSavedSuccessful=true;
         
      })
      .addCase(saveOEM.rejected, (state, action) =>{
        state.loading = false;
        state.error = action.error.message || "Something went wrong!";
      }) 
//Delete OEM
      .addCase(deleteOEM.pending,(state)=>{
        state.loading=true;
        state.error="";
      })
      .addCase(deleteOEM.fulfilled,(state,action)=> {
          state.loading = false;
          state.isDeletedSuccessful=true;
         
      })
      .addCase(deleteOEM.rejected, (state, action) =>{
        state.loading = false;
        state.error = action.error.message || "Something went wrong!";
      }) 
      //Edit OEM
      .addCase(editOEM.pending,(state)=>{
        state.loading=true;
        state.error="";
      })
      .addCase(editOEM.fulfilled,(state,action)=> {
          state.loading = false;
          state.isSavedSuccessful=true;
         
      })
      .addCase(editOEM.rejected, (state, action) =>{
        state.loading = false;
        state.error = action.error.message || "Something went wrong!";
      }) 
      // save Cover image

      .addCase(uploadCoverImg.pending,(state)=>{
        state.coverImageSaved=false;
        state.error="";
      })
      .addCase(uploadCoverImg.fulfilled,(state,action)=> {
      
          state.coverImageSaved = true;
          state.coverImagePath=action.payload.data.data;
         
      })
      .addCase(uploadCoverImg.rejected, (state, action) =>{
        state.coverImageSaved = false;
        state.error = action.error.message || "Something went wrong!";
      }) 
}
})
export default OEMSlice.reducer;
export const {setOEMFilter } = OEMSlice.actions
