import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { deleteConsumerDetailsById, editConsumersDetails, fetchConsumersDetails, saveConsumersDetails } from "./consumer.api";

const initialState={
    loading:false,
    error:"",
    consumer: [],
    isDeletedSuccessful:false,
    isSavedSuccessful:false,
    isUpdatedSuccessful:false,

    filterConsumerListData:[]
    

   }
export const fetchConsumer=createAsyncThunk(
     "consumer/fetchConsumer",(data)=>fetchConsumersDetails(data)
   );

export const saveConsumer=createAsyncThunk(
    "consumer/saveConsumer",(data)=>saveConsumersDetails(data)
  );
  export const deleteConsumer=createAsyncThunk(
    "consumer/deleteConsumer",(id)=>deleteConsumerDetailsById(id)
  )

  export const editConsumer=createAsyncThunk(
    "consumer/editConsumer",(id)=>editConsumersDetails(id)
  )


const consumerSlice=createSlice({
name:'consumer',
initialState,
reducers:{
  setConsumerFilter:(state,action)=>{
      state.consumer= action.payload;
  },

},
extraReducers: (builder) => {
  builder
    .addCase(fetchConsumer.pending,(state)=>{
      state.loading=true;
      state.error="";
    })
    .addCase(fetchConsumer.fulfilled,(state,action)=> {
      state.error = "";
        state.loading = false;
        state.isDeletedSuccessful=false;
        state.isSavedSuccessful=false;

   
        if(Array.isArray(action.payload?.data.data)){
            state.consumer = action.payload?.data.data;
          }
          else{
            state.consumer = []
          }
         
        state.filterConsumerListData=action.payload?.data.data;
    })
    .addCase(fetchConsumer.rejected, (state, action) =>{
      state.loading = false;
      state.error = action.error.message || "Something went wrong!";
    })  
//Save consumer
   .addCase(saveConsumer.pending,(state)=>{
        state.loading=true;
        state.error="";
      })
      .addCase(saveConsumer.fulfilled,(state,action)=> {
          state.loading = false;
          state.isSavedSuccessful=true;
         
      })
      .addCase(saveConsumer.rejected, (state, action) =>{
        state.loading = false;
        state.error = action.error.message || "Something went wrong!";
      }) 
//Delete consumer
      .addCase(deleteConsumer.pending,(state)=>{
        state.loading=true;
        state.error="";
      })
      .addCase(deleteConsumer.fulfilled,(state,action)=> {
          state.loading = false;
          state.isDeletedSuccessful=true;
         
      })
      .addCase(deleteConsumer.rejected, (state, action) =>{
        state.loading = false;
        state.error = action.error.message || "Something went wrong!";
      }) 
      //Edit consumer
      .addCase(editConsumer.pending,(state)=>{
        state.loading=true;
        state.error="";
      })
      .addCase(editConsumer.fulfilled,(state,action)=> {
          state.loading = false;
          state.isSavedSuccessful=true;
          state.isUpdatedSuccessful=true;
         
      })
      .addCase(editConsumer.rejected, (state, action) =>{
        state.loading = false;
        state.error = action.error.message || "Something went wrong!";
      }) 
}
})
export default consumerSlice.reducer;
export const {setConsumerFilter } = consumerSlice.actions
