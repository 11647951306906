import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { deleteDetailsById, editWarehouseDetails, fetchWarehouseDetails, saveWarehouseDetails } from "./warehouse.api";


const initialState={
    loading:false,
    error:"",
    warehouse: [],
    isDeletedSuccessful:false,
    isSavedSuccessful:false,
    filterWarehouseListData:[]
    

   }
export const fetchwarehouses=createAsyncThunk(
     "warehouse/fetchwarehouses",()=>fetchWarehouseDetails()
   );

export const savewarehouse=createAsyncThunk(
    "warehouse/savewarehouses",(data)=>saveWarehouseDetails(data)
  );
  export const deletewarehouse=createAsyncThunk(
    "warehouse/deletewarehouses",(id)=>deleteDetailsById(id)
  )

  export const editwarehouse=createAsyncThunk(
    "warehouse/editwarehouses",(id)=>editWarehouseDetails(id)
  )


const warehouseSlice=createSlice({
name:'warehouse',
initialState,
reducers:{
  setWarehouseFilter:(state,action)=>{
      state.warehouse= action.payload;
  },

},
extraReducers: (builder) => {
  builder
    .addCase(fetchwarehouses.pending,(state)=>{
      state.loading=true;
      state.error="";
    })
    .addCase(fetchwarehouses.fulfilled,(state,action)=> {
      state.error = "";
        state.loading = false;
        state.isDeletedSuccessful=false;
        state.isSavedSuccessful=false;
        state.warehouse = action.payload?.data.data;
        state.filterWarehouseListData=action.payload?.data.data;
    })
    .addCase(fetchwarehouses.rejected, (state, action) =>{
      state.loading = false;
      state.error = action.error.message || "Something went wrong!";
    })  
//Save warehouse
   .addCase(savewarehouse.pending,(state)=>{
        state.loading=true;
        state.error="";
      })
      .addCase(savewarehouse.fulfilled,(state,action)=> {
          state.loading = false;
          state.isSavedSuccessful=true;
         
      })
      .addCase(savewarehouse.rejected, (state, action) =>{
        state.loading = false;
        state.error = action.error.message || "Something went wrong!";
      }) 
//Delete warehouse
      .addCase(deletewarehouse.pending,(state)=>{
        state.loading=true;
        state.error="";
      })
      .addCase(deletewarehouse.fulfilled,(state,action)=> {
          state.loading = false;
          state.isDeletedSuccessful=true;
         
      })
      .addCase(deletewarehouse.rejected, (state, action) =>{
        state.loading = false;
        state.error = action.error.message || "Something went wrong!";
      }) 
      //Edit warehouse
      .addCase(editwarehouse.pending,(state)=>{
        state.loading=true;
        state.error="";
      })
      .addCase(editwarehouse.fulfilled,(state,action)=> {
          state.loading = false;
          state.isSavedSuccessful=true;
         
      })
      .addCase(editwarehouse.rejected, (state, action) =>{
        state.loading = false;
        state.error = action.error.message || "Something went wrong!";
      }) 
}
})
export default warehouseSlice.reducer;
export const {setWarehouseFilter } = warehouseSlice.actions
