import React, { useState } from "react";
import "./SignUp.css";
import { personsImgs } from "../../../utils/images";
import { Link } from "react-router-dom";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import { Bounce, toast } from "react-toastify";

function SignUp() {
  const initialValues = {
    name: "",
    email: "",
    password: "",
  };

  const validationSchema = Yup.object({
    name: Yup.string().required("Name is required"),
    email: Yup.string()
      .email("Invalid email address")
      .required("Email is required"),
    password: Yup.string()
      .matches(/[A-Z]/, "Password must contain at least one capital letter")
      .matches(/[a-z]/, "Password must contain at least one small letter")
      .matches(
        /[!@#$%^&*(),.?":{}|<>]/,
        "Password must contain at least one special character"
      )
      .min(8, "Password must be at least 8 characters")
      .required("Password is required"),
  });
  const onSubmit = (values, { resetForm, setSubmitting }) => {
    toast.success('🦄 Wow so easy!', {
      position: "top-center",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      theme: "light",
      transition: Bounce,
      });
    console.log("Submitted:", values);
    setSubmitting(false);
    resetForm();
  };

  const [showPassword, setShowPassword] = useState(false);

  const togglePasswordVisibility = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setShowPassword(!showPassword);
  };
  
  return (
    <div>
      <div className=" d-flex flex-column">
        <div className="page page-center">
          <div className="container container-tight ">
            <div className="text-center my-2">
              <a href="/" className="navbar-brand navbar-brand-autodark m-3">
                <img
                  src={personsImgs.logo}
                  height="106"
                  alt=""
                  style={{ borderRadius: "50%" }}
                />
              </a>
            </div>
            <Formik
              initialValues={initialValues}
              validationSchema={validationSchema}
              onSubmit={onSubmit}
            >
              {({ isSubmitting }) => (
                <Form className="card card-md" noValidate>
                  <div className="card-body">
                    <h2 className="card-title text-center mb-4">
                      Create new account
                    </h2>
                    <div className="mb-3">
                      <label className="form-label" htmlFor="name">
                        Name
                      </label>
                      <Field
                        type="text"
                        id="name"
                        name="name"
                        className="form-control"
                        placeholder="Enter name"
                      />
                      <ErrorMessage
                        name="name"
                        component="div"
                        className="error"
                      />
                    </div>
                    <div className="mb-3">
                      <label className="form-label" htmlFor="email">
                        Email address
                      </label>
                      <Field
                        type="email"
                        id="email"
                        name="email"
                        className="form-control"
                        placeholder="Enter email"
                      />
                      <ErrorMessage
                        name="email"
                        component="div"
                        className="error"
                      />
                    </div>
                    <div className="mb-3">
                      <label className="form-label">Password</label>
                      <div className="input-group input-group-flat">
                        <Field
                        type={showPassword ? 'text' : 'password'}
                          id="password"
                          name="password"
                          className="form-control"
                          placeholder="Enter password"
                          autoComplete="on"
                        />
                        <span className="input-group-text">
                          <a
                         onClick={(e)=>{togglePasswordVisibility(e)}}
                            className="link-secondary"
                            title="Show password"
                            data-bs-toggle="tooltip"
                          >
                             {!showPassword ? (
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
            <circle cx="12" cy="12" r="4"/>
            <path d="M1 12c2.7 4.2 7.8 7.2 11 7.2s8.3-3 11-7.2"/>
            <path d="M1 12c2.7-4.2 7.8-7.2 11-7.2s8.3 3 11 7.2"/>
          </svg>
        ) : (
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
            <path d="M12 15a3 3 0 0 1-3-3 3 3 0 0 1 6 0 3 3 0 0 1-3 3z"/>
            <path d="M19.84 16.02a10 10 0 0 1-14.46 0"/>
            <line x1="1" y1="1" x2="23" y2="23"/>
          </svg>
        )}
                          </a>
                        </span>
                      </div>
                      <ErrorMessage
                        name="password"
                        component="div"
                        className="error"
                      />
                    </div>
                    <div className="mb-3">
                      <label className="form-check">
                        <input type="checkbox" className="form-check-input" />
                        <span className="form-check-label">
                          Agree the{" "}
                          <a href="./terms-of-service.html" tabIndex="-1">
                            terms and policy
                          </a>
                          .
                        </span>
                      </label>
                    </div>
                    <div className="form-footer">
                      <button
                        type="submit"
                        disabled={isSubmitting}
                        className="btn btn-primary w-100"
                      >
                        Create new account
                      </button>
                    </div>
                  </div>
                </Form>
              )}
            </Formik>
            <div className="text-center text-muted mt-3">
              Already have account?{" "}
              <Link className=" mt-3" to="../sign-in" tabIndex="-1">
                Sign in
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default SignUp;
