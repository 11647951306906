import React, { useEffect, useRef, useState } from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import { useDispatch } from "react-redux";
import { MDBIcon } from "mdb-react-ui-kit";
import { ShimmerTable } from "react-shimmer-effects";
import { editMeter, saveMeter } from "./meterSlice";
import { OptionsMaster } from "../../data/dropdown";

function MeterForm({ dropdownList,id, editItemDetails, isEdit, showModal, setShowModal }) {
 const dispatch = useDispatch();
  const modalRef = useRef(null);

  const closeModal = () => {
    const button = document.getElementById("closeEditModalBtnMeter");
    button.click();
  };

  const validationSchema = Yup.object({
    status: Yup.number().required("Status is required"),
    stage: Yup.string().required("Stage is required"),
    oem: Yup.string().required("OEM is required"),
    number: Yup.string().required("Number is required"),
    capacity: Yup.string().required("Capacity is required"),
  });

  const onSubmit = (values, { resetForm, setSubmitting }) => {
    console.log(values);
    if (isEdit) {
      dispatch(editMeter(values));
    } else {
      dispatch(saveMeter(values));
    }
    setSubmitting(false);
    resetForm();
    closeModal();
  };

  return (
    <div
      className="modal modal-blur fade"
      id={id}
      tabIndex="-1"
      role="dialog"
      aria-hidden="true"
      ref={modalRef}
      data-bs-backdrop="static"
    >
      <div className="modal-dialog modal-lg modal-dialog-centered" role="document">
        <div className="modal-content">
          {showModal && (
            <Formik
              initialValues={{
                id: editItemDetails?.id,
                status: editItemDetails?.status,
                stage: editItemDetails?.stage,
                oem: editItemDetails?.oem,
                number: editItemDetails?.number,
                capacity: editItemDetails?.capacity,
                nicno: editItemDetails?.nicno,
                seal1: editItemDetails?.seal1,
                seal2: editItemDetails?.seal2,
                seal3: editItemDetails?.seal3,
                seal4: editItemDetails?.seal4,
                seal5: editItemDetails?.seal5,
                seal6: editItemDetails?.seal6,
                seal7: editItemDetails?.seal7,
              }}
              validationSchema={validationSchema}
              onSubmit={onSubmit}
            >
              {({ isSubmitting, resetForm }) => (
                <Form>
                  <div className="modal-header">
                    <h5 className="modal-title">Meter Form</h5>
                    <button
                      onClick={() => {
                        setShowModal(false);
                        resetForm();
                      }}
                      type="button"
                      className="btn-close"
                      data-bs-dismiss="modal"
                      aria-label="Close"
                    ></button>
                  </div>
                  <div className="modal-body">
                    <div className="row">
                      <div className="col-lg-6">
                        <div className="mb-3">
                          <label htmlFor="stage">Stage</label>
                          <Field
                            type="text"
                            id="stage"
                            name="stage"
                            className="form-control"
                          />
                          <ErrorMessage
                            name="stage"
                            component="div"
                            className="error"
                          />
                        </div>
                      </div>

                      <div className="col-lg-6">
                        <div className="mb-3">
                          <label htmlFor="nicno">NIC No.</label>
                          <Field
                            type="text"
                            id="nicno"
                            name="nicno"
                            className="form-control"
                          />
                          <ErrorMessage
                            name="nicno"
                            component="div"
                            className="error"
                          />
                        </div>
                      </div>

                      <div className="col-lg-6">
                        <div className="mb-3">
                          <label htmlFor="status">Status</label>
                          <Field
                            as="select"
                            id="status"
                            name="status"
                            className="form-control"
                          >
                            <option value="">Select Status</option>
                            {dropdownList[OptionsMaster.status].map((item) => {
                              return (
                                <option value={item.id} key={item.id}>
                                  {item.name}
                                </option>
                              );
                            })}
                          </Field>

                          <ErrorMessage
                            name="status"
                            component="div"
                            className="error"
                          />
                        </div>
                      </div>
                      <div className="col-lg-6">
                      <div className="mb-3">
                      <label htmlFor="oem">OEM</label>
                      <Field
                        type="text"
                        id="oem"
                        name="oem"
                        className="form-control"
                      />
                      <ErrorMessage
                        name="oem"
                        component="div"
                        className="error"
                      />
                    </div>
                        </div>
                        <div className="col-lg-6">

                        <div className="mb-3">
                      <label htmlFor="number">Number</label>
                      <Field
                        type="text"
                        id="number"
                        name="number"
                        className="form-control"
                      />
                      <ErrorMessage
                        name="number"
                        component="div"
                        className="error"
                      />
                    </div>
                        </div>
                        <div className="col-lg-6">
                        <div className="mb-3">
                      <label htmlFor="capacity">Capacity</label>
                      <Field
                        type="text"
                        id="capacity"
                        name="capacity"
                        className="form-control"
                      />
                      <ErrorMessage
                        name="capacity"
                        component="div"
                        className="error"
                      />
                    </div>
                    </div>
                    <div className="col-lg-6">
                      <div className="mb-3">
                        <label htmlFor="seal1">Seal 1</label>
                          <Field
                            type="text"
                            id="seal1"
                            name="seal1"
                            className="form-control"
                          />
                          <ErrorMessage
                            name="seal1"
                            component="div"
                            className="error"
                          />
                      </div>
                    </div>
                    <div className="col-lg-6">
                      <div className="mb-3">
                        <label htmlFor="seal2">Seal 2</label>
                          <Field
                            type="text"
                            id="seal2"
                            name="seal2"
                            className="form-control"
                          />
                          <ErrorMessage
                            name="seal2"
                            component="div"
                            className="error"
                          />
                      </div>
                    </div>
                    <div className="col-lg-6">
                      <div className="mb-3">
                        <label htmlFor="seal3">Seal 3</label>
                          <Field
                            type="text"
                            id="seal3"
                            name="seal3"
                            className="form-control"
                          />
                          <ErrorMessage
                            name="seal3"
                            component="div"
                            className="error"
                          />
                      </div>
                    </div>
                    <div className="col-lg-6">
                      <div className="mb-3">
                        <label htmlFor="seal4">Seal 4</label>
                          <Field
                            type="text"
                            id="seal4"
                            name="seal4"
                            className="form-control"
                          />
                          <ErrorMessage
                            name="seal4"
                            component="div"
                            className="error"
                          />
                      </div>
                    </div>
                    <div className="col-lg-6">
                      <div className="mb-3">
                        <label htmlFor="seal5">Seal 5</label>
                          <Field
                            type="text"
                            id="seal5"
                            name="seal5"
                            className="form-control"
                          />
                          <ErrorMessage
                            name="seal5"
                            component="div"
                            className="error"
                          />
                      </div>
                    </div>
                    <div className="col-lg-6">
                      <div className="mb-3">
                        <label htmlFor="seal6">Seal 6</label>
                          <Field
                            type="text"
                            id="seal6"
                            name="seal6"
                            className="form-control"
                          />
                          <ErrorMessage
                            name="seal6"
                            component="div"
                            className="error"
                          />
                      </div>
                    </div>
                    <div className="col-lg-6">
                      <div className="mb-3">
                        <label htmlFor="seal7">Seal 7</label>
                          <Field
                            type="text"
                            id="seal7"
                            name="seal7"
                            className="form-control"
                          />
                          <ErrorMessage
                            name="seal7"
                            component="div"
                            className="error"
                          />
                      </div>
                    </div>
                  </div>
                  
              
               
                  </div>
                  <div className="modal-footer">
                    <a
                      className="btn ouline-secondary"
                      data-bs-dismiss="modal"
                      id="closeEditModalBtnMeter"
                      onClick={() => {
                        setShowModal(false);
                        resetForm();
                      }}
                    >
                      Cancel
                    </a>
                    <button
                      disabled={isSubmitting}
                      type="submit"
                      className="btn btn-primary ms-auto text-white"
                    >
                      {isEdit ? "Update Meter" : "Create New Meter"}
                    </button>
                  </div>
                </Form>
              )}
            </Formik>
          )}
        </div>
      </div>
    </div>
  );
}

export default MeterForm;
