import React from 'react'
import { Imgs } from '../../utils/images'

function ErrorPage() {
  return (
   <div className="page-wrapper">
    <div className="page-body">
      <div className="container-xl d-flex flex-column justify-content-center">
        <div className="empty">
          <div className="empty-img">
            <img src={Imgs.blank} height="128" alt=""/>
          </div>
          <p className="empty-title">No Url match found</p>
          <p className="empty-subtitle text-muted">
            Try adjusting your search or filter to find what you're looking for.
          </p>
          <div className="empty-action">
            <a href="/" className="btn btn-primary text-white">
              Go to Home
            </a>
          </div>
        </div>
      </div>
    </div>
    <footer className="footer footer-transparent d-print-none">
      <div className="container-xl">
        <div className="row text-center align-items-center flex-row-reverse">
         
         
        </div>
      </div>
    </footer>
  </div>
  )
}

export default ErrorPage