import React, { useEffect, useState } from "react";
import { deleteVendor, fetchVendors, setVendorFilter } from "./vendorSlice";
import { useDispatch, useSelector } from "react-redux";
import VendorListForm from "./components/VendorListForm";
import DeleteDialog from "../../components/DeleteDialog";
import { errorMessage, successMessage } from "../../utils/tostify";
import ShimmerUITable from "../../components/shimmerUi/ShimmerTable";
import VendorAnalytics from "./components/VendorAnalytics";
import { iconsImgs } from "../../utils/images";
import SearchBox from "../../components/SearchBox";
import { debounce } from "../../utils/common";
import JSONToExcel from "../../components/JsonToExcel";
import Pagination from "../../components/Pagination";

function Vendor() {
  const [deletedId, setDeletedId] = useState(null);
  const [editItemDetails, setEditItemDetails] = useState({});
  const [isEdit, setIsEdit] = useState(false);
  const dispatch = useDispatch();
  const vendorList = useSelector((state) => state.vendor.vendors);
  const filterDataList = useSelector(
    (state) => state.vendor.filterVendorListData
  );
  const error = useSelector((state) => state.vendor.error);
  const isDeletedSuccessful = useSelector(
    (state) => state.vendor.isDeletedSuccessful
  );
  const isSaveduccessful = useSelector(
    (state) => state.vendor.isSavedSuccessful
  );
  const isLoading = useSelector((state) => state.vendor.loading);
  const [showModal, setShowModal] = useState(false);
  let message = isEdit
    ? "Vendor Updated Successfully"
    : "Vendor Added Successfully";
  useEffect(() => {
    if (isDeletedSuccessful) {
      dispatch(fetchVendors());
      successMessage("Vendor  Deleted Successfully");
    } else if (isSaveduccessful) {
      dispatch(fetchVendors());

      successMessage(message);
    }
  }, [isDeletedSuccessful, isSaveduccessful]);

  useEffect(() => {
    if (error) {
      errorMessage(error);
    }
  }, [error]);
  useEffect(() => {
    dispatch(fetchVendors());
  }, []);

  const onDelete = (cb) => {
    dispatch(deleteVendor({ id: deletedId }));
    cb();
  };




  ///This code nedds to move (Table pagination)
  const filterData = (query, keys) => {
    if(query){
      const filteredData = filterDataList.filter((item) => {
        return keys.some((key) =>
          item[key].toLowerCase().includes(query.toLowerCase())
        );
      });
      dispatch(setVendorFilter(filteredData));
      console.log(filteredData);
    }
    else{
      handlePageChange(currentPage);
    }
   
  };
  const debouncedFilterData = debounce(filterData, 800);
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemPerPage] = useState(5);
  const itemsPerPageList = [
    { id: 1, name: "5", value: 5 },
    { id: 2, name: "10", value: 10 },
    { id: 3, name: "15", value: 15 },
  ];

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
    const startIndex = (pageNumber - 1) * itemsPerPage;
    const endIndex = Math.min(startIndex + itemsPerPage, filterDataList.length);
    let filteredData = filterDataList.slice(startIndex, endIndex);
    dispatch(setVendorFilter(filteredData));
  };
  
  useEffect(() => {
    handlePageChange(currentPage);

    return () => {};
  }, [itemsPerPage, filterDataList]);

  return (
    <div>
      <div className="card">
        <div className="card-header d-flex justify-content-between">
          <h3 className="card-title">Vendor Master Table</h3>

          <div>
            <a
              className="btn btn-bitbucket text-white"
              data-bs-toggle="modal"
              data-bs-target="#vendorFormModal"
              onClick={(e) => {
                setShowModal(true);
                setEditItemDetails({
                  vendorcode: "",
                  name: "",
                  email: "",
                  mobile: "",
                  gstnumber: "",
                  pan: "",
                  imei: "",
                  address: "",
                });
                setIsEdit(false);
              }}
            >
              <img src={iconsImgs.plus} width={20} height={20} alt="Add" />
              Add
            </a>

            <JSONToExcel data={vendorList} fileName="vendor" />
          </div>
        </div>
        <div className="card-body border-bottom py-3">
          <div className="d-flex">
            <div className="text-muted">
              Show
              <div className="mx-2 d-inline-block">
                <select
                  className="form-control "
                  onChange={(e) => setItemPerPage(e.target.value)}
                >
                  {itemsPerPageList.map((item, i) => (
                    <option
                      className="dropdown-item cursor-pointer"
                      value={item.value}
                    >
                      {item.name}
                    </option>
                  ))}
                </select>
              </div>
              entries
            </div>
            <div className="ms-auto text-muted">
              <SearchBox
                onChange={(e) => {
                  debouncedFilterData(e.target.value, ["name"]);
                 
                }}
              />
            </div>
          </div>
        </div>
        {isLoading ? (
          <ShimmerUITable row={4} />
        ) : (
          <div className="table-responsive">
            <table className="table card-table table-vcenter text-nowrap datatable">
              <thead>
                <tr>
                  <th className="w-1">Id.</th>
                  <th>Vendor Code</th>
                  <th>Name</th> 
                  <th>Email</th>
                  <th>Mobile</th>
                  <th>GST</th>
                  <th>PAN</th>
                  <th>IMEI</th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                {vendorList?.map((vendor) => {
                  return (
                    <tr key={vendor.id}>
                      <td>
                        <span className="text-muted">{vendor.id}</span>
                      </td>
                      <td>{vendor.vendorcode}</td>
                      <td>
                        
                        {vendor.name}
                      </td>
                      <td>{vendor.email}</td>
                      <td>{vendor.mobile}</td>
                      <td>{vendor.gstnumber}</td>
                      <td>{vendor.pan}</td>

                      <td>
                        <span className="badge bg-success me-1"></span>{" "}
                        {vendor.imei}
                      </td>

                      <td className="text-end">
                        <VendorAnalytics />
                        <a
                          className="btn  w-25 text-white  btn-icon mx-2 text-primary"
                          aria-label="Facebook"
                          data-bs-toggle="modal"
                          data-bs-target="#vendorFormModal"
                          title="Edit"
                          onClick={() => {
                            setEditItemDetails(vendor);
                            setIsEdit(true);
                            setShowModal(true);
                          }}
                        >
                          <img
                            src={iconsImgs.edit}
                            width={20}
                            height={20}
                            alt=""
                          />
                        </a>
                        <a
                          data-bs-toggle="modal"
                          data-bs-target="#modal-small"
                          className="btn w-25  mx-2 btn-icon text-danger"
                          aria-label="delete"
                          title="Delete"
                          onClick={() => setDeletedId(vendor.id)}
                        >
                          <img
                            color="red"
                            src={iconsImgs.deleteIcon}
                            width={20}
                            height={20}
                            alt=""
                          />
                        </a>
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
        )}
       

          <Pagination
            totalItems={filterDataList?.length}
            itemsPerPage={itemsPerPage}
            currentPage={currentPage}
            onPageChange={handlePageChange}
          />
    
      </div>

      <DeleteDialog
        modalId="modal-small"
        onDeleteCall={onDelete}
        closeId={"deleteNoButton"}
        message={
          "Are you sure you want to delete this item? This action cannot be undone."
        }
      />
      <VendorListForm
        id="vendorFormModal"
        editItemDetails={editItemDetails}
        isEdit={isEdit}
        setShowModal={setShowModal}
        showModal={showModal}
      />
    </div>
  );
}

export default Vendor;
