import instance from "../../api/axiosInstance";
import { createFormData } from "../../utils/common";


export const fetchOEMDetails=()=>{
    return instance.get("oem/get_all")
} 
export const saveOEMDetails=(payload)=>{
    let _payload=createFormData(payload)
    return instance.post("oem/save",_payload)
} 
export const getOEMDetailsById=(payload)=>{
    return instance.post("oem/getById",payload)
} 
export const deleteOEMDetailsById=(payload)=>{
    let _payload=createFormData(payload)
    return instance.post("oem/deleteList",_payload)
} 
export const editOEMDetails=(payload)=>{
    let _payload=createFormData(payload)
    return instance.post("oem/editList",_payload)
} 

export const saveCoverOEM=(payload)=>{
    let _payload=createFormData(payload)
     return instance.post("globalvariable/upload_file",_payload)
 } 
 


