import ReactApexChart from "react-apexcharts";

function LineChart() {
    const series = [
        {
          name: "Party 1",
          data: [60, 80, 30, 50, 45, 60],
        },
        {
          name: "Party 2",
          data: [45, 100, 10, 90, 45, 98],
        },
      ];
      
    const options = {
        chart: {
          height: 350,
          width: "100%",
          type: 'line',
          zoom: {
            enabled: false,
          },
          animations: {
            enabled: true,
            easing: 'easein',
            speed: 800,
            animateGradually: {
                enabled: true,
                delay: 100
            }
          },
        },
        plotOptions: {
          bar: {
            borderRadius: 10,
            
            distributed: true

          }
        },
        dataLabels: {
          enabled: false,
        },
        stroke: {
            curve: 'smooth',
        },
        title: {
          text: 'Monthly Meter Installation',
          align: 'left',
        },
        xaxis: {
          categories: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun'],
        },
        yaxis: {
            forceNiceScale: true,
            min:0,
        },
      };
      
  return (
    <div id="chart">
      <ReactApexChart options={options} series={series} type="line" height={250} />
    </div>
  );
}

export default LineChart;
