import instance from "../../api/axiosInstance";
import { createFormData } from "../../utils/common";


export const fetchWarehouseDetails=()=>{
    return instance.get("warehouse/get_all")
} 
export const saveWarehouseDetails=(payload)=>{
    let _payload=createFormData(payload)
    return instance.post("warehouse/save",_payload)
} 
export const getWarehouseDetailsById=(payload)=>{
    return instance.post("warehouse/getById",payload)
} 
export const deleteDetailsById=(payload)=>{
    let _payload=createFormData(payload)
    return instance.post("warehouse/deleteList",_payload)
} 
export const editWarehouseDetails=(payload)=>{
    let _payload=createFormData(payload)
    return instance.post("warehouse/editList",_payload)
} 