import React, { useEffect, useState } from "react";
import { iconsImgs } from "../../utils/images";
import JSONToExcel from "../../components/JsonToExcel";
import SearchBox from "../../components/SearchBox";
import ShimmerUITable from "../../components/shimmerUi/ShimmerTable";
import { useDispatch, useSelector } from "react-redux";
import DeleteDialog from "../../components/DeleteDialog";
import { errorMessage, successMessage } from "../../utils/tostify";
import { ActiveInactive, ActiveInactiveID } from "../../data/dropdown";
import { useParams } from "react-router-dom";
import { setPageTitle } from "../setting/globalSetting.slice";

import { debounce } from "../../utils/common";
import Pagination from "../../components/Pagination";
import {
    deletediscomlab,
    fetchdiscomlabs,
    setDiscomlabFilter,
  } from "./discomlabSlice";
import { fetchUsers } from "../users/userSlice";
import DiscomlabForm from "./DiscomlabForm";
import Stocks from "./components/Stocks";


const DiscomLab = () => {
    const plannerID = 96;
     let { status } = useParams();
    const dispatch = useDispatch();
    const [isEdit, setIsEdit] = useState(false);
    const [editItemDetails, setEditItemDetails] = useState({});
    const [deletedId, setDeletedId] = useState(null);
    const {
      discomlab: discomlabList= [],
      loading,
      isDeletedSuccessful,
      isSavedSuccessful,
      filterDiscomlabListData,
      error,
    } = useSelector((state) => state.discomlab);
  
    const { users: PlannerList } = useSelector((state) => state.users);
  
    const { dropdownList, pageTitle } = useSelector(
      (state) => state.globalSetting
    );
  
    const [showModal, setShowModal] = useState(false);
    let message = isEdit
      ? "Discom Lab Updated Successfully"
      : "Discom Lab Added Successfully";
    useEffect(() => {
      if (isDeletedSuccessful) {
        dispatch(fetchdiscomlabs());
        successMessage("Discom Lab Deleted Successfully");
      } else if (isSavedSuccessful) {
        dispatch(fetchdiscomlabs());
  
        successMessage(message);
      }
  
      return () => {};
    }, [isDeletedSuccessful, isSavedSuccessful]);
  
    useEffect(() => {
      if(discomlabList.length==0){
        dispatch(fetchdiscomlabs());
  
      }
      dispatch(fetchUsers(plannerID));
    }, []);
    useEffect(() => {
      if (error) {
        errorMessage(error);
      }
    }, [error]);
  
  
    const getPlannerName = (id) => {
      let plannerName = PlannerList.find((item) => item.id == id);
      if (plannerName) {
        return plannerName.first_name + " " + plannerName.last_name;
      }
      return "";
    };
  
  
    useEffect(() => {
      if (Object.keys(dropdownList).length > 0 && status) {
        dispatch(setPageTitle(status));
        dispatch(fetchdiscomlabs(status));
      }
    }, [dropdownList, status]);
  
    const onDelete = (cb) => {
        if(discomlabList.length>1){
            dispatch(deletediscomlab({ id: deletedId }));
            cb();
        }else{
            errorMessage("One Item Must be Present!");
            window.location.reload();
        }
     
    };
  
  
  
    const filterData = (query, keys) => {
      const filteredData = filterDiscomlabListData.filter((item) => {
        return keys.some((key) =>
          item[key].toLowerCase().includes(query.toLowerCase())
        );
      });
      dispatch(setDiscomlabFilter(filteredData));
      console.log(filteredData);
    };
  
    const debouncedFilterData = debounce(filterData, 800);
  
    const [currentPage, setCurrentPage] = useState(1);
    const [itemsPerPage, setItemPerPage] = useState(5);
    const itemsPerPageList = [
      { id: 1, name: "5", value: 5 },
      { id: 2, name: "10", value: 10 },
      { id: 3, name: "15", value: 15 },
    ];
  
    const handlePageChange = (pageNumber) => {
      setCurrentPage(pageNumber);
      const startIndex = (pageNumber - 1) * itemsPerPage;
      const endIndex = Math.min(
        startIndex + itemsPerPage,
        filterDiscomlabListData.length
      );
      let filteredData = filterDiscomlabListData.slice(startIndex, endIndex);
      dispatch(setDiscomlabFilter(filteredData));
    };
  
    useEffect(() => {
      handlePageChange(currentPage);
  
      return () => {};
    }, [itemsPerPage, filterDiscomlabListData]);
  
    return (
      <div>
        <div className="card">
          {/* Page Header */}
          <div className="card-header d-flex justify-content-between">
            <h3 className="card-title">Discom Lab Master Table</h3>
            <div>
              <a
                className="btn btn-bitbucket text-white"
                data-bs-toggle="modal"
                data-bs-target="#discomlabFormModal"
                onClick={(e) => {
                  setShowModal(true);
                  setEditItemDetails({
                    status: "",
                    name: "",
                    address: "",
                    latitude: "",
                    longitude: "",
                    planner: "",
                  });
                  setIsEdit(false);
                }}
              >
                <img src={iconsImgs.plus} width={20} height={20} alt="Add" />
                Add
              </a>
  
              <JSONToExcel data={discomlabList} fileName="discomlab" />
            </div>
          </div>
  
          <div className="card-body border-bottom py-3">
            <div className="d-flex">
              <div className="text-muted">
                Show
                <div className="mx-2 d-inline-block">
                <select
                    className="form-control "
                    onChange={(e) => setItemPerPage(e.target.value)}
                  >
                    {itemsPerPageList.map((item, i) => (
                      <option
                        className="dropdown-item cursor-pointer"
                        value={item.value}
                      >
                        {item.name}
                      </option>
                    ))}
                  </select>
                </div>
                entries
              </div>
              <div className="ms-auto text-muted">
                <SearchBox
                  onChange={(e) => {
                    debouncedFilterData(e.target.value, ["name"]);
                  }}
                />
              </div>
            </div>
          </div>
          {loading ? (
            <ShimmerUITable row={4} />
          ) : (
            <div className="table-responsive">
              <table className="table card-table table-vcenter text-nowrap datatable">
                <thead>
                  <tr>
                    <th className="w-1">Id</th>
                    <th>Status</th>
                    <th>Name</th>
                    <th>Address</th>
                    <th>Latitude</th>
                    <th>Longitude</th>
                    <th>Planner</th>
  
                    <th></th>
                  </tr>
                </thead>
                <tbody>
                  {discomlabList?.map((discomlab) => {
                    return (
                      <tr key={discomlab.id}>
                        <td>
                          <span className="text-muted">{discomlab.id}</span>
                        </td>
                        <td>
                          {ActiveInactiveID.active.includes(
                            Number(discomlab.status)
                          ) ? (
                            <span className="status-dot status-dot-animated  bg-success me-2"></span>
                          ) : (
                            <span className=" status-dot status-dot-animated  bg-danger me-2"></span>
                          )}
  
                          {ActiveInactive[discomlab.status] || "Others"}
                        </td>
                        <td>{discomlab.name}</td>
                        <td>{discomlab.address}</td>
                        <td>{discomlab.latitude}</td>
                        <td>{discomlab.longitude}</td>
                        <td>{getPlannerName(discomlab.planner)}</td>
  
                        <td className="text-end">
                          {/* <Stocks /> */}
                          <Stocks discomlabid={discomlab.id}/>

                          <a
                            className="btn  w-25 text-white  btn-icon mx-2 text-primary"
                            aria-label="Facebook"
                            data-bs-toggle="modal"
                            data-bs-target="#discomlabFormModal"
                            title="Edit"
                            onClick={() => {
                              setEditItemDetails(discomlab);
                              setIsEdit(true);
                              setShowModal(true);
                            }}
                          >
                            <img
                              src={iconsImgs.edit}
                              width={20}
                              height={20}
                              alt=""
                            />
                          </a>
                          <a
                            data-bs-toggle="modal"
                            data-bs-target="#discomlab-delete"
                            className="btn w-25  mx-2 btn-icon text-danger"
                            aria-label="delete"
                            title="Delete"
                            onClick={() => {
                              setDeletedId(discomlab.id);
                            }}
                          >
                            <img
                              color="red"
                              src={iconsImgs.deleteIcon}
                              width={20}
                              height={20}
                              alt=""
                            />
                          </a>
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>
          )}
  
          <Pagination
            totalItems={filterDiscomlabListData.length}
            itemsPerPage={itemsPerPage}
            currentPage={currentPage}
            onPageChange={handlePageChange}
          />
        </div>
  
        <DeleteDialog
          modalId="discomlab-delete"
          onDeleteCall={onDelete}
          closeId={"deleteNoButtonDiscomlab"}
          message={
            "Are you sure you want to delete this item? This action cannot be undone."
          }
        />
        <DiscomlabForm
          id="discomlabFormModal"
          editItemDetails={editItemDetails}
          isEdit={isEdit}
          setShowModal={setShowModal}
          showModal={showModal}
          dropdownList={dropdownList}
          plannerList={PlannerList}
        />
      </div>
    );
  };
  
  export default DiscomLab;
