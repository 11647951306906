import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { deleteSupervisorDetailsById, editSupervisorDetails, fetchSupervisorDetails, saveSupervisorDetails } from "./supervisor.api";

const initialState={
    loading:false,
    error:"",
    supervisors: [],
    isDeletedSuccessful:false,
    isSavedSuccessful:false,
    filterSupervisorListData:[]
    

   }
export const fetchSupervisors=createAsyncThunk(
     "supervisors/fetchSupervisors",()=>fetchSupervisorDetails()
   );

export const saveSupervisor=createAsyncThunk(
    "supervisors/saveSupervisors",(data)=>saveSupervisorDetails(data)
  );
  export const deleteSupervisor=createAsyncThunk(
    "supervisors/deleteSupervisors",(id)=>deleteSupervisorDetailsById(id)
  )

  export const editSupervisor=createAsyncThunk(
    "supervisors/editSupervisors",(id)=>editSupervisorDetails(id)
  )


const supervisorSlice=createSlice({
name:'supervisor',
initialState,
reducers:{
  setSupervisorFilter:(state,action)=>{
      state.supervisors= action.payload;
  },

},
extraReducers: (builder) => {
  builder
    .addCase(fetchSupervisors.pending,(state)=>{
      state.loading=true;
      state.error="";
    })
    .addCase(fetchSupervisors.fulfilled,(state,action)=> {
      state.error = "";
        state.loading = false;
        state.isDeletedSuccessful=false;
        state.isSavedSuccessful=false;
        state.supervisors = action.payload?.data.data;
        state.filterSupervisorListData=action.payload?.data.data;
    })
    .addCase(fetchSupervisors.rejected, (state, action) =>{
      state.loading = false;
      state.error = action.error.message || "Something went wrong!";
    })  
//Save supervisor
   .addCase(saveSupervisor.pending,(state)=>{
        state.loading=true;
        state.error="";
      })
      .addCase(saveSupervisor.fulfilled,(state,action)=> {
          state.loading = false;
          state.isSavedSuccessful=true;
         
      })
      .addCase(saveSupervisor.rejected, (state, action) =>{
        state.loading = false;
        state.error = action.error.message || "Something went wrong!";
      }) 
//Delete Supervisor
      .addCase(deleteSupervisor.pending,(state)=>{
        state.loading=true;
        state.error="";
      })
      .addCase(deleteSupervisor.fulfilled,(state,action)=> {
          state.loading = false;
          state.isDeletedSuccessful=true;
         
      })
      .addCase(deleteSupervisor.rejected, (state, action) =>{
        state.loading = false;
        state.error = action.error.message || "Something went wrong!";
      }) 
      //Edit Supervisor
      .addCase(editSupervisor.pending,(state)=>{
        state.loading=true;
        state.error="";
      })
      .addCase(editSupervisor.fulfilled,(state,action)=> {
          state.loading = false;
          state.isSavedSuccessful=true;
         
      })
      .addCase(editSupervisor.rejected, (state, action) =>{
        state.loading = false;
        state.error = action.error.message || "Something went wrong!";
      }) 
}
})
export default supervisorSlice.reducer;
export const {setSupervisorFilter } = supervisorSlice.actions
