import React, { useEffect, useRef, useState } from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import { useDispatch } from "react-redux";
import { MDBIcon } from "mdb-react-ui-kit";
import { ShimmerTable } from "react-shimmer-effects";
import { editwarehouse, savewarehouse } from "./warehouseSlice";
import { OptionsMaster } from "./../../data/dropdown";
import Select from "react-select";

function WarehouseForm({
  id,
  editItemDetails,
  isEdit,
  showModal,
  setShowModal,
  dropdownList,
  plannerList,
}) {
  const [location, setLocation] = useState({
    latitude: editItemDetails?.latitude || "",
    longitude: editItemDetails?.longitude || "",
  });

  const [showLocationDetails, setShowLocationDetails] = useState(false);
  const dispatch = useDispatch();
  const modalRef = useRef(null);

  const closeModal = () => {
    const button = document.getElementById("closeEditModalBtnWarehouse");
    button.click();
  };

  const validationSchema = Yup.object({
    status: Yup.number().required("Status is required"),
    name: Yup.string().required("Name is required"),
    address: Yup.string().required("Address is required"),
    latitude: Yup.number().required("Latitude is required"),
    longitude: Yup.number().required("Longitude is required"),
    planner: Yup.string().required("Planner is required"),
  });

  const onSubmit = (values, { resetForm, setSubmitting }) => {
    console.log(values);
    if (isEdit) {
      dispatch(editwarehouse(values));
    } else {
      dispatch(savewarehouse(values));
    }
    setSubmitting(false);
    resetForm();
    closeModal();
  };
  const fetchLocation = async () => {
    return new Promise((resolve, reject) => {
      if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition(
          (position) => {
            const latitude = position.coords.latitude;
            const longitude = position.coords.longitude;

            setLocation({ latitude, longitude });
            resolve({ latitude, longitude });
          },
          (error) => {
            console.error("Error getting current position:", error);
            reject(error);
          }
        );
      } else {
        console.error("Geolocation is not supported by this browser.");
        reject(new Error("Geolocation is not supported by this browser."));
      }
    });
  };

  return (
    <div
      className="modal modal-blur fade"
      id={id}
      tabIndex="-1"
      role="dialog"
      aria-hidden="true"
      ref={modalRef}
      data-bs-backdrop="static"
    >
      <div
        className="modal-dialog modal-lg modal-dialog-centered"
        role="document"
      >
        <div className="modal-content">
          {showModal && (
            <Formik
              initialValues={{
                id: editItemDetails?.id,
                status: editItemDetails?.status,
                name: editItemDetails?.name,
                address: editItemDetails?.address,
                latitude: editItemDetails.latitude,
                longitude: editItemDetails.longitude,
                planner: editItemDetails.planner,
              }}
              validationSchema={validationSchema}
              onSubmit={onSubmit}
            >
              {({ values, isSubmitting, resetForm, setFieldValue }) => {
                const selectedPlanner = plannerList.find(
                  (item) => item.id === values.planner
                );
                return (
                  <Form>
                    <div className="modal-header">
                      <h5 className="modal-title">Warehouse Form</h5>
                      <button
                        onClick={() => {
                          setShowModal(false);
                          resetForm();
                        }}
                        type="button"
                        className="btn-close"
                        data-bs-dismiss="modal"
                        aria-label="Close"
                      ></button>
                    </div>
                    <div className="modal-body">
                      <div className="row">
                        <div className="col-lg-6">
                          <div className="mb-3">
                            <label htmlFor="name">Name</label>
                            <Field
                              type="text"
                              id="name"
                              name="name"
                              className="form-control"
                            />
                            <ErrorMessage
                              name="name"
                              component="div"
                              className="error"
                            />
                          </div>
                        </div>

                        {/* <div className="col-lg-6">
                        <div className="mb-3">
                          <label htmlFor="status">Status</label>
                          <Field
                            as="select"
                            id="status"
                            name="status"
                            className="form-control"
                          >
                            <option value="">Select Status</option>
                            {dropdownList[OptionsMaster.status].map((item) => {
                              return (
                                <option value={item.id} key={item.id}>
                                  {item.name}
                                </option>
                              );
                            })}

                          </Field>

                          <ErrorMessage
                            name="status"
                            component="div"
                            className="error"
                          />
                        </div>
                      </div> */}
                        <div className="col-lg-6">
                          <div className="mb-3">
                            <label htmlFor="status">Status</label>
                            <Field
                              as="select"
                              id="status"
                              name="status"
                              className="form-control"
                            >
                              <option value="">Select Status</option>
                              {dropdownList[OptionsMaster.status].map(
                                (item) => {
                                  return (
                                    <option value={item.id} key={item.id}>
                                      {item.name}
                                    </option>
                                  );
                                }
                              )}
                            </Field>
                            <ErrorMessage
                              name="gender"
                              component="div"
                              className="error"
                            />
                          </div>
                        </div>
                      </div>
                      {showLocationDetails ? (
                        <ShimmerTable row={1} col={2} />
                      ) : (
                        <div className="row align-items-center">
                          <div className="col-lg-5">
                            <div className="mb-3">
                              <label htmlFor="latitude">Latitude</label>
                              <Field
                                type="number"
                                id="latitude"
                                name="latitude"
                                className="form-control"
                              />
                              <ErrorMessage
                                name="latitude"
                                component="div"
                                className="error"
                              />
                            </div>
                          </div>
                          <div className="col-lg-5">
                            <div className="mb-3">
                              <label htmlFor="longitude">Longitude</label>
                              <Field
                                type="number"
                                id="longitude"
                                name="longitude"
                                className="form-control"
                              />
                              <ErrorMessage
                                name="longitude"
                                component="div"
                                className="error"
                              />
                            </div>
                          </div>

                          <div className="col-sm-2 mt-2">
                            <button
                              type="button"
                              className="btn btn-outline-primary "
                              onClick={async () => {
                                setShowLocationDetails(true);
                                try {
                                  const { latitude, longitude } =
                                    await fetchLocation();

                                  setFieldValue("latitude", latitude);
                                  setFieldValue("longitude", longitude);
                                  setShowLocationDetails(false);
                                } catch (error) {
                                  setShowLocationDetails(false);
                                  console.error("Error:", error);
                                }
                              }}
                            >
                              <MDBIcon fas icon="map-marked-alt" />
                            </button>
                          </div>
                          <div className="col-lg-5">
                            <div className="mb-3">
                              <label htmlFor="planner">Planner</label>

                              {/* <Field
                                    as="select"
                                    id="planner"
                                    name="planner"
                                    className="form-control"
                                    >
                                      <option value="">Select Planner</option>{
                                      plannerList?.map((item)=>{

                                    return  <option value={item.id}key={item.id}>{item.first_name}{item.last_name}</option>
                                      })
                                    }
                              
                                    </Field> */}

                              <Field
                                as={Select}
                                id="planner"
                                name="planner"
                                options={plannerList.map((item) => ({
                                  value: item.id,
                                  label: `${item.first_name} ${item.last_name}`,
                                }))}
                                onChange={(option) =>
                                  setFieldValue("planner", option?.value || "")
                                }
                                className="basic-single"
                                classNamePrefix="select"
                                isClearable={true}
                                value={
                                  selectedPlanner
                                    ? {
                                        value: selectedPlanner.id,
                                        label: `${selectedPlanner.first_name} ${selectedPlanner.last_name}`,
                                      }
                                    : null
                                }
                              />
                              <ErrorMessage
                                name="planner"
                                component="div"
                                className="error"
                              />
                            </div>
                          </div>
                        </div>
                      )}
                      <div className="mb-3">
                        <label htmlFor="address">Address</label>
                        <Field
                          as="textarea"
                          id="address"
                          name="address"
                          className="form-control"
                        />
                        <ErrorMessage
                          name="address"
                          component="div"
                          className="error"
                        />
                      </div>
                    </div>
                    <div className="modal-footer">
                      <a
                        className="btn ouline-secondary"
                        data-bs-dismiss="modal"
                        id="closeEditModalBtnWarehouse"
                        onClick={() => {
                          setShowModal(false);
                          resetForm();
                        }}
                      >
                        Cancel
                      </a>
                      <button
                        disabled={isSubmitting}
                        type="submit"
                        className="btn btn-primary ms-auto text-white"
                      >
                        {isEdit ? "Update Warehouse" : "Create New Warehouse"}
                      </button>
                    </div>
                  </Form>
                );
              }}
            </Formik>
          )}
        </div>
      </div>
    </div>
  );
}

export default WarehouseForm;
