import React, { useEffect, useRef, useState } from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import { useDispatch } from "react-redux";
import { editUser, saveUser, uploadCoverImg } from "./userSlice";
import { personsImgs } from "../../utils/images";
import { OptionsMaster } from "../../data/dropdown";

function UserForm({ id, editItemDetails, isEdit, showModal, setShowModal,dropdownList }) {
  const dispatch = useDispatch();
  const modalRef = useRef(null);
  const formRef = useRef();
  const editCoverRef = useRef();
  const [isCoverPicChanged, setIsCoverPicChanged] = useState(false);
  const [coverImg, setCoverImg] = useState(null);

  useEffect(() => {
    console.log(editItemDetails);
    if (editItemDetails?.cover) {
      setCoverImg(editItemDetails?.cover);
    }

    return () => {};
  }, [editItemDetails]);

  const closeModal = () => {
    const button = document.getElementById("closeEditModalBtnUser");
    button.click();
  };

  const validationSchema = Yup.object({
    first_name: Yup.string().required("First name is required"),
    last_name: Yup.string().required("Last name is required"),
    email: Yup.string().required("Email is required"),
    password: Yup.string().required("Password is required"),
    // gender: Yup.string().required("Gender is required"),
    // usertype: Yup.string().required("Type is required"),
    // status: Yup.string().required("Status is required"),
    mobile: Yup.string().required("Mobile is required"),
    date: Yup.date().required("Date is required"),
    // company: Yup.string().required("Company is required"),
    imei: Yup.string().required("IMEI is required"),
    address: Yup.string().required("Address is required"),
  });

  const onSubmit = async (values, { resetForm, setSubmitting }) => {
    debugger
    try {
      if (isEdit) {
        if (isCoverPicChanged) {
          const originalPromiseResult = await dispatch(
            uploadCoverImg({ userfile: coverImg })
          ).unwrap();
           values.cover = originalPromiseResult.data.data;
        }
        dispatch(editUser(values));
      } else {
        const originalPromiseResult = await dispatch(
          uploadCoverImg({ userfile: coverImg })
        ).unwrap();

        values.cover = originalPromiseResult.data.data;
        dispatch(saveUser(values));
      }
      setSubmitting(false);
      resetForm();
      closeModal();
    } catch (rejectedValueOrSerializedError) {}
  };

  return (
    <div
      className="modal modal-blur fade"
      id={id}
      tabIndex="-1"
      role="dialog"
      aria-hidden="true"
      ref={modalRef}
      data-bs-backdrop="static"
    >
      <div
        className="modal-dialog modal-lg modal-dialog-centered"
        role="document"
      >
        <div className="modal-content">
          {showModal && (
            <Formik
              initialValues={{
                id: editItemDetails?.id || "",
                first_name: editItemDetails?.first_name || "",
                last_name: editItemDetails?.last_name || "",
                email: editItemDetails?.email || "",
                password: editItemDetails?.password || "",
                gender: editItemDetails?.gender || "",
                usertype: editItemDetails?.usertype || "",
                status: editItemDetails?.status || "",
                cover: editItemDetails?.cover || "",
                mobile: editItemDetails?.mobile || "",
                others: editItemDetails?.others || "",
                date: editItemDetails?.date || "",
                // company: editItemDetails?.company || "",
                imei: editItemDetails?.imei || "",
                address: editItemDetails?.address || "",
              }}
              validationSchema={validationSchema}
              onSubmit={onSubmit}
            >
              {({ isSubmitting, resetForm, setFieldValue }) => (
                <Form ref={formRef}>
                  <div className="modal-header">
                    <h5 className="modal-title">User Form</h5>
                    <button
                      onClick={() => {
                        setShowModal(false);
                        resetForm();
                        // setCoverImg("");
                        setIsCoverPicChanged(false);
                      }}
                      type="button"
                      className="btn-close"
                      data-bs-dismiss="modal"
                      aria-label="Close"
                    ></button>
                  </div>
                  <div className="modal-body">
                    <div className="row">
                      <div className="col-lg-6">
                        <div className="mb-3">
                          <label htmlFor="first_name">First Name</label>
                          <Field
                            type="text"
                            id="first_name"
                            name="first_name"
                            className="form-control"
                          />
                          <ErrorMessage
                            name="first_name"
                            component="div"
                            className="error"
                          />
                        </div>
                      </div>
                      <div className="col-lg-6">
                        <div className="mb-3">
                          <label htmlFor="last_name">Last Name</label>
                          <Field
                            type="text"
                            id="last_name"
                            name="last_name"
                            className="form-control"
                          />
                          <ErrorMessage
                            name="last_name"
                            component="div"
                            className="error"
                          />
                        </div>
                      </div>

                      <div className="col-lg-6">
                        <div className="mb-3">
                          <label htmlFor="email">Email</label>
                          <Field
                            type="email"
                            id="email"
                            name="email"
                            className="form-control"
                          />
                          <ErrorMessage
                            name="email"
                            component="div"
                            className="error"
                          />
                        </div>
                      </div>
                      <div className="col-lg-6">
                        <div className="mb-3">
                          <label htmlFor="password">Password</label>
                          <Field
                            type="text"
                            id="password"
                            name="password"
                            className="form-control"
                          />
                          <ErrorMessage
                            name="password"
                            component="div"
                            className="error"
                          />
                        </div>
                      </div>
                    </div>

                    <div className="row">
                      <div className="col-lg-6">
                        <div className="mb-3">
                          <label htmlFor="gender">Gender</label>
                          <Field
                            as="select"
                            id="gender"
                            name="gender"
                            className="form-control"
                          >
                             <option value="">Select Gender</option>{
                            dropdownList[OptionsMaster.gender].map((item)=>{
                          return  <option value={item.id}key={item.id}>{item.name}</option>
                            })
                          }
                      
                          </Field>
                          <ErrorMessage
                            name="gender"
                            component="div"
                            className="error"
                          />
                        </div>
                      </div>
                      <div className="col-lg-6">
                
                        {!isEdit ? (
                          <div className="mb-3">
                            {coverImg ? (
                              <>
                                <img
                                  src={URL.createObjectURL(coverImg)}
                                  className="rounded-circle"
                                  style={{ width: 60, height: 60 }}
                                  alt="Avatar"
                                />
                              </>
                            ) : (
                              <>
                                <label htmlFor="cover">Cover</label>
                                <Field
                                  type="file"
                                  id="cover"
                                  name="filename"
                                  accept="image/*"
                                  className="form-control"
                                  onChange={(event) => {
                                    event.preventDefault();
                                    const formData = new FormData(
                                      formRef.current
                                    );
                                    const file = formData.get("filename");
                                    setCoverImg(file);
                                  }}
                                />

                                <ErrorMessage
                                  name="cover"
                                  component="div"
                                  className="error"
                                />
                              </>
                            )}
                          </div>
                        ) : (
                          <>
                            {isCoverPicChanged ? (
                              <img
                                src={URL.createObjectURL(coverImg)}
                                className="rounded-circle"
                                style={{ width: 60, height: 60 }}
                                alt="Avatar"
                              />
                            ) : (
                              <img
                                onError={(e) =>
                                  (e.target.src = personsImgs.person_three)
                                }
                                src={`http://apiinnovel.apiitech.co.in/uploads/${coverImg}`}
                                className="rounded-circle"
                                style={{ width: 60 }}
                                alt="Avatar"
                              />
                            )}

                            <Field
                              innerRef={editCoverRef}
                              type="file"
                              id="cover"
                              name="filename"
                              accept="image/*"
                              className="form-control"
                              onChange={(event) => {
                                event.preventDefault();
                                const formData = new FormData(formRef.current);
                                const file = formData.get("filename");
                                setCoverImg(file);
                                setIsCoverPicChanged(true);
                              }}
                              hidden
                            />

                            {/* <input accept="image/*"  type="file" id="editCover" name="editCover" ref={editCoverRef} hidden
                        onChange={(e)=>{
                          console.log(e) } }/> */}

                            <button
                              className="btn mx-4 btn-outline-info"
                              onClick={(e) => {
                                e.stopPropagation();
                                e.preventDefault();
                                editCoverRef.current.click();
                              }}
                            >
                              Change Picture
                            </button>
                          </>
                        )}
                      </div>
                    </div>

                    <div className="row">
                      <div className="col-lg-6">
                        <div className="mb-3">
                          <label htmlFor="usertype">User Type</label>
                          <Field
                            as="select"
                            id="usertype"
                            name="usertype"
                            className="form-control"
                          >
                            <option value="">Select Type</option>
                            {
                            dropdownList[OptionsMaster.usertype].map((item)=>{
                          return  <option value={item.id}key={item.id}>{item.name}</option>
                            })
                          }
                          </Field>
                          <ErrorMessage
                            name="usertype"
                            component="div"
                            className="error"
                          />
                        </div>
                      </div>

                      <div className="col-lg-6">
                        <div className="mb-3">
                          <label htmlFor="status">Status</label>
                          <Field
                            as="select"
                            id="status"
                            name="status"
                            className="form-control"
                          >
                            <option value="">Select Status</option>
                            {
                            dropdownList[OptionsMaster.status].map((item)=>{
                          return  <option value={item.id}key={item.id}>{item.name}</option>
                            })
                          }
                          </Field>
                          <ErrorMessage
                            name="status"
                            component="div"
                            className="error"
                          />
                        </div>
                      </div>
                    </div>

                    <div className="row">
                      <div className="col-lg-6">
                        <div className="mb-3">
                          <label htmlFor="mobile">Mobile</label>
                          <Field
                            type="text"
                            id="mobile"
                            name="mobile"
                            className="form-control"
                          />
                          <ErrorMessage
                            name="mobile"
                            component="div"
                            className="error"
                          />
                        </div>
                      </div>
                      {/* <div className="col-lg-6">
                        <div className="mb-3">
                          <label htmlFor="company">Company</label>
                          <Field
                            type="text"
                            id="company"
                            name="company"
                            className="form-control"
                          />
                          <ErrorMessage
                            name="company"
                            component="div"
                            className="error"
                          />
                        </div>
                      </div> */}
                      <div className="col-lg-6">
                        <div className="mb-3">
                          <label htmlFor="imei">IMEI</label>
                          <Field
                            type="text"
                            id="imei"
                            name="imei"
                            className="form-control"
                          />
                          <ErrorMessage
                            name="imei"
                            component="div"
                            className="error"
                          />
                        </div>
                      </div>
                    </div>

                    <div className="row">
                      <div className="col-lg-6">
                        <div className="mb-3">
                          <label htmlFor="others">Others</label>
                          <Field
                            type="text"
                            id="others"
                            name="others"
                            className="form-control"
                          />
                          <ErrorMessage
                            name="others"
                            component="div"
                            className="error"
                          />
                        </div>
                      </div>

                      <div className="col-lg-6">
                        <div className="mb-3">
                          <label htmlFor="date">Date</label>
                          <Field
                            type="date"
                            id="date"
                            name="date"
                            className="form-control"
                          />
                          <ErrorMessage
                            name="date"
                            component="div"
                            className="error"
                          />
                        </div>
                      </div>
                    </div>

                    <div className="row">
                      <div className="mb-3">
                        <label htmlFor="address">Address</label>
                        <Field
                          as="textarea"
                          id="address"
                          name="address"
                          className="form-control"
                        />
                        <ErrorMessage
                          name="address"
                          component="div"
                          className="error"
                        />
                      </div>
                    </div>
                  </div>
                  <div className="modal-footer">
                    <a
                      className="btn ouline-secondary"
                      data-bs-dismiss="modal"
                      id="closeEditModalBtnUser"
                      onClick={() => {
                        setCoverImg("");
                        setShowModal(false);
                        resetForm();
                        setIsCoverPicChanged(false);
                      }}
                    >
                      Cancel
                    </a>
                    <button
                      disabled={isSubmitting}
                      type="submit"
                      className="btn btn-primary ms-auto text-white"
                    >
                      {isEdit ? "Update User" : "Create New User"}
                    </button>
                  </div>
                </Form>
              )}
            </Formik>
          )}
        </div>
      </div>
    </div>
  );
}

export default UserForm;
