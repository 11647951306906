// icons svg images
import collectionsvg from "../assets/icons/collectionsvg.svg";

import alert from "../assets/icons/alert.svg";
import bell from "../assets/icons/bell.svg";
import bills from "../assets/icons/bills.svg";
import budget from "../assets/icons/budget.svg";
import card from "../assets/icons/card.svg";
import check from "../assets/icons/check.svg";
import empty_check from "../assets/icons/empty_check.svg";
import gears from "../assets/icons/gears.svg";
import home from "../assets/icons/home.svg";
import menu from "../assets/icons/menu.svg";
import plane from "../assets/icons/plane.svg";
import plus from "../assets/icons/plus.svg";
import report from "../assets/icons/report.svg";
import search from "../assets/icons/search.svg";
import user from "../assets/icons/user.svg";
import wallet from "../assets/icons/wallet.svg";
import wealth from "../assets/icons/wealth.svg";
import smartMeter from "../assets/icons/sm.jpeg";
import newuserprofile from "../assets/icons/newuserprofile.svg";
import vendornew from "../assets/icons/vendornew.svg";
import techniciannew from "../assets/icons/techniciannew.svg";
import productionnew from "../assets/icons/productionnew.svg";
import warehousenew from "../assets/icons/warehousenew.svg";
import userplus from "../assets/icons/userplus.svg";
import supervisornew from "../assets/icons/supervisornew.svg";
import mastersvg from "../assets/icons/mastersvg.svg";
import labsvg from "../assets/icons/labsvg.svg";








// person images
import person_one from "../assets/images/person_one.jpg";
import person_two from "../assets/images/person_two.jpg";
import person_three from "../assets/images/person_three.jpg";
import person_four from "../assets/images/person_four.jpg";
import logo from "../assets/images/logo.png";
import blank from "../assets/illustrations/undraw_printing_invoice.svg";
import newgears from "../assets/icons/newgears.svg";
import edit from "../assets/icons/edit.svg";
import deleteIcon from "../assets/icons/trash.svg";
import xls from "../assets/icons/xls.svg";
import analytics from "../assets/icons/analytics.svg";


export const iconsImgs = {
    smartMeter,newgears, analytics,xls, deleteIcon, edit, alert, bell, 
    bills, budget, card, check, empty_check, gears, home, menu, plane, 
    plus, report, search, user, wallet, wealth,newuserprofile, vendornew, 
    techniciannew, supervisornew, warehousenew, productionnew,userplus,collectionsvg,
    mastersvg, labsvg
}

export const personsImgs = {
    person_one, person_two, person_three, person_four,logo
};
export const Imgs = {
    blank
};

