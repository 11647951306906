import React, { useEffect } from 'react'
import { useSelector } from 'react-redux';
import { Outlet, useLocation, useNavigate, useParams } from 'react-router-dom'

function AuthPage() {
  const navigate = useNavigate();
  const {pathname} = useLocation()
  let { id } = useParams();
  const {isLoginSuccessful} = useSelector((state) => state.logInUser);
  
   useEffect(() => {
    const isTokenAvailable=localStorage.getItem("innvelToken");
    if(isTokenAvailable){
      navigate("/")

    }
    return () => {

    }
  }, [id,isLoginSuccessful])

  useEffect(() => {

    const isTokenAvailable=localStorage.getItem("innvelToken");
    if(!isTokenAvailable){
    navigate("/auth")
    }
    return () => {

    }
  }, [id])
  
  return (
    <div>
        <Outlet/>
    </div>
  )
}

export default AuthPage