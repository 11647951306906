import instance from "../../api/axiosInstance";
import { createFormData } from "../../utils/common";


export const fetchGlobalSettingDetails=()=>{
    return instance.get("globalvariable/get_all")
} 
export const saveGlobalSettingDetails=(payload)=>{
    let _payload=createFormData(payload)
    return instance.post("globalvariable/save",_payload)
} 
export const getGlobalSettingDetailsById=(payload)=>{
    return instance.post("globalvariable/getById",payload)
} 
export const deleteDetailsById=(payload)=>{
    let _payload=createFormData(payload)
    return instance.post("globalvariable/deleteList",_payload)
} 
export const editGlobalSettingDetails=(payload)=>{
    let _payload=createFormData(payload)
    return instance.post("globalvariable/editList",_payload)
} 

export const getGlobalVeriablesByTypeDetails=(payload)=>{
    let _payload=createFormData(payload)
    return instance.post("globalvariable/getByType",_payload)
} 