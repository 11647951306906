import React from 'react';
import * as XLSX from 'xlsx';
import { iconsImgs } from '../utils/images';
import {saveAs} from "file-saver";
const JSONToExcel = ({ data, fileName }) => {
  const exportToExcel = () => {
    if (!data || !Array.isArray(data)) {
      console.error('Invalid data format. Expected an array.');
      return;
    }

    if (!fileName) {
      console.error('Invalid file name.');
      return;
    }

  
    const worksheet = XLSX.utils.json_to_sheet(data);

        // Set header color to blue
        const headerCell = worksheet['A1'];
        headerCell.s = {
          fill: {
            bgColor: { rgb: '0000FF' },
          },
        };
        const headerStyle = {
          fill: { fgColor: { rgb: '0000FF' } },
          font: { color: { rgb: 'FFFFFF' }, bold: true },
        };
        const headerRange = XLSX.utils.decode_range(worksheet['!ref']);
        for (let col = headerRange.s.c; col <= headerRange.e.c; ++col) {
          const cellAddress = XLSX.utils.encode_cell({ r: 0, c: col });
          worksheet[cellAddress].s = headerStyle;
        }
    
        const workbook = XLSX.utils.book_new();
        const columnWidths = [{ wpx: 200 }, { wpx: 200 }, { wpx: 200 }];
        worksheet['!cols'] = columnWidths;
        XLSX.utils.book_append_sheet(workbook, worksheet, 'Sheet1');
    
    
        const excelBuffer = XLSX.write(workbook, {
          bookType: 'xlsx',
          type: 'buffer',
        });
    
     
        saveAs(new Blob([excelBuffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' }), fileName);
     
      
  };

  return (
    <a onClick={exportToExcel} className="btn w-25  mx-2 btn-icon text-success">
      <img src={iconsImgs.xls} width={20} height={20} alt="xls" />
    </a>
  );
};

export default JSONToExcel;
