import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { deleteDetailsById, editVendorDetails, fetchVendorDetails, saveVendorDetails } from "./vendor.api";


const initialState={
    loading:false,
    error:"",
    vendors: [],
    isDeletedSuccessful:false,
    isSavedSuccessful:false,
    filterVendorListData:[]
    

   }
export const fetchVendors=createAsyncThunk(
     "vendors/fetchVendors",()=>fetchVendorDetails()
   );

export const saveVendor=createAsyncThunk(
    "vendors/saveVendors",(data)=>saveVendorDetails(data)
  );
  export const deleteVendor=createAsyncThunk(
    "vendors/deleteVendors",(id)=>deleteDetailsById(id)
  )

  export const editVendor=createAsyncThunk(
    "vendors/editVendors",(id)=>editVendorDetails(id)
  )


const vendorSlice=createSlice({
name:'vendor',
initialState,
reducers:{
  setVendorFilter:(state,action)=>{
      state.vendors= action.payload;
  },

},
extraReducers: (builder) => {
  builder
    .addCase(fetchVendors.pending,(state)=>{
      state.loading=true;
      state.error="";
    })
    .addCase(fetchVendors.fulfilled,(state,action)=> {
      state.error = "";
        state.loading = false;
        state.isDeletedSuccessful=false;
        state.isSavedSuccessful=false;
        state.vendors = action.payload?.data.data;
        state.filterVendorListData=action.payload?.data.data;
    })
    .addCase(fetchVendors.rejected, (state, action) =>{
      state.loading = false;
      state.error = action.error.message || "Something went wrong!";
    })  
//Save vendor
   .addCase(saveVendor.pending,(state)=>{
        state.loading=true;
        state.error="";
      })
      .addCase(saveVendor.fulfilled,(state,action)=> {
          state.loading = false;
          state.isSavedSuccessful=true;
         
      })
      .addCase(saveVendor.rejected, (state, action) =>{
        state.loading = false;
        state.error = action.error.message || "Something went wrong!";
      }) 
//Delete Vendor
      .addCase(deleteVendor.pending,(state)=>{
        state.loading=true;
        state.error="";
      })
      .addCase(deleteVendor.fulfilled,(state,action)=> {
          state.loading = false;
          state.isDeletedSuccessful=true;
         
      })
      .addCase(deleteVendor.rejected, (state, action) =>{
        state.loading = false;
        state.error = action.error.message || "Something went wrong!";
      }) 
      //Edit Vendor
      .addCase(editVendor.pending,(state)=>{
        state.loading=true;
        state.error="";
      })
      .addCase(editVendor.fulfilled,(state,action)=> {
          state.loading = false;
          state.isSavedSuccessful=true;
         
      })
      .addCase(editVendor.rejected, (state, action) =>{
        state.loading = false;
        state.error = action.error.message || "Something went wrong!";
      }) 
}
})
export default vendorSlice.reducer;
export const {setVendorFilter } = vendorSlice.actions
