import instance from "../../api/axiosInstance";
import { createFormData } from "../../utils/common";


export const fetchMetersDetails=(data)=>{

    let _payload=createFormData({limit:data.itemsPerPage,offset:data.currentPage})
    return instance.post("meter/getmeterdatafordisplay",_payload)
} 
export const saveMetersDetails=(payload)=>{
    let _payload=createFormData(payload)
    return instance.post("meter/save",_payload)
} 
export const getMeterDetailsById=(payload)=>{
    return instance.post("meter/getById",payload)
} 
export const deleteMeterDetailsById=(payload)=>{
    let _payload=createFormData(payload)
    return instance.post("meter/deleteList",_payload)
} 
export const editMetersDetails=(payload)=>{
    let _payload=createFormData(payload)
    return instance.post("meter/editList",_payload)
} 
export const bulkUpload=(_payload)=>{
    // let _payload = [];
    // for (let key in payload) {
    //   _payload[key] = payload[key];
    // }

    // const config = {
    //     headers: {
    //       'Content-Type': 'application/x-www-form-urlencoded'
    //     }
    //   };
    // let _payload=createFormData(payload)
    return instance.post("meter/savebulk",{payload:_payload})
} 
export const getDiscomoperationsdatafordisplay=(data)=>{
    let _payload=createFormData({discomlab:data.discomlabid.discomlabid,limit:data.itemsPerPage,offset:data.currentPage})
    return instance.post("discomoperations/getDiscomoperationsdatafordisplay",_payload)
} 

export const getMeterTrackById=(meterId)=>{
    let _payload=createFormData({meter:meterId})
    return instance.post("meter/trackmeter",_payload)
} 

export const getMeterListSearch=(data)=>{
    let _payload=createFormData({limit:data.itemsPerPage,offset:data.currentPage,number:data.meterNumber})
    return instance.post("meter/getmeterdatafordisplaysearch",_payload)
} 

export const getWarehouseStock=(id)=>{
    let _payload=createFormData({warehouse:id})
    return instance.post("meter/getwarehousestock",_payload)
}


export const discomBulkUpload=(_payload)=>{
    
    return instance.post("discomoperations/savebulk",{payload:_payload})
} 

export const getMeterDataAsPerStage=(data)=>{
    let _payload=createFormData({stage:data.targetid,limit:data.itemsPerPage,offset:data.currentPage})
    return instance.post("meter/getmeterdataasperstage",_payload)
}

export const getStatusWiseMeter=()=>{
    return instance.get("meter/getstatuswisemeter")
} 



