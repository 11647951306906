import { configureStore } from '@reduxjs/toolkit'
import vendorSlice from '../features/vendor/vendorSlice'
import warehouseSlice from '../features/warehouse/warehouseSlice'
import discomlabSlice from '../features/discomLab/discomlabSlice'

import userSlice from '../features/users/userSlice'
import globalSettingSlice from '../features/setting/globalSetting.slice'
import technicianSlice from '../features/technician/technicianSlice'
import oemSlice from '../features/OEM/oemSlice'
import supervisorSlice from '../features/supervisor/supervisorSlice'
import meterSlice from '../features/meter/meterSlice'
import consumerSlice from '../features/consumers/consumerSlice'
import logInUserSlice from "../features/auth/authSlice"
export const store = configureStore({
  reducer: {
    vendor: vendorSlice,
    warehouse:warehouseSlice,
    discomlab:discomlabSlice,
    users:userSlice,
    globalSetting:globalSettingSlice,
    technician:technicianSlice,
    oem:oemSlice,
    supervisors:supervisorSlice,
    meters:meterSlice,
    consumers:consumerSlice,
    logInUser:logInUserSlice
  },
})