import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { deleteDetailsById, editDiscomlabDetails, fetchDiscomlabDetails, saveDiscomlabDetails } from "./discomlab.api";

const initialState={
    loading:false,
    error:"",
    discomlab: [],
    isDeletedSuccessful:false,
    isSavedSuccessful:false,
    filterDiscomlabListData:[]
}

export const fetchdiscomlabs=createAsyncThunk(
    "discomlab/fetchdiscomlabs",()=>fetchDiscomlabDetails()
  );

export const savediscomlab=createAsyncThunk(
   "discomlab/savediscomlabs",(data)=>saveDiscomlabDetails(data)
 );
 export const deletediscomlab=createAsyncThunk(
   "discomlab/deletediscomlabs",(id)=>deleteDetailsById(id)
 )

 export const editdiscomlab=createAsyncThunk(
   "discomlab/editdiscomlabs",(id)=>editDiscomlabDetails(id)
 )

 const discomlabSlice=createSlice({
    name:'discomlab',
    initialState,
    reducers:{
      setDiscomlabFilter:(state,action)=>{
          state.discomlab= action.payload;
      },
    
    },
    extraReducers: (builder) => {
      builder
        .addCase(fetchdiscomlabs.pending,(state)=>{
          state.loading=true;
          state.error="";
        })
        .addCase(fetchdiscomlabs.fulfilled,(state,action)=> {
          state.error = "";
            state.loading = false;
            state.isDeletedSuccessful=false;
            state.isSavedSuccessful=false;
            state.discomlab = action.payload?.data.data;
            state.filterDiscomlabListData=action.payload?.data.data;
        })
        .addCase(fetchdiscomlabs.rejected, (state, action) =>{
          state.loading = false;
          state.error = action.error.message || "Something went wrong!";
        })  
    //Save discomlab
       .addCase(savediscomlab.pending,(state)=>{
            state.loading=true;
            state.error="";
          })
          .addCase(savediscomlab.fulfilled,(state,action)=> {
              state.loading = false;
              state.isSavedSuccessful=true;
             
          })
          .addCase(savediscomlab.rejected, (state, action) =>{
            state.loading = false;
            state.error = action.error.message || "Something went wrong!";
          }) 
    //Delete discomlab
          .addCase(deletediscomlab.pending,(state)=>{
            state.loading=true;
            state.error="";
          })
          .addCase(deletediscomlab.fulfilled,(state,action)=> {
              state.loading = false;
              state.isDeletedSuccessful=true;
             
          })
          .addCase(deletediscomlab.rejected, (state, action) =>{
            state.loading = false;
            state.error = action.error.message || "Something went wrong!";
          }) 
          //Edit discomlab
          .addCase(editdiscomlab.pending,(state)=>{
            state.loading=true;
            state.error="";
          })
          .addCase(editdiscomlab.fulfilled,(state,action)=> {
              state.loading = false;
              state.isSavedSuccessful=true;
             
          })
          .addCase(editdiscomlab.rejected, (state, action) =>{
            state.loading = false;
            state.error = action.error.message || "Something went wrong!";
          }) 
    }
    })
    export default discomlabSlice.reducer;
    export const {setDiscomlabFilter } = discomlabSlice.actions