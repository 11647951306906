import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { iconsImgs } from "../../utils/images";
import JSONToExcel from "../../components/JsonToExcel";
import ShimmerUITable from "../../components/shimmerUi/ShimmerTable";
import DeleteDialog from "../../components/DeleteDialog";
import MeterForm from "./MeterForm";
import Select from "react-select";

import {
  bulkUploadMeter,
  deleteMeter,
  fetchMeter,
  getMeterListBySearch,
  setMeterFilter,
  getMeterDataAsPerStageAPI,
  getStatusWiseMeterAPI,
} from "./meterSlice";
import { errorMessage, successMessage } from "../../utils/tostify";
import { debounce } from "../../utils/common";
import { ActiveInactive, ActiveInactiveID } from "../../data/dropdown";
import TimeLine from "../../components/TimeLine";

import ExcelToJsonConverter from "../../components/ExcelToJsonConverter";
import {
  deleteGlobalSetting,
  editGlobalSetting,
  fetchGlobalSetting,
  saveGlobalSetting,
} from "../setting/globalSetting.slice";
import {
  MDBBtn,
  MDBModal,
  MDBModalDialog,
  MDBModalContent,
  MDBModalHeader,
  MDBModalTitle,
  MDBModalBody,
  MDBModalFooter,
  MDBIcon,
} from "mdb-react-ui-kit";
import { fetchOEM } from "../OEM/oemSlice";
import { fetchwarehouses } from "../warehouse/warehouseSlice";
import MaterialOutwardMovement from "../../components/MaterialOutwardMovement";
import discomlabSlice from "../discomLab/discomlabSlice";
import BarChart from './chartComponents/BarChart';
import Styles from "./Meter.module.css";



const Meter = () => {
  const [basicModal, setBasicModal] = useState(false);
  const toggleOpenTracking = () => setBasicModal(!basicModal);
  const [searchText, setSearchText] = useState("");

  const itemsPerPageList = [
    { id: 1, name: "10", value: 10 },
    { id: 2, name: "15", value: 15 },
    { id: 3, name: "20", value: 20 },
  ];
  const [itemsPerPage, setItemPerPage] = useState(itemsPerPageList[0].value);

  const meterStageList = [
    // { id: 1, name: "Select Stage", value: null },
    // { id: 1, name: "Received", value: 10 },
    // { id: 2, name: "Rejected", value: 15 },
    // { id: 3, name: "20", value: 20 },
  ];
  const [meterStages, setMeterStages] = useState(meterStageList[0]);
  const {
    globalSetting: globalSettingList,

    filterGlobalSettingListData,
  } = useSelector((state) => state.globalSetting);
  useEffect(() => {
    if(filterGlobalSettingListData.length>0) return
      dispatch(fetchGlobalSetting());
  }, filterGlobalSettingListData);

  const [centredModal, setCentredModal] = useState(false);
  const [meterId,setMeterId]=useState(null);
  const [meterNumber,setMeterNumber]=useState(null)
  const closeModal = () => {
    const button = document.getElementById("closeEditModalBtnMeterUpload");
    button.click();
  };
  const toggleOpen = () => {
    setCentredModal(!centredModal);
    setOEMValue("");
    setWarehouseValue("");
    setJSONData([])
  };
  const inputRef = useRef();
  const dispatch = useDispatch();
  const [isEdit, setIsEdit] = useState(false);
  const [editItemDetails, setEditItemDetails] = useState({});
  const [deletedId, setDeletedId] = useState(null);
  const [JSONData, setJSONData] = useState([]);
  let {
    meter: meterList,
    loading,
    isDeletedSuccessful,
    isSavedSuccessful,
    filterMeterListData,
    error,
    meterByStageList,
    statusWiseMeterCount
  } = useSelector((state) => state.meters);
  const [showModal, setShowModal] = useState(false);
  let message = isEdit
    ? "Meter Updated Successfully"
    : "Meter Added Successfully";
  const [currentPage, setCurrentPage] = useState(1);
  const { dropdownList } = useSelector((state) => state.globalSetting);
  useEffect(() => {
    if (isDeletedSuccessful) {
      dispatch(fetchMeter({ itemsPerPage, currentPage }));
      successMessage("Meter Deleted Successfully");
    } else if (isSavedSuccessful) {
      dispatch(fetchMeter({ itemsPerPage, currentPage }));
      successMessage(message);
      setCentredModal(false);
    }
  }, [isDeletedSuccessful, isSavedSuccessful]);


  const getInitialCall=()=>{
    dispatch(fetchMeter({ itemsPerPage, currentPage }));
    dispatch(getStatusWiseMeterAPI());
  }

  useEffect(() => {
    getInitialCall();
  }, [itemsPerPage, currentPage]);

  const { OEM: oemList } = useSelector((state) => state.oem);
  const { warehouse: warehouseList } = useSelector((state) => state.warehouse);

  useEffect(() => {
    dispatch(fetchOEM());
    dispatch(fetchwarehouses());
  }, []);

  const getJsonData = (jsonData) => {
    const lowerCaseJsonData = jsonData.map((obj) => {
      const newObj = {};
      for (const key in obj) {
        newObj[key.toLowerCase()] = obj[key];
      }
      return newObj;
    });
    console.log(lowerCaseJsonData);
    setJSONData(lowerCaseJsonData);
  };

  useEffect(() => {
    if (error) {
      errorMessage(error);
    }
  }, [error]);

  const onDelete = (cb) => {
    dispatch(deleteMeter({ id: deletedId }));
    cb();
  };
  const [warehouseValue, setWarehouseValue] = useState("");
  const [OEMValue, setOEMValue] = useState("");

  let filteredOptions = [{
    colorcode: "",
    id: "0",
    name: "Select Stage",
    type: "119",
  }]
  filteredOptions =[...filteredOptions,  ...filterGlobalSettingListData.filter(item => {

    return item.type == 119;
  })]
 
  console.log(meterByStageList);

 

  return (
    <>
      <div>
        <div className="card">
          {/* Page Header */}
          <div className="card-header d-flex justify-content-between">
            <h3 className="card-title">Meter Master Table</h3>
            <div className="d-flex justify-content-between align-items-center">
            <div className="mx-3">    
            <MaterialOutwardMovement warehouseList={warehouseList} getFetchedData={getInitialCall}/></div>
      
              <div className="d-flex align-items-center cursor-pointer text-info mx-2">
                <MDBIcon size="2x" fas icon="file-upload" onClick={toggleOpen} />
              </div>
              {/* <div className="col-md-4">
                <a
                  className="btn btn-bitbucket text-white"
                  data-bs-toggle="modal"
                  data-bs-target="#meterFormModal"
                  onClick={(e) => {
                    setShowModal(true);
                    setEditItemDetails({
                      status: "",
                      stage: "",
                      oem: "",
                      number: "",
                      capacity: "",
                      nicno: "",
                      seal1: "",
                      seal2: "",
                      seal3: "",
                      seal4: "",
                      seal5: "",
                      seal6: "",
                      seal7: "",
                    });
                    setIsEdit(false);
                  }}
                >
                  <img src={iconsImgs.plus} width={20} height={20} alt="Add" />
                  Add
                </a>
              </div> */}

              <div className="mx-3">
                <JSONToExcel data={meterList} fileName="meter" />
              </div>
            </div>
          </div>

          <div className="card-body border-bottom py-3">
          
          
            <div className="d-flex row">
              <div className="text-muted col-5">
                Show
                <div className="mx-2 d-inline-block">
                  <select
                    className="form-control "
                    onChange={(e) => setItemPerPage(e.target.value)}
                  >
                    {itemsPerPageList.map((item, i) => (
                      <option
                        className="dropdown-item cursor-pointer"
                        value={item.value}
                      >
                        {item.name}
                      </option>
                    ))}
                  </select>
                </div>
                entries
              </div>
              <div className="text-muted col-2">
                    <div className="mx-2 d-inline-block">
                      <select
                        className="form-control "
                        onChange={(e) => {
                          // callGetMeterDataAsPerStage()
                          let target= filteredOptions.filter((x)=>x.name==e.target.value);
                          let targetid=0;
                          if(target.length){
                            targetid=target[0].id;
                            if(targetid!=0){
                              dispatch(
                                getMeterDataAsPerStageAPI({
                                  targetid,
                                  itemsPerPage,
                                  currentPage
                                })
                              );
                            }else{
                              dispatch(fetchMeter({ itemsPerPage, currentPage }));

                              
                            }

                          }else{
                            errorMessage("No Meters Found. Initial Table Loaded.");
                            dispatch(fetchMeter({ itemsPerPage, currentPage }));
                           
                            


                          }
                          
                          
                          
                        }}
                      >
                        {filteredOptions.map((item, i) => (
                          <option
                            className="dropdown-item cursor-pointer"
                            value={item.value}
                            
                          >
                            {item.name}
                          </option>
                        ))
                        }
                      </select>
                    </div>
                    
              </div>

              <div className="ms-auto text-muted col-5">
                {/* <SearchBox
                  onChange={(e) => {
                    debouncedFilterData(e.target.value, ["stage"]);
                  }}
                /> */}
                <div className="mb-3">
              
                  <div className="row g-2">

            
                
                    <div className="col">
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Search for meter no…"
                        onChange={(e) => setSearchText(e.target.value)}
                      />
                    </div>
                    <div className="col-auto">
                      <a
                        className="btn btn-icon"
                        aria-label="Button"
                        onClick={() => {
                          dispatch(
                            getMeterListBySearch({
                              itemsPerPage,
                              currentPage,
                              meterNumber: searchText,
                            })
                          );
                        }}
                      >
                        <MDBIcon fas icon="search" size="1x" />
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            

            {/* Top pagination */}
            {/* <div className=" d-flex align-items-center justify-content-between">
              <div className="col-12">
                <div className="__card">
                  <div className="card-body">
                    <ul className="pagination ">
                      <li
                        className={`page-item page-prev ${
                          currentPage <= 1 ? "disabled" : ""
                        } `}
                      >
                        <a className="page-link">
                          <div
                            className="page-item-subtitle btn btn-outline-info"
                            onClick={() => setCurrentPage((prev) => prev - 1)}
                          >
                            previous
                          </div>
                        </a>
                      </li>

                      <li
                        className={`page-item page-next ${
                          itemsPerPage > meterList.length ? "disabled" : ""
                        } `}
                      >
                        <a className="page-link">
                          <div
                            className="page-item-subtitle btn btn-outline-info"
                            onClick={() => setCurrentPage((prev) => prev + 1)}
                          >
                            next
                          </div>
                          
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div> */}
            
          </div>
          {loading ? (
            <ShimmerUITable row={4} />
          ) : (
            <div className="table-responsive">
              <table className="table card-table table-vcenter text-nowrap datatable">
                <thead>
                  <tr>
                    <th className="w-1">Id.</th>
                    <th>Track</th>
                    <th>Stage</th>


                    <th>Meter Installation Date</th>
                    <th>Time</th>
                    <th>Type of Installation</th>
                    <th>Name Of Circle</th>
                    <th>Name Of Division</th>
                    <th>Name Of Town</th>
                    <th>Name Of Zone/Dc</th>
                    <th>Feeder</th>
                    <th>Capacity Of Dtr</th>
                    <th>Dtr Code</th>
                    <th>Pole No</th>
                    <th>Ivrs/Service No. Of Consumer</th>
                    <th>Name Of Consumer</th>
                    <th>Address</th>
                    <th>Mobile No.</th>
                    <th>Email Id</th>
                    <th>Category (Dl/Ci/Ip)</th>
                    <th>Sanction Load</th>
                    <th>Location Of Meter</th>
                    <th>Condition Of Service Cable</th>
                    <th>Lat</th>
                    <th>Long</th>
                    <th>Old Meter Reading</th>
                    <th>Old Meter Serial No.</th>
                    <th>Old Meter Make</th>
                    <th>Meter Status</th>
                    <th>Old return Meter Status</th>
                    <th>Old Meter Return Date</th>
                    <th>New Meter Type</th>
                    <th>New Meter Serial No.</th>
                    <th>New Meter Reading</th>
                    <th>Seal No 1</th>
                    <th>Seal No 2</th>
                    <th>Seal No 3</th>
                    <th>Seal No 4</th>
                    <th>Seal No 5</th>
                    <th>Seal No 6</th>
                    <th>Seal No 7</th>
                    <th>New Meter MF</th>
                    <th>New Meter ME CT</th>
                    <th>New Meter ME PT</th>
                    <th>New Meter CT</th>
                    <th>New Meter PT</th>
                    <th>Old Meter Lr</th>
                    <th>Nic Card Details</th>
                    <th>Name Of Technician</th>
                    <th>Technician Mobile No</th>
                    <th>Name Of Planner</th>
                    <th>Name Of Vendor</th>
                    <th>Name Of Supervisor</th>
                    <th>Approval Status</th>
                    <th>Remarks</th>
                    <th>Digital Mcr Type</th>
                    <th>NIC No.</th>
                    {/* <th>Status</th>*/}
                    <th>OEM</th>
                    <th>Number</th>
                    <th>Capacity Of Dtr</th>
                    
                    {/* <th>Meter Installation Date</th>
                    <th>Time</th>
                    <th>Type of Installation</th>
                    <th>Name Of Circle</th>
                    <th>Name Of Division</th>
                    <th>Name Of Town</th>
                    <th>Name Of Zone/Dc</th>
                    <th>Feeder</th>
                    <th>Old return Meter Status</th>
                    <th>Old Meter Return Date</th>
                    <th>New Meter Type</th>
                    <th>New Meter Serial No.</th>
                    <th>New Meter Reading</th>
                    <th>New Meter MF</th>
                    <th>New Meter ME CT</th>
                    <th>New Meter ME PT</th>
                    <th>New Meter CT</th>
                    <th>New Meter PT</th>
                    <th>Old Meter Lr</th>
                    <th>Nic Card Details</th>
                    <th>Name Of Technician</th>
                    <th>Technician Mobile No</th>
                    <th>Name Of Planner</th>
                    <th>Name Of Vendor</th>
                    <th>Name Of Supervisor</th>
                    <th>Approval Status</th>
                    <th>Remarks</th>
                    <th>Digital Mcr Type</th> */}
                  </tr>
                </thead>
                <tbody>
                  {meterList?.map((meter) => {
                    return (
                      <tr key={meter.id}>
                        
                        
                      
                        
                        
                        {/*  <td>
                          {ActiveInactiveID.active.includes(
                            Number(meter.status)
                          ) ? (
                            <span className="status-dot status-dot-animated  bg-success me-2"></span>
                          ) : (
                            <span className=" status-dot status-dot-animated  bg-danger me-2"></span>
                          )}

                          {ActiveInactive[meter.status] || "Others"}
                        </td>
                    
                          */}
                        <td>
                          <span className="text-muted">{meter.id}</span>
                        </td>
                        <td>
                          {" "}
                          <a  title='Tracking' className="btn  mx-1 btn-icon text-success" onClick={()=>{
                            setMeterId(meter.id)
                            setMeterNumber(meter.number);

                            toggleOpenTracking()
                            
                            }}>
                        <MDBIcon fas  icon="eye" style={{fontSize:20}}/>

                          </a>
                        </td>
                        <td>
                          <span className="badge bg-blue">{meter.stage}</span>
                        </td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td>{meter.metertype}</td>
                        <td></td>
                        <td></td>
                        <td>{meter.seal1}</td>
                        <td>{meter.seal2}</td>
                        <td>{meter.seal3}</td>
                        <td>{meter.seal4}</td>
                        <td>{meter.seal5}</td>
                        <td>{meter.seal6}</td>
                        <td>{meter.seal7 || "---"}</td>
                        <td>{meter.mf}</td>
                        <td>{meter.mect}</td>
                        <td>{meter.mept}</td>

                        <td>{meter.ct}</td>
                        <td>{meter.pt}</td>
                        <td>{meter.lr}</td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td>{meter.mcrtype}</td>
                        <td>
                          <span className="text-muted">{meter.nicno}</span>
                        </td>
                        {/* <td>Status</td>*/}
                       
                        <td>{meter.oem}</td>
                        <td>{meter.number}</td>
                        <td>{meter.capacity}</td>
                        

                        <td className="text-end">
                          {/* <a
                            className="btn w-25 text-white btn-icon mx-2 text-primary"
                            aria-label="Edit"
                            data-bs-toggle="modal"
                            data-bs-target="#meterFormModal"
                            title="Edit"
                            onClick={() => {
                              setEditItemDetails(meter);
                              setIsEdit(true);
                              setShowModal(true);
                            }}
                          >
                            <img
                              src={iconsImgs.edit}
                              width={20}
                              height={20}
                              alt="Edit"
                            />
                          </a> */}
                          <a
                            data-bs-toggle="modal"
                            data-bs-target="#meter-delete"
                            className="btn w-25 mx-2 btn-icon text-danger"
                            aria-label="Delete"
                            title="Delete"
                            onClick={() => {
                              setDeletedId(meter.id);
                            }}
                          >
                            <img
                              src={iconsImgs.deleteIcon}
                              width={20}
                              height={20}
                              alt="Delete"
                            />
                          </a>
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
              {meterList.length == 0 ? (
                <div className="alert alert-danger text-center my-4" role="alert">
                  No Data Found
                </div>
              ) : (
                ""
              )}
            </div>
          )}
          <div className="card-footer d-flex align-items-center justify-content-between">
            <div className="col-12">
              <div className="__card">
                <div className="card-body">
                  <ul className="pagination ">
                    <li
                      className={`page-item page-prev ${
                        currentPage <= 1 ? "disabled" : ""
                      } `}
                    >
                      <a className="page-link">
                        <div
                          className="page-item-subtitle btn btn-outline-info"
                          onClick={() => setCurrentPage((prev) => prev - 1)}
                        >
                          previous
                        </div>
                      </a>
                    </li>

                    <li
                      className={`page-item page-next ${
                        itemsPerPage > meterList.length ? "disabled" : ""
                      } `}
                    >
                      <a className="page-link">
                        <div
                          className="page-item-subtitle btn btn-outline-info"
                          onClick={() => setCurrentPage((prev) => prev + 1)}
                        >
                          next
                        </div>
                        {/* <div className="page-item-title w-25 my-2">
                          Page no {currentPage}
                        </div> */}
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>

        <DeleteDialog
          modalId="meter-delete"
          onDeleteCall={onDelete}
          closeId={"deleteNoButtonMeter"}
          message={
            "Are you sure you want to delete this item? This action cannot be undone."
          }
        />
        <MeterForm
          id="meterFormModal"
          editItemDetails={editItemDetails}
          isEdit={isEdit}
          setShowModal={setShowModal}
          showModal={showModal}
          dropdownList={dropdownList}
        />

        
        {/* Upload excel */}
        <MDBModal
          tabIndex="-1"
          open={centredModal}
          onClose={() => setCentredModal(false)}
        >
          <MDBModalDialog centered>
            <MDBModalContent>
              <MDBModalHeader>
                <MDBModalTitle>Upload excel</MDBModalTitle>
                <MDBBtn
                  className="btn-close"
                  color="none"
                  onClick={toggleOpen}
                ></MDBBtn>
              </MDBModalHeader>
              <MDBModalBody>
                <div className="mb-3">
                  <label className="form-label required">Warehouse</label>

                  <Select
                    placeholder={"select warehouse..."}
                    options={warehouseList.map((item) => ({
                      value: item.id,
                      label: `${item.name}`,
                    }))}
                    onChange={(option) => setWarehouseValue(option)}
                    value={warehouseValue}
                    isClearable={true}
                  />
                </div>

                <div className="mb-3">
                  <label className="form-label required">OEM</label>

                  <Select
                    placeholder={"select oem..."}
                    options={oemList.map((item) => ({
                      value: item.id,
                      label: `${item.name}`,
                    }))}
                    onChange={(option) => setOEMValue(option)}
                    value={OEMValue}
                    isClearable={true}
                  />
                </div>

                <button
                  className="btn mx-2 btn-outline-info"
                  disabled={!OEMValue || !warehouseValue}
                  onClick={(e) => {
                    
                    e.stopPropagation();
                    e.preventDefault();
                    inputRef.current.click();
                  }}
                >
                  Upload Excel
                </button>
                <div className="my-2">
                {JSONData.length>0 &&<span className="badge bg-green-lt"> xlsx's data fetch succesfully</span>}

                </div>
              </MDBModalBody>
              <MDBModalFooter>
                <MDBBtn color="secondary" onClick={toggleOpen}>
                  Close
                </MDBBtn>

                <button
                  className="btn btn-outline-primary"
                  disabled={JSONData.length==0}
                  onClick={() => {
              
                    if (JSONData.length > 0) {
                      JSONData.map((item) => {
                        item["warehouse"] = warehouseValue.value;
                        item["warehouseName"] = warehouseValue.label;
                        item["oem"] = OEMValue.value;
                        item["status"] = 1;
                        item["stage"] = 120;
                        item["date"]=new Date();
                      });
                    }

                    console.log(JSONData)
                    dispatch(bulkUploadMeter(JSONData));
                  
                  }}
                >
                  Upload
                </button>
              </MDBModalFooter>
            </MDBModalContent>
          </MDBModalDialog>
        </MDBModal>

        <ExcelToJsonConverter ref={inputRef} getJsonData={getJsonData} />

        <TimeLine id={meterId} number={meterNumber} basicModal={basicModal} setBasicModal={setBasicModal}/>
      </div>
      <div className={`${Styles.barChartStagewise} d-flex row card` }>
        <BarChart statusWiseMeterCount={statusWiseMeterCount}/>
      </div>
    </>
  );
};

export default Meter;
