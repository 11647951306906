import instance from "../../api/axiosInstance";
import { createFormData } from "../../utils/common";


export const fetchTechniciansDetails=()=>{
    return instance.get("technician/get_all")
} 
export const saveTechniciansDetails=(payload)=>{
    let _payload=createFormData(payload)
    return instance.post("technician/save",_payload)
} 
export const getTechnicianDetailsById=(payload)=>{
    return instance.post("technician/getById",payload)
} 
export const deleteTechnicianDetailsById=(payload)=>{
    let _payload=createFormData(payload)
    return instance.post("technician/deleteList",_payload)
} 
export const editTechniciansDetails=(payload)=>{
    let _payload=createFormData(payload)
    return instance.post("technician/editList",_payload)
} 

export const saveDocs=(payload)=>{
    let _payload=createFormData(payload)
     return instance.post("globalvariable/upload_file",_payload)
 } 
 



