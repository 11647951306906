import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import DeleteDialog from "../../components/DeleteDialog";
import { errorMessage, successMessage } from "../../utils/tostify";
import ShimmerUITable from "../../components/shimmerUi/ShimmerTable";
import { iconsImgs } from "../../utils/images";
import SearchBox from "../../components/SearchBox";
import { debounce } from "../../utils/common";
import JSONToExcel from "../../components/JsonToExcel";
import {
  deleteTechnician,
  fetchTechnicians,
  setTechnicianFilter,
} from "./technicianSlice";
import { ActiveInactive, ActiveInactiveID } from "../../data/dropdown";
import TechnicianListForm from "./TechnicianListForm";

const initialValues = {
  id: "",
  status: "",
  name: "",
  vendor: "",
  supervisor: "",
  email: "",
  mobile: "",
  imei: "",
  address: "",
};

function Technician() {
  const dispatch = useDispatch();
  const [deletedId, setDeletedId] = useState(null);
  const [editItemDetails, setEditItemDetails] = useState({});
  const [isEdit, setIsEdit] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const {
    technicians: technicianList,
    loading,
    isDeletedSuccessful,
    isSavedSuccessful,
    filterTechniciansListData,
    error,
  } = useSelector((state) => state.technician);

  let message = isEdit
    ? "User Updated Successfully"
    : "User Added Successfully";
  useEffect(() => {
    if (isDeletedSuccessful) {
      dispatch(fetchTechnicians());
      successMessage("User Deleted Successfully");
    } else if (isSavedSuccessful) {
      dispatch(fetchTechnicians());
      successMessage(message);
    }

    return () => {};
  }, [isDeletedSuccessful, isSavedSuccessful]);

  const { dropdownList } = useSelector((state) => state.globalSetting);

  useEffect(() => {
    dispatch(fetchTechnicians());
  }, []);


  useEffect(() => {

    if (error) {
      errorMessage(error);
    }
  }, [error]);



  const onDelete = (cb) => {

    dispatch(deleteTechnician({ id: deletedId }));
    cb();
  };

  const filterData = (query, keys) => {
    const filteredData = filterTechniciansListData.filter((item) => {
      return keys.some((key) =>
        item[key].toLowerCase().includes(query.toLowerCase())
      );
    });
    dispatch(setTechnicianFilter(filteredData));
    console.log(filteredData);
  };

  const debouncedFilterData = debounce(filterData, 800);
  return (
    <div>
      <div className="card">
        {/* Page Header */}
        <div className="card-header d-flex justify-content-between">
          <h3 className="card-title">Technician Master Table</h3>
          <div>
            <a
              className="btn btn-bitbucket text-white"
              data-bs-toggle="modal"
              data-bs-target="#technicianFormModal"
              onClick={(e) => {
                setShowModal(true);
                setEditItemDetails(initialValues);
                setIsEdit(false);
              }}
            >
              <img src={iconsImgs.plus} width={20} height={20} alt="Add" />
              Add
            </a>

            <JSONToExcel data={technicianList} fileName="technician" />
          </div>
        </div>

        <div className="card-body border-bottom py-3">
          <div className="d-flex">
            <div className="text-muted">
              Show
              <div className="mx-2 d-inline-block">
                <select className="form-control ">
                  <option className="dropdown-item">5</option>
                  <option className="dropdown-item">10</option>
                  <option className="dropdown-item">25</option>
                </select>
              </div>
              entries
            </div>
            <div className="ms-auto text-muted">
              <SearchBox
                onChange={(e) => {
                  debouncedFilterData(e.target.value, ["first_name"]);
                }}
              />
            </div>
          </div>
        </div>
        {loading ? (
          <ShimmerUITable row={4} />
        ) : (
          <div className="table-responsive">
            <table className="table card-table table-vcenter text-nowrap datatable">
              <thead>
                <tr>
                  <th className="w-1">Id.</th>
                  <th>Name</th>
                  <th>Status</th>
                  <th>Vendor</th>

                  <th>Email</th>

                  <th>Mobile</th>

                  <th>IMEI</th>

                  <th>Address</th>

                  <th></th>
                </tr>
              </thead>
              <tbody>
                {technicianList?.map((technician) => {
                  return (
                    <tr key={technician.id}>
                      <td>
                        <span className="text-muted">{technician.id}</span>
                      </td>

                      <td>{technician.name}</td>
                      <td>
                        {ActiveInactiveID.active.includes(Number(technician.status)) ? (
                          <span className="status-dot status-dot-animated  bg-success me-2"></span>
                        ) : (
                          <span className=" status-dot status-dot-animated  bg-danger me-2"></span>
                        )}

                        {ActiveInactive[technician.status] || "Others"}
                      </td>

                      <td>{technician.vendor}</td>
                      <td>{technician.email}</td>

                      <td>{technician.mobile}</td>

                      <td>{technician.imei}</td>
                      <td>{technician.address}</td>

                      <td className="text-end">
                        <a
                          className="btn  w-25 text-white  btn-icon mx-2 text-primary"
                          aria-label="Facebook"
                          data-bs-toggle="modal"
                          data-bs-target="#technicianFormModal"
                          title="Edit"
                          onClick={() => {
                            setEditItemDetails(technician);
                            setIsEdit(true);
                            setShowModal(true);
                          }}
                        >
                          <img
                            src={iconsImgs.edit}
                            width={20}
                            height={20}
                            alt=""
                          />
                        </a>
                        <a
                          data-bs-toggle="modal"
                          data-bs-target="#technician-delete"
                          className="btn w-25  mx-2 btn-icon text-danger"
                          aria-label="delete"
                          title="Delete"
                          onClick={() => {
                            setDeletedId(technician.id);
                          }}
                        >
                          <img
                            color="red"
                            src={iconsImgs.deleteIcon}
                            width={20}
                            height={20}
                            alt=""
                          />
                        </a>
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
        )}
        <div className="card-footer d-flex align-items-center">
          <p className="m-0 text-muted">
            Showing <span>1</span> to <span>8</span> of{" "}
            <span>{technicianList?.length}</span> entries
          </p>
          <ul className="pagination m-0 ms-auto text-white">
            <li className="page-item disabled">
              <a
                className="page-link"
                href="#"
                tabIndex="-1"
                aria-disabled="true"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="icon"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  strokeWidth="2"
                  stroke="currentColor"
                  fill="none"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                >
                  <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                  <path d="M15 6l-6 6l6 6" />
                </svg>
                prev
              </a>
            </li>
            <li className="page-item">
              <a className="page-link " href="#">
                1
              </a>
            </li>
            <li className="page-item active">
              <a className="page-link text-white" href="#">
                2
              </a>
            </li>
            <li className="page-item">
              <a className="page-link" href="#">
                3
              </a>
            </li>
            <li className="page-item">
              <a className="page-link" href="#">
                4
              </a>
            </li>
            <li className="page-item">
              <a className="page-link" href="#">
                5
              </a>
            </li>
            <li className="page-item">
              <a className="page-link">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="icon"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  strokeWidth="2"
                  stroke="currentColor"
                  fill="none"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                >
                  <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                  <path d="M9 6l6 6l-6 6" />
                </svg>
                next
              </a>
            </li>
          </ul>
        </div>
      </div>
      <DeleteDialog
        modalId="technician-delete"
        onDeleteCall={onDelete}
        closeId={"deleteNoButtonUser"}
        message={
          "Are you sure you want to delete this item? This action cannot be undone."
        }
      />
      <TechnicianListForm
        id="technicianFormModal"
        editItemDetails={editItemDetails}
        isEdit={isEdit}
        setShowModal={setShowModal}
        showModal={showModal}
        dropdownList={dropdownList}
      />
    </div>
  );
}

export default Technician;
