// import React from "react";

// const Pagination = ({ totalItems, itemsPerPage, currentPage, onPageChange }) => {
//   const totalPages = Math.ceil(totalItems / itemsPerPage);

//   const handlePrevPage = () => {
//     if (currentPage > 1) {
//       onPageChange(currentPage - 1);
//     }
//   };

//   const handleNextPage = () => {
//     if (currentPage < totalPages) {
//       onPageChange(currentPage + 1);
//     }
//   };

//   const handlePageChange = (pageNumber) => {
//     onPageChange(pageNumber);
//   };

//   return (
//     <nav>
//       <ul className="pagination">
//         <li className={`page-item ${currentPage === 1 ? "disabled" : ""}`}>
//           <button className="page-link" onClick={handlePrevPage}>
//             Previous
//           </button>
//         </li>
//         {Array.from({ length: totalPages }, (_, i) => i + 1).map((page) => (
//           <li
//             key={page}
//             className={`page-item ${page === currentPage ? "active" : ""}`}
//           >
//             <button className="page-link" onClick={() => handlePageChange(page)}>
//               {page}
//             </button>
//           </li>
//         ))}
//         <li className={`page-item ${currentPage === totalPages ? "disabled" : ""}`}>
//           <button className="page-link" onClick={handleNextPage}>
//             Next
//           </button>
//         </li>
//       </ul>
//     </nav>
//   );
// };

// export default Pagination;


// ------------------------------------------------------------------------
// import React from "react";

// const Pagination = ({ totalItems, itemsPerPage, currentPage, onPageChange }) => {
//   const totalPages = Math.ceil(totalItems / itemsPerPage);
//   const pageRangeDisplayed = 10; // Number of page numbers to display in the pagination

//   const handlePrevPage = () => {
//     if (currentPage > 1) {
//       onPageChange(currentPage - 1);
//     }
//   };

//   const handleNextPage = () => {
//     if (currentPage < totalPages) {
//       onPageChange(currentPage + 1);
//     }
//   };

//   const handlePageChange = (pageNumber) => {
//     onPageChange(pageNumber);
//   };

//   // Calculate the start and end of the page range
//   const startPage = Math.max(1, currentPage - Math.floor(pageRangeDisplayed / 2));
//   const endPage = Math.min(totalPages, startPage + pageRangeDisplayed - 1);

//   return (
  
//       <ul className="pagination p-0 m-0">
//         <li className={`page-item ${currentPage === 1 ? "disabled" : ""}`}>
//           <button className="page-link" onClick={handlePrevPage}>
//           <svg
//                   xmlns="http://www.w3.org/2000/svg"
//                   className="icon"
//                   width="24"
//                   height="24"
//                   viewBox="0 0 24 24"
//                   strokeWidth="2"
//                   stroke="currentColor"
//                   fill="none"
//                   strokeLinecap="round"
//                   strokeLinejoin="round"
//                 >
//                   <path stroke="none" d="M0 0h24v24H0z" fill="none" />
//                   <path d="M15 6l-6 6l6 6" />
//                 </svg>
//             Previous
//           </button>
//         </li>
//         {Array.from({ length: endPage - startPage + 1 }, (_, i) => startPage + i).map((page) => (
//           <li
//             key={page}
//             className={`page-item ${page === currentPage ? "active" : ""}`}
//           >
//             <button className="page-link" onClick={() => handlePageChange(page)}>
//               {page}
//             </button>
//           </li>
//         ))}
//         <li className={`page-item ${currentPage === totalPages ? "disabled" : ""}`}>
//           <button className="page-link" onClick={handleNextPage}>
//             Next <svg
//                   xmlns="http://www.w3.org/2000/svg"
//                   className="icon"
//                   width="24"
//                   height="24"
//                   viewBox="0 0 24 24"
//                   strokeWidth="2"
//                   stroke="currentColor"
//                   fill="none"
//                   strokeLinecap="round"
//                   strokeLinejoin="round"
//                 >
//                   <path stroke="none" d="M0 0h24v24H0z" fill="none" />
//                   <path d="M9 6l6 6l-6 6" />
//                 </svg>
//           </button>
//         </li>
//       </ul>
 
//   );
// };

// export default Pagination;











const Pagination = ({ totalItems, itemsPerPage, currentPage, onPageChange }) => {
  function displayItems(currentPage, itemsPerPage) {
    const startIndex = (currentPage - 1) * itemsPerPage + 1;
    const endIndex = Math.min(currentPage * itemsPerPage, totalItems); // Ensure endIndex doesn't exceed totalItems
    return [startIndex, endIndex];
  }
    const totalPages = Math.ceil(totalItems / itemsPerPage);
    const pageRangeDisplayed = 5; // Number of page numbers to display in the pagination
    const [startIndex, endIndex] = displayItems(currentPage, itemsPerPage);

    const handlePrevPage = () => {
      if (currentPage > 1) {
        onPageChange(currentPage - 1);
      }
    };
  
    const handleNextPage = () => {
      if (currentPage < totalPages) {
        onPageChange(currentPage + 1);
      }
    };
  
    const handlePageChange = (pageNumber) => {
      onPageChange(pageNumber); // This is where onPageChange is called
    };
  
    // Calculate the start and end of the page range
    const startPage = Math.max(1, currentPage - Math.floor(pageRangeDisplayed / 2));
    const endPage = Math.min(totalPages, startPage + pageRangeDisplayed - 1);
  
    return (
      <div className="card-footer d-flex align-items-center justify-content-between">
          <p className="m-0 text-muted">
            Showing <span>{(startIndex)}</span> to <span>{endIndex}</span> of{" "}
            <span>{totalItems}</span> entries
          </p>
      <ul className="pagination p-0 m-0">
        <li className={`page-item mx-2 ${currentPage === 1 ? "disabled" : ""}`}>
          <button className="page-link" onClick={handlePrevPage}>
          <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="icon"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  strokeWidth="2"
                  stroke="currentColor"
                  fill="none"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                >
                  <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                  <path d="M15 6l-6 6l6 6" />
                </svg>
            
          </button>
        </li>
        {Array.from({ length: endPage - startPage + 1 }, (_, i) => startPage + i).map((page) => (
          <li
            key={page}
            className={`page-item  mx-1 ${page === currentPage ? "active" : ""}`}
          >
            <button className="page-link" onClick={() => handlePageChange(page)}> {/* This is where handlePageChange is used */}
              {page}
            </button>
          </li>
        ))}
        <li className={`page-item mx-2 ${currentPage === totalPages ? "disabled" : ""}`}>
          <button className="page-link" onClick={handleNextPage}>
          <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="icon"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  strokeWidth="2"
                  stroke="currentColor"
                  fill="none"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                >
                  <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                  <path d="M9 6l6 6l-6 6" />
                </svg>
          </button>
        </li>
      </ul>
      </div>
    );
  };
  
  export default Pagination;
  