import React from "react";

function DeleteDialog({ modalId, onDeleteCall, closeId, message }) {
  const closeModal = () => {
    const button = document.getElementById(closeId);
    button.click();
  };
  const onDelete = () => {
    onDeleteCall(closeModal);
  };

  return (
    <div
      className="modal modal-blur fade"
      id={modalId}
      tabIndex="-1"
      role="dialog"
      aria-hidden="true"
    >
      <div
        className="modal-dialog modal-sm modal-dialog-centered"
        role="document"
      >
        <div className="modal-content">
          <div className="modal-body">
            <div className="modal-title">Are you sure?</div>
            <div>{message}</div>
          </div>
          <div className="modal-footer">
            <button
              type="button"
              className="btn link-secondary me-auto"
              data-bs-dismiss="modal"
              id={closeId}
            >
              Cancel
            </button>
            <button
              type="button"
              className="btn btn-danger"
              onClick={() => onDelete()}
            >
              Yes, delete this item
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default DeleteDialog;
