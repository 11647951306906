import instance from "../../api/axiosInstance";
import { createFormData } from "../../utils/common";


export const fetchVendorDetails=()=>{
    return instance.get("vendor/getVendor")
} 
export const saveVendorDetails=(payload)=>{
    let _payload=createFormData(payload)
    return instance.post("vendor/save",_payload)
} 
export const getVendorDetailsById=(payload)=>{
    return instance.post("vendor/getById",payload)
} 
export const deleteDetailsById=(payload)=>{
    let _payload=createFormData(payload)
    return instance.post("vendor/deleteList",_payload)
} 
export const editVendorDetails=(payload)=>{
    let _payload=createFormData(payload)
    return instance.post("vendor/editList",_payload)
} 