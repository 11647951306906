import { iconsImgs } from "../utils/images";

export const navigationLinks = [
    { id: 1, title: 'Home', image: iconsImgs.home,to:"/" },
    { id: 2, title: 'Master', image: iconsImgs.mastersvg,
      children: [
        {id: 1 , title: 'Vendor', image: iconsImgs.vendornew, to:"../vendor"},
        {id: 2 , title: 'Warehouse', image: iconsImgs.warehousenew, to:"../warehouse"},
        {id: 3 , title: 'Discom Lab', image: iconsImgs.labsvg, to:"../discomlab"},

        // {id: 3 , title: 'Supervisor', image: iconsImgs.supervisornew, to:"../supervisor"},
        {id: 4 , title: 'Meters', image: iconsImgs.newgears, to:"../meters",},
        // {id: 5 , title: 'Technician', image: iconsImgs.techniciannew, to:"../technician"},
        {id: 6 , title: 'Consumers', image: iconsImgs.newuserprofile, to:"../consumers"},
        {id: 7 , title: 'OEM', image: iconsImgs.productionnew, to:"../OEM"},
      ]
    },
    { id: 4, title: 'Users', image: iconsImgs.user, 
      children: []
    },
    // { id: 3, title: 'Warehouse', image: iconsImgs.warehousenew,to:"/warehouse"},
    // { id: 7, title: 'Supervisor', image: iconsImgs.supervisornew,to:"/supervisor" },
    // { id: 8, title: 'Meters', image: iconsImgs.newgears,to:"/meters"},
    // { id: 5, title: 'Technician', image: iconsImgs.techniciannew,to:"/technician" },
    // { id: 9, title: 'Consumers', image: iconsImgs.newuserprofile,to:"/consumers" },
    // { id: 6, title: 'OEM', image: iconsImgs.productionnew,to:"/OEM" },
    { id: 10, title: 'Global Settings', image: iconsImgs.gears,to:"/setting" }
];


