import React, { useEffect, useState  } from 'react';
import Styles from "./Stocks.module.css";
import {
    MDBBtn,
    MDBModal,
    MDBModalDialog,
    MDBModalContent,
    MDBModalHeader,
    MDBModalTitle,
    MDBModalBody,
  } from 'mdb-react-ui-kit';
import { ShimmerFeaturedGallery } from 'react-shimmer-effects';
import { iconsImgs } from '../../../utils/images';
import ShimmerUITable from "../../../components/shimmerUi/ShimmerTable";
import Pagination from "../../../components/Pagination";
import { useDispatch, useSelector } from "react-redux";
import { ActiveInactive, ActiveInactiveID } from "../../../data/dropdown";
import TimeLine from "../../../components/TimeLine";
import { deleteMeter, fetchMeter, setMeterFilter,getDiscomoperationsdatafordisplayAPI } from "../../meter/meterSlice";








function Stocks(discomlabid){
  const itemsPerPage = 5;
  const [currentPage, setCurrentPage] = useState(1);



    const [basicModal, setBasicModal] = useState(false);
    const [showData, setShowData] = useState(false);
    const {
      meter: meterList,
      loading,
      discomlabStockList,
      isDeletedSuccessful,
      isSavedSuccessful,
      filterMeterListData,
      error,
    } = useSelector((state) => state.meters);
    const dispatch = useDispatch();
    const [isEdit, setIsEdit] = useState(false);
    const [editItemDetails, setEditItemDetails] = useState({});
    const [deletedId, setDeletedId] = useState(null);

    // const toggleOpen = () => setBasicModal(!basicModal);
    const toggleOpen = (e) => {
      dispatch(getDiscomoperationsdatafordisplayAPI({discomlabid,itemsPerPage,currentPage}))
      console.log(discomlabStockList);
      setBasicModal(!basicModal);
    };
    useEffect(()=>{
      setTimeout(() => {
        setShowData(true)
      }, 6000);
    },[])
    const [showModal, setShowModal] = useState(false);
    const handlePageChange = (pageNumber) => {
      setCurrentPage(pageNumber);
      const startIndex = (pageNumber - 1) * itemsPerPage;
      const endIndex = Math.min(
        startIndex + itemsPerPage,
        filterMeterListData.length
      );
      const filteredData = filterMeterListData.slice(startIndex, endIndex);
      dispatch(setMeterFilter(filteredData));
    };
    const getInitialCall=()=>{
      dispatch(getDiscomoperationsdatafordisplayAPI({ discomlabid,itemsPerPage, currentPage }));
    }
  
    useEffect(() => {
      getInitialCall();
    }, [itemsPerPage, currentPage]);


    
    return (
        <>
          <a  title='Stocks' className="btn w-25  mx-1 btn-icon text-success" onClick={toggleOpen}>
          <img src={iconsImgs.collectionsvg} width={20} height={20} alt="xls" />
          </a>
          <MDBModal open={basicModal} setOpen={setBasicModal} tabIndex='-1'>
            <MDBModalDialog centered size='fullscreen'>
                <MDBModalContent>
                    <MDBModalHeader>
                        <MDBModalTitle>Stocks</MDBModalTitle>
                        <MDBBtn className='btn-close' color='none' onClick={toggleOpen}></MDBBtn>
                    </MDBModalHeader>
                    <MDBModalBody>
                 
                      <div>
                        <div className="card">
                          {/* Page Header */}
                          <div className="card-header d-flex justify-content-between">
                            
                          </div>

                          <div className="card-body border-bottom py-3">
                            <div className="d-flex">
                              <div className="text-muted">
                                Show
                                <div className="mx-2 d-inline-block">
                                  <select className="form-control ">
                                    <option className="dropdown-item">5</option>
                                    <option className="dropdown-item">10</option>
                                    <option className="dropdown-item">25</option>
                                  </select>
                                </div>
                                entries
                              </div>
                              <div className="ms-auto text-muted">
                                {/* <SearchBox
                                  onChange={(e) => {
                                    debouncedFilterData(e.target.value, ["stage"]);
                                  }}
                                /> */}
                                <div className="mb-3">
                                              
                                                <div className="row g-2">
                                                  <div className="col">
                                                    <input type="text" className="form-control" placeholder="Search for meter no…"/>
                                                  </div>
                                                  <div className="col-auto">
                                                    <a href="#" className="btn btn-icon" aria-label="Button">
                                                      <svg xmlns="http://www.w3.org/2000/svg" className="icon" width="24" height="24" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round"><path stroke="none" d="M0 0h24v24H0z" fill="none"></path><path d="M10 10m-7 0a7 7 0 1 0 14 0a7 7 0 1 0 -14 0"></path><path d="M21 21l-6 -6"></path></svg>
                                                    </a>
                                                  </div>
                                                </div>
                                              </div>
                              </div>
                            </div>
                          </div>
                          {loading ? (
                            <ShimmerUITable row={4} />
                          ) : (
                            <div className="table-responsive">
                              <table className="table card-table table-vcenter text-nowrap datatable">
                                <thead>
                                  <tr>
                                    <th className="w-1">Id.</th>
                                    <th>NIC No.</th>
                                    <th>Status</th>
                                    <th>Track</th>
                                    <th>Stage</th>
                                    <th>OEM</th>
                                    <th>Number</th>
                                    <th>Capacity</th>
                                    <th>Seal 1</th>
                                    <th>Seal 2</th>
                                    <th>Seal 3</th>
                                    <th>Seal 4</th>
                                    <th>Seal 5</th>
                                    <th>Seal 6</th>
                                    <th>Seal 7</th>
                                  </tr>
                                </thead>
                                <tbody>
                                  {discomlabStockList?.map((meter) => {
                                    return (
                                      <tr key={meter.id}>
                                        <td>
                                          <span className="text-muted">{meter.id}</span>
                                        </td>
                                        <td>
                                          <span className="text-muted">{meter.nicno}</span>
                                        </td>
                                        <td>
                                        
                                          {ActiveInactiveID.active.includes(Number(meter.status)) ? (
                                            <span className="status-dot status-dot-animated  bg-success me-2"></span>
                                          ) : (
                                            <span className=" status-dot status-dot-animated  bg-danger me-2"></span>
                                          )}

                                          {ActiveInactive[meter.status] || "Others"}
                                      
                                        </td>
                                        <td>    <TimeLine/></td>

                                        <td>{meter.stage}</td>
                                        <td>{meter.oem}</td>
                                        <td>{meter.number}</td>
                                        <td>{meter.capacity}</td>
                                        <td>{meter.seal1}</td>
                                        <td>{meter.seal2}</td>
                                        <td>{meter.seal3}</td>
                                        <td>{meter.seal4}</td>
                                        <td>{meter.seal5}</td>
                                        <td>{meter.seal6}</td>
                                        <td>{meter.seal7}</td>

                                       
                                      </tr>
                                    );
                                  })}
                                </tbody>
                              </table>
                            </div>
                          )}
                          <div className="card-footer d-flex align-items-center justify-content-between">
                            <p className="m-0 text-muted">
                              Showing <span>1</span> to <span>8</span> of{" "}
                              <span>{discomlabStockList?.length}</span> entries
                            </p>
                            <Pagination
                              totalItems={filterMeterListData.length}
                              itemsPerPage={itemsPerPage}
                              currentPage={currentPage}
                              onPageChange={handlePageChange}
                            />
                          </div>
                        </div>
                      </div>
 

                    </MDBModalBody>
                </MDBModalContent>
            </MDBModalDialog>
          </MDBModal>
        </>
    )
}

export default Stocks;