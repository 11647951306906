import React, { useEffect, useState } from "react";
import { iconsImgs, personsImgs } from "../../utils/images";
import JSONToExcel from "../../components/JsonToExcel";
import SearchBox from "../../components/SearchBox";
import ShimmerUITable from "../../components/shimmerUi/ShimmerTable";
import { useDispatch, useSelector } from "react-redux";
import DeleteDialog from "../../components/DeleteDialog";
import { errorMessage, successMessage } from "../../utils/tostify";
import { debounce } from "../../utils/common";
import { deleteUser, fetchUsers, setUsersFilter } from "./userSlice";
import UserForm from "./UserForm";
import { useParams } from "react-router-dom";
import { setPageTitle } from "../setting/globalSetting.slice";
import { ActiveInactive, ActiveInactiveID, OptionsMaster } from "../../data/dropdown";
import Pagination from "../../components/Pagination";


const initialValues = {
  id: "",
  first_name: "",
  last_name: "",
  email: "",
  password: "",
  gender: "",
  usertype: "",
  status: "",
  cover: "",
  mobile: "",
  others: "",
  date: "",
  //company: "",
  imei:"",
  address: "",
};

function Users() {
  let { userType } = useParams();
  const dispatch = useDispatch();
  const [isEdit, setIsEdit] = useState(false);
  const [editItemDetails, setEditItemDetails] = useState({});
  const [deletedId, setDeletedId] = useState(null);
  const {
    users: userList,
    loading,
    isDeletedSuccessful,
    isSavedSuccessful,
    filterUsersListData,
    error,
  } = useSelector((state) => state.users);
  const {
    dropdownList,pageTitle
  } = useSelector((state) => state.globalSetting);
  const filterDataList = useSelector(
    (state) => state.users.filterUsersListData
  );
  const [showModal, setShowModal] = useState(false);
  let message = isEdit
    ? " Updated Successfully"
    : "Added Successfully";

    
  useEffect(() => {
    if (isDeletedSuccessful) {
      dispatch(fetchUsers(userType));
      successMessage("Deleted Successfully");
    } else if (isSavedSuccessful) {
      dispatch(fetchUsers(userType));
      successMessage(pageTitle+" "+ message);
    }

    return () => {};
  }, [isDeletedSuccessful, isSavedSuccessful]);

  const getName=(data,id)=>{
    return data.find((item)=>item.id==id)?.name;

  }


  useEffect(()=>{
    if(Object.keys(dropdownList).length>0 && userType){
      dispatch(setPageTitle(userType));
      dispatch(fetchUsers(userType));

    } 
  },[dropdownList,userType])
  useEffect(() => {
    console.log(error);
    if (error) {
      errorMessage(error);
    }
  }, [error]);
  const onDelete = (cb) => {
    dispatch(deleteUser({ id: deletedId }));
    cb();
  };
  // const filterData = (query, keys) => {
  //   const filteredData = filterUsersListData.filter((item) => {
  //     return keys.some((key) =>
  //       item[key].toLowerCase().includes(query.toLowerCase())
  //     );
  //   });
  //   dispatch(setUsersFilter(filteredData));
  //   console.log(filteredData);
  // };

    ///This code nedds to move (Table pagination)
    const filterData = (query, keys) => {
      if(query){
        const filteredData = filterDataList.filter((item) => {
          return keys.some((key) =>
            item[key].toLowerCase().includes(query.toLowerCase())
          );
        });
        dispatch(setUsersFilter(filteredData));
        console.log(filteredData);
      }
      else{
        handlePageChange(currentPage);
      }
     
    };
    const [itemsPerPage, setItemPerPage] = useState(5);
    const itemsPerPageList = [
      { id: 1, name: "5", value: 5 },
      { id: 2, name: "10", value: 10 },
      { id: 3, name: "15", value: 15 },
    ];
    const handlePageChange = (pageNumber) => {
      setCurrentPage(pageNumber);
      const startIndex = (pageNumber - 1) * itemsPerPage;
      const endIndex = Math.min(startIndex + itemsPerPage, filterDataList.length);
      let filteredData = filterDataList.slice(startIndex, endIndex);
      dispatch(setUsersFilter(filteredData));
    };
    useEffect(() => {
      handlePageChange(currentPage);
  
      return () => {};
    }, [itemsPerPage, filterDataList]);

  const debouncedFilterData = debounce(filterData, 800);
  const [currentPage, setCurrentPage] = useState(1);
  

  return (
    <div>
      <div className="card">
        {/* Page Header */}
        <div className="card-header d-flex justify-content-between">
          <h3 className="card-title">{pageTitle}</h3>
          <div>
            <a
              className="btn btn-bitbucket text-white"
              data-bs-toggle="modal"
              data-bs-target="#userFormModal"
              onClick={(e) => {
                setShowModal(true);
                initialValues.usertype=Number(userType);
                setEditItemDetails(initialValues);
                setIsEdit(false);
              }}
            >
              <img src={iconsImgs.plus} width={20} height={20} alt="Add" />
              Add
            </a>

            <JSONToExcel data={userList} fileName="user" />
          </div>
        </div>

        <div className="card-body border-bottom py-3">
          <div className="d-flex">
            <div className="text-muted">
              Show
              <div className="mx-2 d-inline-block">
                <select
                  className="form-control "
                  onChange={(e) => setItemPerPage(e.target.value)}
                >
                  {itemsPerPageList.map((item, i) => (
                    <option
                      className="dropdown-item cursor-pointer"
                      value={item.value}
                    >
                      {item.name}
                    </option>
                  ))}
                </select>
              </div>
              entries
            </div>
            <div className="ms-auto text-muted">
              <SearchBox
                onChange={(e) => {
                  debouncedFilterData(e.target.value, ["name"]);
                 
                }}
              />
            </div>
          </div>
        </div>
        {loading ? (
          <ShimmerUITable row={4} />
        ) : (
          <div className="table-responsive">
            <table className="table card-table table-vcenter text-nowrap datatable">
              <thead>
                <tr>
                  {/* <th className="w-1">Id.</th> */}
                  <th>Cover</th>
                  <th>Status</th>
                  <th>First Name</th>
                  <th>Last Name</th>
                  <th>Email</th>
                  <th>Gender</th>
                  <th>Mobile</th>
                  {/*<th>Company</th>*/}
                  <th>IMEI</th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                {userList?.map((user) => {
                  return (
                    <tr key={user.id}>
                      {/* <td>
                        <span className="text-muted">{user.id}</span>
                      </td> */}
                      <td>
                      <img src={`http://apiinnovel.apiitech.co.in/uploads/${user.cover}`}
                      onError={(e)=>e.target.src =personsImgs.person_three}
                      className="rounded-circle" style={{width:40}} alt="Avatar" />
                      
                      </td>
              
                      <td>
                        {ActiveInactiveID.active.includes(Number(user.status)) ? (
                          <span className="status-dot status-dot-animated  bg-success me-2"></span>
                        ) : (
                          <span className=" status-dot status-dot-animated  bg-danger me-2"></span>
                        )}

                        {ActiveInactive[user.status] || "Others"}
                      </td>

                      <td>{user.first_name}</td>
                      <td>{user.last_name}</td>
                      <td>{user.email}</td>

                      <td>{getName(dropdownList[OptionsMaster.gender],user.gender)}</td>
                      <td>{user.mobile}</td>
                      {/* <td>{user.company}</td> */}
                      <td>{user.imei}</td>

                      <td className="text-end">
                        <a
                          className="btn  w-25 text-white  btn-icon mx-2 text-primary"
                          aria-label="Facebook"
                          data-bs-toggle="modal"
                          data-bs-target="#userFormModal"
                          title="Edit"
                          onClick={() => {
                            setEditItemDetails(user);
                            setIsEdit(true);
                            setShowModal(true);
                          }}
                        >
                          <img
                            src={iconsImgs.edit}
                            width={20}
                            height={20}
                            alt=""
                          />
                        </a>
                        <a
                          data-bs-toggle="modal"
                          data-bs-target="#user-delete"
                          className="btn w-25  mx-2 btn-icon text-danger"
                          aria-label="delete"
                          title="Delete"
                          onClick={() => {
                            setDeletedId(user.id);
                          }}
                        >
                          <img
                            color="red"
                            src={iconsImgs.deleteIcon}
                            width={20}
                            height={20}
                            alt=""
                          />
                        </a>
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
        )}
   
          
          <Pagination
            totalItems={filterDataList?.length}
            itemsPerPage={itemsPerPage}
            currentPage={currentPage}
            onPageChange={handlePageChange}
          />
     
      </div>
      <DeleteDialog
        modalId="user-delete"
        onDeleteCall={onDelete}
        closeId={"deleteNoButtonUser"}
        message={
          "Are you sure you want to delete this item? This action cannot be undone."
        }
      />
      <UserForm
        id="userFormModal"
        editItemDetails={editItemDetails}
        isEdit={isEdit}
        setShowModal={setShowModal}
        showModal={showModal}
        dropdownList={dropdownList}
      />
    </div>
  );
}

export default Users;
