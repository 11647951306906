import ReactApexChart from "react-apexcharts";

function BarChart() {
    const series = [
        {
          name: "Meter Approved V/S Rejected",
          data: [60, 40],
        },
      ];
      
    const options = {
        chart: {
          height: 350,
          width: "100%",
          type: 'bar',
          zoom: {
            enabled: false,
          },
          animations: {
            enabled: true,
            easing: 'easein',
            speed: 1000,
            animateGradually: {
                enabled: true,
                delay: 150
            }
          },
        },
        plotOptions: {
          bar: {
            borderRadius: 10,
            dataLabels: {
              position: 'top', // top, center, bottom
            },
            distributed: true

          }
        },
        dataLabels: {
          enabled: true,
          formatter: function (val) {
            return val + "%";
          },
          offsetY: -20,
          style: {
            fontSize: '12px',
            colors: ["#304758"]
          }
        },
        xaxis: {
          categories: ["Approved", "Rejected"],
          position: 'bottom',
          axisBorder: {
            show: false
          },
          axisTicks: {
            show: false
          },
          crosshairs: {
            fill: {
              type: 'gradient',
              gradient: {
                colorFrom: '#D8E3F0',
                colorTo: '#BED1E6',
                stops: [0, 100],
                opacityFrom: 0.4,
                opacityTo: 0.5,
              }
            }
          },
          tooltip: {
            enabled: true,
          }
        },
        yaxis: {
          axisBorder: {
            show: false
          },
          axisTicks: {
            show: false,
          },
          labels: {
            show: true,
            formatter: function (val) {
              return val + "%";
            }
          }
        
        },
        
    }
      
  return (
    <div id="chart">
      <ReactApexChart options={options} series={series} type="bar" height={295} />
    </div>
  );
}

export default BarChart;
