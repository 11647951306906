import instance from "../../api/axiosInstance";
import { createFormData } from "../../utils/common";


export const fetchUsersDetails=()=>{
    return instance.get("users/getUsers")
} 
export const fetchUsersDetailsByType=(id)=>{
    let payload=createFormData({usertype:id})
    return instance.post("users/getUsersByType",payload)
} 


export const saveUsersDetails=(payload)=>{
    let _payload=createFormData(payload)
    return instance.post("users/save",_payload)
} 
export const getUserDetailsById=(payload)=>{
    return instance.post("users/getById",payload)
} 
export const deleteUserDetailsById=(payload)=>{
    let _payload=createFormData(payload)
    return instance.post("users/deleteList",_payload)
} 
export const editUsersDetails=(payload)=>{
    let _payload=createFormData(payload)
    return instance.post("users/editList",_payload)
} 
export const saveCover=(payload)=>{
   let _payload=createFormData(payload)
    return instance.post("globalvariable/upload_file",_payload)
} 


