import React, {  useRef } from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import { useDispatch } from "react-redux";
import { editVendor, saveVendor } from "../vendorSlice";
import { iconsImgs } from "../../../utils/images";

function VendorListForm({
  id,
  editItemDetails,
  isEdit,
  showModal,
  setShowModal,
}) {
  const dispatch = useDispatch();
  const modalRef = useRef(null);

  console.log(editItemDetails);

  const closeModal = () => {
    const button = document.getElementById("closeEditModalBtn");
    button.click();
  };

  const validationSchema = Yup.object({
    vendorcode: Yup.string().required("Vendor Code is required"),
    name: Yup.string().required("Name is required"),
    email: Yup.string()
      .email("Invalid email address")
      .required("Email is required"),
    mobile: Yup.string().required("Mobile is required"),
    gstnumber: Yup.string().required("GST Number is required"),
    pan: Yup.string().required("PAN is required"),
    imei: Yup.string().required("IMEI is required"),
    address: Yup.string().required("Address is required"),
  });
  const onSubmit = (values, { resetForm, setSubmitting }) => {
    if (isEdit) {
      dispatch(editVendor(values));
    } else {
      dispatch(saveVendor(values));
    }
    setSubmitting(false);
    resetForm();
    closeModal();
  };

  return (
    <div
      className="modal modal-blur fade"
      id={id}
      tabIndex="-1"
      role="dialog"
      aria-hidden="true"
      ref={modalRef}
      data-bs-backdrop="static"
    >
      <div
        className="modal-dialog modal-lg modal-dialog-centered"
        role="document"
      >
        <div className="modal-content">
          {showModal && (
            <Formik
              initialValues={{
                vendorcode: editItemDetails?.vendorcode,
                name: editItemDetails?.name,
                email: editItemDetails?.email,
                mobile: editItemDetails?.mobile,
                gstnumber: editItemDetails?.gstnumber,
                pan: editItemDetails?.pan,
                imei: editItemDetails?.imei,
                address: editItemDetails?.address,
                id: editItemDetails?.id,
              }}
              validationSchema={validationSchema}
              onSubmit={onSubmit}
            >
              {({ isSubmitting, resetForm }) => (
                <Form>
                  <div className="modal-header">
                    <h5 className="modal-title">New Vendor</h5>
                    <button
                      onClick={() => {
                        setShowModal(false);
                        resetForm();
                      }}
                      type="button"
                      className="btn-close"
                      data-bs-dismiss="modal"
                      aria-label="Close"
                    ></button>
                  </div>
                  <div className="modal-body">
                    <div className="mb-3">
                      <label htmlFor="vendorcode">Vendor Code</label>
                      <Field
                        type="text"
                        id="vendorcode"
                        name="vendorcode"
                        className="form-control"
                      />
                      <ErrorMessage
                        name="vendorcode"
                        component="div"
                        className="error"
                      />
                    </div>
                    <div className="row">
                      <div className="col-lg-6">
                        <div className="mb-3">
                          <label htmlFor="name">Name</label>
                          <Field
                            type="text"
                            id="name"
                            name="name"
                            className="form-control"
                          />
                          <ErrorMessage
                            name="name"
                            component="div"
                            className="error"
                          />
                        </div>
                      </div>
                      <div className="col-lg-6">
                    
                        <div className="mb-3">
                          <label htmlFor="email">Email</label>
                          <Field
                            type="email"
                            id="email"
                            name="email"
                            className="form-control"
                          />
                          <ErrorMessage
                            name="email"
                            component="div"
                            className="error"
                          />
                        </div>
                      </div>{" "}
                    </div>{" "}
                    <div className="row">
                      {" "}
                      <div className="col-lg-6">
                        {" "}
                        <div className="mb-3">
                          <label htmlFor="mobile">Mobile</label>
                          <Field
                            type="text"
                            id="mobile"
                            name="mobile"
                            className="form-control"
                          />
                          <ErrorMessage
                            name="mobile"
                            component="div"
                            className="error"
                          />
                        </div>
                      </div>{" "}
                      <div className="col-lg-6">
                        <div className="mb-3">
                          <label htmlFor="gstnumber">GST Number</label>
                          <Field
                            type="text"
                            id="gstnumber"
                            name="gstnumber"
                            className="form-control"
                          />
                          <ErrorMessage
                            name="gstnumber"
                            component="div"
                            className="error"
                          />
                        </div>
                      </div>{" "}
                    </div>{" "}
                  </div>{" "}
                  <div className="modal-body">
                    {" "}
                    <div className="row">
                      {" "}
                      <div className="row">
                        {" "}
                        <div className="col-lg-6">
                          <div className="mb-3">
                            <label htmlFor="pan">PAN</label>
                            <Field
                              type="text"
                              id="pan"
                              name="pan"
                              className="form-control"
                            />
                            <ErrorMessage
                              name="pan"
                              component="div"
                              className="error"
                            />
                          </div>
                        </div>{" "}
                        <div className="col-lg-6">
                          <div className="mb-3">
                            <label htmlFor="imei">IMEI</label>
                            <Field
                              type="text"
                              id="imei"
                              name="imei"
                              className="form-control"
                            />
                            <ErrorMessage
                              name="imei"
                              component="div"
                              className="error"
                            />
                          </div>
                        </div>{" "}
                      </div>{" "}
                      <div className="col-lg-12">
                        {" "}
                        <div>
                          <label htmlFor="address">Address</label>
                          <Field
                            as="textarea"
                            id="address"
                            name="address"
                            className="form-control"
                          />
                          <ErrorMessage
                            name="address"
                            component="div"
                            className="error"
                          />
                        </div>{" "}
                      </div>{" "}
                    </div>{" "}
                  </div>{" "}
                  <div className="modal-footer">
                    {" "}
                    <a
                      className="btn ouline-secondary"
                      data-bs-dismiss="modal"
                      id="closeEditModalBtn"
                      onClick={() => {
                        setShowModal(false);
                        resetForm();
                      }}
                    >
                      {" "}
                      Cancel
                    </a>
                    <button
                      disabled={isSubmitting}
                      type="submit"
                      className="btn btn-primary ms-auto text-white"
                    >
              <img src={iconsImgs.plus} width={20} height={20} alt="Add" />
                      
                      {isEdit ? "Update vendor" : "Create new vendor"}
                    </button>
                  </div>
                </Form>
              )}
            </Formik>
          )}{" "}
        </div>
      </div>
    </div>
  );
}

export default VendorListForm;
