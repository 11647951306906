import React from "react";
import Sidebar from "../components/sidebar/Sidebar";
import { Outlet, useNavigate, useParams } from "react-router-dom";
import "./HomePage.css";
import ContentTop from "../components/contentTop/ContentTop";
import { useDispatch } from "react-redux";
import { useEffect } from "react";
import { fetchGlobalSetting } from "../features/setting/globalSetting.slice";
import { setLoginUserDetails, setLoginUserToken } from "../features/auth/authSlice";

function HomePage() {
  const navigate = useNavigate();

 let { id } = useParams();
  
  useEffect(() => {

    const isTokenAvailable=localStorage.getItem("innvelToken");
    const loginUserDetailsInLocalstorage=localStorage.getItem("innvelLoginUser");
    if(!isTokenAvailable){
    navigate("/auth")
    }
    dispatch(setLoginUserToken(isTokenAvailable));
     if(loginUserDetailsInLocalstorage){
      dispatch(setLoginUserDetails(JSON.parse(loginUserDetailsInLocalstorage)))

     }
    return () => {
     

    }
  }, [id])
  

  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(fetchGlobalSetting());
  }, []);
  return (
    <div className='page'>
      <Sidebar />
      <div className="page-wrapper">
        <ContentTop />
        <div className="container-xl pt-2">
          <Outlet />
        </div>
      
      </div>

     
    </div>
  );
}

export default HomePage;
