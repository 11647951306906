import React, { useEffect, useState  } from 'react';
import { Chart as ChartJS, defaults } from "chart.js/auto";
import { Bar, Doughnut, Line } from "react-chartjs-2";
import "./VendorCommon.css";
import Styles from "./VendorAnalytics.module.css";


import revenueData from "../../../data/revenueData.json";
import sourceData from "../../../data/sourceData.json";
import {
    MDBBtn,
    MDBModal,
    MDBModalDialog,
    MDBModalContent,
    MDBModalHeader,
    MDBModalTitle,
    MDBModalBody,
  } from 'mdb-react-ui-kit';
import { ShimmerFeaturedGallery } from 'react-shimmer-effects';
import { iconsImgs } from '../../../utils/images';
import LineChart from './chartComponents/LineChart';
import BarChart from './chartComponents/BarChart';
import SideBarChart from './chartComponents/SideBarChart';



defaults.maintainAspectRatio = false;
defaults.responsive = true;

defaults.plugins.title.display = true;
defaults.plugins.title.align = "start";
defaults.plugins.title.font.size = 20;
defaults.plugins.title.color = "black";



export const  VendorAnalytics=()=> {
    const [basicModal, setBasicModal] = useState(false);
    const [showData, setShowData] = useState(false);

    const toggleOpen = () => setBasicModal(!basicModal);
    useEffect(()=>{
      setTimeout(() => {
        setShowData(true)
      }, 6000);
    },[])
  
    // return(
    //   <>
    //     <div id="chart">
    //       chart here
    //     </div>
    //   </>
    // )

    return (
    <>
     <a  title='Analytics' className="btn w-25  mx-1 btn-icon text-success" onClick={toggleOpen}>
      <img src={iconsImgs.analytics} width={20} height={20} alt="xls" />
     </a>
   
     
      <MDBModal open={basicModal} setOpen={setBasicModal} tabIndex='-1'>
        <MDBModalDialog centered size='fullscreen'>
          <MDBModalContent>
            <MDBModalHeader>
              <MDBModalTitle>Vendor Analytics</MDBModalTitle>
              <MDBBtn className='btn-close' color='none' onClick={toggleOpen}></MDBBtn>
            </MDBModalHeader>
            <MDBModalBody>
              {showData?<div className="_app">
                {/* <div className="dataCard revenueCard">
                <Line
                  data={{
                    labels: revenueData.map((data) => data.label),
                    datasets: [
                      {
                        label: "Revenue",
                        data: revenueData.map((data) => data.revenue),
                        backgroundColor: "#064FF0",
                        borderColor: "#064FF0",
                      },
                      {
                        label: "Cost",
                        data: revenueData.map((data) => data.cost),
                        backgroundColor: "#FF3030",
                        borderColor: "#FF3030",
                      },
                    ],
                  }}
                  options={{
                    elements: {
                      line: {
                        tension: 0.5,
                      },
                    },
                    plugins: {
                      title: {
                        text: "Monthly Revenue & Cost",
                      },
                    },
                  }}
                />
                  </div>

              <div className="dataCard customerCard">
                <Bar
                  data={{
                    labels: sourceData.map((data) => data.label),
                    datasets: [
                      {
                        label: "Count",
                        data: sourceData.map((data) => data.value),
                        backgroundColor: [
                          "rgba(43, 63, 229, 0.8)",
                          "rgba(250, 192, 19, 0.8)",
                          "rgba(253, 135, 135, 0.8)",
                        ],
                        borderRadius: 5,
                      },
                    ],
                  }}
                  options={{
                    plugins: {
                      title: {
                        text: "Revenue Source",
                      },
                    },
                  }}
                />
              </div>

              <div className="dataCard categoryCard">
                <Doughnut
                  data={{
                    labels: sourceData.map((data) => data.label),
                    datasets: [
                      {
                        label: "Count",
                        data: sourceData.map((data) => data.value),
                        backgroundColor: [
                          "rgba(43, 63, 229, 0.8)",
                          "rgba(250, 192, 19, 0.8)",
                          "rgba(253, 135, 135, 0.8)",
                        ],
                        borderColor: [
                          "rgba(43, 63, 229, 0.8)",
                          "rgba(250, 192, 19, 0.8)",
                          "rgba(253, 135, 135, 0.8)",
                        ],
                      },
                    ],
                  }}
                  options={{
                    plugins: {
                      title: {
                        text: "Revenue Sources",
                      },
                    },
                  }}
                />
              </div> */}

              {/* <div className={`${Styles.row}`}>
              <LineChart />
              <div className={`${Styles.cardBasicRed} row` }>
                <h2 className={`${Styles.textleft}` }>Meter Installed</h2>
                <h1 className={`${Styles.textright} ${Styles.textlarge}` }>422</h1>
              </div>
              <div className={`${Styles.cardBasicBlue} row` }>
                <h2 className={`${Styles.textleft}` }>Meter In Stock</h2>
                <h1 className={`${Styles.textright} ${Styles.textlarge} ` }>700</h1>
              </div>
              <div className={`${Styles.cardBasicRed} row` }>
                <h2 className={`${Styles.textleft}` }>Score</h2>
                <h1 className={`${Styles.textright} ${Styles.textlarge}` }>2.1</h1>
              </div>
              <BarChart />
              <div className={`${Styles.cardBasicBlue1} col-12 row` }>
                <h2 className={`${Styles.textleft}` }>Installation Approved</h2>
                <h1 className={`${Styles.textright} ${Styles.textlarge}` }>395</h1>
              </div>
              <div className={`${Styles.cardBasicBlue2} row col-12` }>
                    <h2 className={`${Styles.textleft}` }>Current Statistics</h2>
                    <div className="row">
                      <h3 className={`${Styles.textleft} col-6` }>Meter Assigned</h3>
                      <h1 className={`${Styles.textright} ${Styles.textlarge} col-6` }>450</h1>
                    </div>
                    <hr/>
                    <div className="row">
                      <h3 className={`${Styles.textleft} col-6` }>Meter Installed</h3>
                      <h1 className={`${Styles.textright} ${Styles.textlarge} col-6` }>300</h1>
                    </div>
                   
              </div>
              <div className={`${Styles.cardBasicRed1} row col-12` }>
                <h2 className={`${Styles.textleft}` }>Performance Score in Last 6 Months</h2>
                <div className="row">
                  <h1 className={`${Styles.textright} ${Styles.textlarge} col-12` }>3.90</h1>
                </div>
              </div>
              <SideBarChart/>
              </div> */}

              <div className={`${Styles.row} row`}>
                <div className="col-lg-8 row">
                  <div className="col-lg-12">
                    <LineChart />
                  </div>
                  <div className="col-lg-12 row">
                    <div className="col-lg-4">
                      <div className={`${Styles.cardBasicRed}` }>
                        <h2 className={`${Styles.textleft}` }>Meter Installed</h2>
                        <h1 className={`${Styles.textright} ${Styles.textlarge}` }>422</h1>
                      </div>
                      <div className={`${Styles.cardBasicBlue}` }>
                        <h2 className={`${Styles.textleft}` }>Meter In Stock</h2>
                        <h1 className={`${Styles.textright} ${Styles.textlarge} ` }>700</h1>
                      </div>
                      <div className={`${Styles.cardBasicRed}` }>
                        <h2 className={`${Styles.textleft}` }>Score</h2>
                        <h1 className={`${Styles.textright} ${Styles.textlarge}` }>2.1</h1>
                      </div>
                    </div>
                    <div className="col-lg-8">
                      <BarChart />
                      <div className={`${Styles.cardBasicBlue1}` }>
                        <h2 className={`${Styles.textleft}` }>Installation Approved</h2>
                        <h1 className={`${Styles.textright} ${Styles.textlarge}` }>395</h1>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-4">
                  <div className={`${Styles.cardBasicBlue2}` }>
                      <h2 className={`${Styles.textleft}` }>Current Statistics</h2>
                      <div className="row">
                        <h3 className={`${Styles.textleft} col-6` }>Meter Assigned</h3>
                        <h1 className={`${Styles.textright} ${Styles.textlarge} col-6` }>450</h1>
                      </div>
                      <hr/>
                      <div className="row">
                        <h3 className={`${Styles.textleft} col-6` }>Meter Installed</h3>
                        <h1 className={`${Styles.textright} ${Styles.textlarge} col-6` }>300</h1>
                      </div>
                    
                  </div>
                  <div className={`${Styles.cardBasicRed1} row col-12` }>
                    <h2 className={`${Styles.textleft}` }>Performance Score in Last 6 Months</h2>
                    <div className="row">
                      <h1 className={`${Styles.textright} ${Styles.textlarge} col-12` }>3.90/5</h1>
                    </div>
                  </div>
                  <SideBarChart/>
                  
                </div>
                
                


              </div>
              
              </div>:<ShimmerFeaturedGallery row={1} col={2} card />}
                    

            </MDBModalBody>

           
          </MDBModalContent>
        </MDBModalDialog>
      </MDBModal>
    </>
        
    )
  
}

export default VendorAnalytics