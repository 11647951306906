export const  createFormData=(item)=>{
    var form_data = new FormData();

    for ( var key in item ) {
        form_data.append(key, item[key]);
    }
    return form_data
}

// Debounce function
export function debounce(func, delay) {
    let timeoutId;
  
    return function() {
      const context = this;
      const args = arguments;
  
      clearTimeout(timeoutId);
      timeoutId = setTimeout(() => {
        func.apply(context, args);
      }, delay);
    };
  }

  export  const groupByKey = (arr, key) =>
  arr.reduce((acc, obj) => {
    const group = acc[obj[key]] || [];
    group.push(obj);
    acc[obj[key]] = group;
    return acc;
  }, {});

  // Function to convert flat data to nested structure
export const convertToNested = (data) => {
  const nestedData = [];
  const tempMap = {};
  
  data.forEach(item => {
    const newItem = { ...item };
    const parent = tempMap[item.type];
    if (parent) {
      parent.children = parent.children || [];
      parent.children.push(newItem);
    } else {
      nestedData.push(newItem);
    }
    tempMap[item.id] = newItem;
  });
  
  return nestedData;
};


export function filterAndGroupById(data) {
  const result = {};

  // Iterate through the data array
  data.forEach(item => {
      // Check if the item's type is 1
      if (item.type === "1") {
          // Create an array for the key if it doesn't exist
          if (!result[item.id]) {
              result[item.id] = [];
          }

          // Push the item to the array
          // result[item.id].push(item);

          // Find items matching this key's type and add them to the array
          const matchingItems = data.filter(subItem => subItem.type === item.id);
          result[item.id] = result[item.id].concat(matchingItems);
      }
  });

  return result;
}

export function getMatchingItemsById(data, id) {

  const matchingItems = data.filter(item => item.type == id);
  const result = matchingItems.map(item => {
      return {
          id: item.id,
          title: item.name,
          to: `../users/${item.id}`
      };
  });
  return result;
}


export const formatDate = (dateString) => {
  const date = new Date(dateString);
  const options = { year: 'numeric', month: 'long', day: 'numeric', hour: 'numeric', minute: 'numeric', second: 'numeric', hour12: true };
  return date.toLocaleString('en-US', options);
};
