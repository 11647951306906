import ReactApexChart from "react-apexcharts";

function SideBarChart() {
    const series = [
        {
          name: "Rejection Reasons",
          data: [430, 310, 250],
        },
      ];
      
    const options = {
        chart: {
            type: 'bar',
            height: 350,
            width: "100%",
        },
        plotOptions: {
            bar: {
              borderRadius: 4,
              horizontal: true,
              distributed: true

            },
            
        },
        dataLabels: {
            enabled: false,
            style: {
                fontSize: '12px',
                colors: ["#304758"]
              }
        },
        xaxis: {
            categories: ['Reason 1', 'Reason 2', 'Reason 3'
            ],
        },
        

        
    }
      
  return (
    <div id="chart">
      <ReactApexChart options={options} series={series} type="bar" height={295}/>
    </div>
  );
}

export default SideBarChart;
