import React, { useEffect, useState } from "react";
import { iconsImgs, personsImgs } from "../../utils/images";
import JSONToExcel from "../../components/JsonToExcel";
import SearchBox from "../../components/SearchBox";
import ShimmerUITable from "../../components/shimmerUi/ShimmerTable";
import { useDispatch, useSelector } from "react-redux";
import DeleteDialog from "../../components/DeleteDialog";
import { errorMessage, successMessage } from "../../utils/tostify";
import { debounce } from "../../utils/common";
import { deleteOEM, fetchOEM, setOEMFilter } from "./oemSlice";
import OEMForm from "./OEMForm";
import { ActiveInactive, ActiveInactiveID } from "../../data/dropdown";
import Pagination from "../../components/Pagination";
const initialValues = {
  id: "",
  name: "",
  address: "",
  contactnumber: "",
  email: "",
  logo: "",
  status: "",
};

function OEM() {
  const { dropdownList } = useSelector((state) => state.globalSetting);
  const dispatch = useDispatch();
  const [isEdit, setIsEdit] = useState(false);
  const [editItemDetails, setEditItemDetails] = useState({});
  const [deletedId, setDeletedId] = useState(null);
  const {
    OEM: oemList,
    loading,
    isDeletedSuccessful,
    isSavedSuccessful,
    filterOEMListData,
    error,
  } = useSelector((state) => state.oem);

  const [showModal, setShowModal] = useState(false);
  let message = isEdit ? "OEM Updated Successfully" : "OEM Added Successfully";
  useEffect(() => {
    if (isDeletedSuccessful) {
      dispatch(fetchOEM());
      successMessage("OEM Deleted Successfully");
    } else if (isSavedSuccessful) {
      dispatch(fetchOEM());
      successMessage(message);
    }

    return () => {};
  }, [isDeletedSuccessful, isSavedSuccessful]);

  useEffect(() => {
    if(oemList?.length>0) return
    dispatch(fetchOEM());
  }, []);
  useEffect(() => {
    console.log(error);
    if (error) {
      errorMessage(error);
    }
  }, [error]);
  const onDelete = (cb) => {
    dispatch(deleteOEM({ id: deletedId }));
    cb();
  };
  const filterData = (query, keys) => {
    const filteredData = filterOEMListData.filter((item) => {
      return keys.some((key) =>
        item[key].toLowerCase().includes(query.toLowerCase())
      );
    });
    dispatch(setOEMFilter(filteredData));
    console.log(filteredData);
  };

  const debouncedFilterData = debounce(filterData, 800);

  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemPerPage] = useState(5);
  const itemsPerPageList = [
    { id: 1, name: "5", value: 5 },
    { id: 2, name: "10", value: 10 },
    { id: 3, name: "15", value: 15 },
  ];

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
    const startIndex = (pageNumber - 1) * itemsPerPage;
    const endIndex = Math.min(startIndex + itemsPerPage, filterOEMListData.length);
  
    let filteredData = filterOEMListData.slice(startIndex, endIndex);
    dispatch(setOEMFilter(filteredData));
  };

  useEffect(() => {
    debugger
    handlePageChange(currentPage);

    return () => {};
  }, [itemsPerPage, filterOEMListData]);

  return (
  
    <div>
      <div className="card">
        {/* Page Header */}
        <div className="card-header d-flex justify-content-between">
          <h3 className="card-title">OEM Master Table</h3>
          <div>
            <a
              className="btn btn-bitbucket text-white"
              data-bs-toggle="modal"
              data-bs-target="#oemFormModal"
              onClick={(e) => {
                setShowModal(true);
                setEditItemDetails(initialValues);
                setIsEdit(false);
              }}
            >
              <img src={iconsImgs.plus} width={20} height={20} alt="Add" />
              Add
            </a>

            <JSONToExcel data={oemList} fileName="oem" />
          </div>
        </div>

        <div className="card-body border-bottom py-3">
          <div className="d-flex">
            <div className="text-muted">
              Show
              <div className="mx-2 d-inline-block">
              <select
                  className="form-control "
                  onChange={(e) =>{
                    debugger
                    setItemPerPage(e.target.value)
                  } }
                >
                  {itemsPerPageList.map((item, i) => (
                    <option
                      className="dropdown-item cursor-pointer"
                      value={item.value}
                    >
                      {item.name}
                    </option>
                  ))}
                </select>
                </div>
              entries
            </div>
            <div className="ms-auto text-muted">
              <SearchBox
                onChange={(e) => {
                  debouncedFilterData(e.target.value, [
                    "name",
                    "address",
                    "contactnumber",
                    "email",
                  ]);
                }}
              />
            </div>
          </div>
        </div>
        {loading ? (
          <ShimmerUITable row={4} />
        ) : (
          <div className="table-responsive">
            <table className="table card-table table-vcenter text-nowrap datatable">
              <thead>
                <tr>
                  {/* <th className="w-1">Id.</th> */}
                  <th>Logo</th>
                  <th>Status</th>

                  <th>Name</th>
                  <th>Address</th>
                  <th>Contact Number</th>
                  <th>Email</th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
              
                {oemList?.map((oem) => {
                  return (
                    <tr key={oem.id}>
                      {/* <td>
                        <span className="text-muted">{oem.id}</span>
                      </td> */}
                      <td>
                      <img src={`http://apiinnovel.apiitech.co.in/uploads/${oem.logo}`}
                      onError={(e)=>e.target.src =personsImgs.person_three}
                      className="rounded-circle" style={{width:40}} alt="Avatar" />
                      
                      </td>
                    
  <td>
                        {ActiveInactiveID.active.includes(Number(oem.status)) ? (
                          <span className="status-dot status-dot-animated  bg-success me-2"></span>
                        ) : (
                          <span className=" status-dot status-dot-animated  bg-danger me-2"></span>
                        )}

                        {ActiveInactive[oem.status] || "Others"}
                      </td>


                      <td>{oem.name}</td>
                      <td>{oem.address}</td>
                      <td>{oem.contactnumber}</td>
                      <td>{oem.email}</td>
                     
                      <td className="text-end">
                        <a
                          className="btn  w-25 text-white btn-icon mx-2 text-primary"
                          aria-label="Facebook"
                          data-bs-toggle="modal"
                          data-bs-target="#oemFormModal"
                          title="Edit"
                          onClick={() => {
                            setEditItemDetails(oem);
                            setIsEdit(true);
                            setShowModal(true);
                          }}
                        >
                          <img
                            src={iconsImgs.edit}
                            width={20}
                            height={20}
                            alt=""
                          />
                        </a>
                        <a
                          data-bs-toggle="modal"
                          data-bs-target="#oem-delete"
                          className="btn w-25  mx-2 btn-icon text-danger"
                          aria-label="delete"
                          title="Delete"
                          onClick={() => {
                            setDeletedId(oem.id);
                          }}
                        >
                          <img
                            color="red"
                            src={iconsImgs.deleteIcon}
                            width={20}
                            height={20}
                            alt=""
                          />
                        </a>
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
        )}
        <Pagination
            totalItems={filterOEMListData?.length}
            itemsPerPage={itemsPerPage}
            currentPage={currentPage}
            onPageChange={handlePageChange}
          />
      </div>
      <DeleteDialog
        modalId="oem-delete"
        onDeleteCall={onDelete}
        closeId={"deleteNoButtonOEM"}
        message={
          "Are you sure you want to delete this item? This action cannot be undone."
        }
      />
      <OEMForm
        id="oemFormModal"
        editItemDetails={editItemDetails}
        isEdit={isEdit}
        setShowModal={setShowModal}
        showModal={showModal}
        dropdownList={dropdownList}
      />


    </div>
  );
}

export default OEM;