import React, { useEffect, useRef, useState } from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import { useDispatch } from "react-redux";
import { editTechnician, saveTechnician, uploadDocs } from "./technicianSlice";
import { iconsImgs } from "../../utils/images";
import { OptionsMaster } from "../../data/dropdown";

function TechnicianListForm({
  id,
  editItemDetails,
  isEdit,
  showModal,
  setShowModal,
  dropdownList,
}) {
  const dispatch = useDispatch();
  const modalRef = useRef(null);
  const imgRef = useRef(null);
  const [files, setFiles] = useState({});

  useEffect(() => {
    if(editItemDetails.files){
      setFiles(JSON.parse(editItemDetails.files))
    }
   
  
    return () => {

    }
  }, [editItemDetails])
  
  

  const closeModal = () => {
    const button = document.getElementById("closeEditModalBtn");
    button.click();
  };

  const validationSchema = Yup.object({
    name: Yup.string().required("Name is required"),
    vendor: Yup.string().required("Vendor is required"),
    supervisor: Yup.string().required("Supervisor is required"),
    email: Yup.string()
      .email("Invalid email address")
      .required("Email is required"),
    mobile: Yup.string().required("Mobile is required"),
    imei: Yup.string().required("IMEI is required"),
    address: Yup.string().required("Address is required"),
  });

  const onSubmit = async (values, { resetForm, setSubmitting }) => {
   let _files={}
  for (const key in files) {
    if (Object.hasOwnProperty.call(files, key)) {
      const element = files[key];
      if(typeof element!="string"){
        const originalPromiseResult = await dispatch(
          uploadDocs({ userfile: element })
        ).unwrap();
        _files[key]=originalPromiseResult.data.data
      }
      else{
        _files[key]=element
      }
   
    }
  }

    values.files= JSON.stringify(_files);

    if (isEdit) {
      dispatch(editTechnician(values));
    } else {
      dispatch(saveTechnician(values));
    }
    setSubmitting(false);
    resetForm();
    closeModal();
  };

  return (
    <div
      className="modal modal-blur fade"
      id={id}
      tabIndex="-1"
      role="dialog"
      aria-hidden="true"
      ref={modalRef}
      data-bs-backdrop="static"
    >
      <div
        className="modal-dialog modal-lg modal-dialog-centered"
        role="document"
      >
        <div className="modal-content">
          {showModal && (
            <Formik
              initialValues={{
                name: editItemDetails?.name,
                vendor: editItemDetails?.vendor,
                supervisor: editItemDetails?.supervisor,
                email: editItemDetails?.email,
                mobile: editItemDetails?.mobile,
                imei: editItemDetails?.imei,
                address: editItemDetails?.address,
                id: editItemDetails?.id,
                status: editItemDetails?.status,
                // files: editItemDetails?.file ,
                // files: JSON.stringify({pan:"",aadhar:"",profile:""}),
              }}
              validationSchema={validationSchema}
              onSubmit={onSubmit}
            >
              {({ isSubmitting, resetForm }) => (
                <Form ref={imgRef}>
                  <div className="modal-header">
                    <h5 className="modal-title">New Technician</h5>
                    <button
                      onClick={() => {
                        setShowModal(false);
                        resetForm();
                        setFiles({})
                      }}
                      type="button"
                      className="btn-close"
                      data-bs-dismiss="modal"
                      aria-label="Close"
                    ></button>
                  </div>
                  <div className="modal-body">
                    <div className="row">
                      <div className="col-lg-6">
                        <div className="mb-3">
                          <label htmlFor="name">Name</label>
                          <Field
                            type="text"
                            id="name"
                            name="name"
                            className="form-control"
                          />
                          <ErrorMessage
                            name="name"
                            component="div"
                            className="error"
                          />
                        </div>
                      </div>
                      <div className="col-lg-6">
                        <div className="mb-3">
                          <label htmlFor="imei">IMEI</label>
                          <Field
                            type="text"
                            id="imei"
                            name="imei"
                            className="form-control"
                          />
                          <ErrorMessage
                            name="imei"
                            component="div"
                            className="error"
                          />
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-lg-6">
                        <div className="mb-3">
                          <label htmlFor="vendor">Vendor</label>
                          <Field
                            type="text"
                            id="vendor"
                            name="vendor"
                            className="form-control"
                          />
                          <ErrorMessage
                            name="vendor"
                            component="div"
                            className="error"
                          />
                        </div>
                      </div>
                      <div className="col-lg-6">
                        <div className="mb-3">
                          <label htmlFor="supervisor">Supervisor</label>
                          <Field
                            type="text"
                            id="supervisor"
                            name="supervisor"
                            className="form-control"
                          />
                          <ErrorMessage
                            name="supervisor"
                            component="div"
                            className="error"
                          />
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-lg-6">
                        <div className="mb-3">
                          <label htmlFor="email">Email</label>
                          <Field
                            type="email"
                            id="email"
                            name="email"
                            className="form-control"
                          />
                          <ErrorMessage
                            name="email"
                            component="div"
                            className="error"
                          />
                        </div>
                      </div>
                      <div className="col-lg-6">
                        <div className="mb-3">
                          <label htmlFor="mobile">Mobile</label>
                          <Field
                            type="text"
                            id="mobile"
                            name="mobile"
                            className="form-control"
                          />
                          <ErrorMessage
                            name="mobile"
                            component="div"
                            className="error"
                          />
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-lg-6">
                        <div className="mb-3">
                          <label htmlFor="status">Status</label>
                          <Field
                            as="select"
                            id="status"
                            name="status"
                            className="form-control"
                          >
                            <option value="">Select Status</option>

                            {dropdownList[OptionsMaster.status].map((item) => {
                              return (
                                <option value={item.id} key={item.id}>
                                  {item.name}
                                </option>
                              );
                            })}
                          </Field>

                          <ErrorMessage
                            name="status"
                            component="div"
                            className="error"
                          />
                        </div>
                      </div>
                      <div className="col-lg-6">
                        <div
                          style={{
                            padding: 10,
                            border: "2px dashed red",
                            borderRadius: 10,
                            backgroundColor: "#fff4f4",
                          }}
                        >
                          {dropdownList[OptionsMaster.documents].map((item) => {
                            return (
                              <div className="d-flex my-1">
                                <span className="m-1 w-25 fw-bold">
                                  {item.name}
                                </span>
                                <input
                                  name={item.name.toLowerCase()}
                                  accept="image/*"
                                  onChange={(e) => {
                                    
                                    let form_data = new FormData(
                                      imgRef.current
                                    );
                                    setFiles({
                                      ...files,
                                      [e.target.name]: form_data.get(
                                        item.name.toLowerCase()
                                      ),
                                    });
                                  }}
                                  className="form-control"
                                  type="file"
                                />
                              </div>
                            );
                          })}
                          <div className="d-flex m-3 align-items-center justify-content-center">
                            
                          {Object.keys(files).map((item,i) => {
                           
                            return (<div key={i} className="mx-2 border border-info  rounded p-1 w-25 d-flex flex-column align-items-center">
                     
                              <a target="_blank" href={isEdit?
                                  
                                  (typeof files[item])=="object"?
                                  URL.createObjectURL(files[item])
                                  :`http://apiinnovel.apiitech.co.in/uploads/${files[item]}` 
                                  : URL.createObjectURL(files[item])}><img
                                src={isEdit?
                                  
                                (typeof files[item])=="object"?
                                URL.createObjectURL(files[item])
                                :`http://apiinnovel.apiitech.co.in/uploads/${files[item]}` 
                                : URL.createObjectURL(files[item])}
                                className="rounded-circle"
                                style={{ width: 40, height: 40 }}
                                alt="Avatar"
                              />
                              </a>
                              <h4 className="text-center text-capitalize my-2">{item}</h4>

                             </div>
                            );
                          })}
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-lg-12">
                        <div>
                          <label htmlFor="address">Address</label>
                          <Field
                            as="textarea"
                            id="address"
                            name="address"
                            className="form-control"
                          />
                          <ErrorMessage
                            name="address"
                            component="div"
                            className="error"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="modal-footer">
                    <a
                      className="btn ouline-secondary"
                      data-bs-dismiss="modal"
                      id="closeEditModalBtn"
                      onClick={() => {
                        setShowModal(false);
                        resetForm();
                      }}
                    >
                      Cancel
                    </a>
                    <button
                      disabled={isSubmitting}
                      type="submit"
                      className="btn btn-primary ms-auto text-white"
                    >
                      <img
                        src={iconsImgs.plus}
                        width={20}
                        height={20}
                        alt="Add"
                      />
                      {isEdit ? "Update technician" : "Create new technician"}
                    </button>
                  </div>
                </Form>
              )}
            </Formik>
          )}
        </div>
      </div>
    </div>
  );
}

export default TechnicianListForm;
