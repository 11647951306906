// Import Axios library
import axios from 'axios';

// Create a new instance of Axios
const instance = axios.create({
  baseURL: 'http://apiinnovel.apiitech.co.in/', // Set base URL for API requests
  timeout: 10000, // Set request timeout (in milliseconds)
  headers: {
    // 'Content-Type': 'application/json', // Set default content type
    // You can add more default headers here if needed
  },
});

// Optionally, you can add request interceptors to modify requests before they are sent
instance.interceptors.request.use(
  (config) => {
    // Do something with the request data before sending it
    // For example, you can add authentication tokens, modify headers, etc.
    config.headers['Basic'] = 'innovelapi';
    return config;
  },
  (error) => {
    // Handle request error
    return Promise.reject(error);
  }
);

// Optionally, you can add response interceptors to modify responses before they are handled
instance.interceptors.response.use(
  (response) => {
    // Do something with the response data before returning it
    // For example, you can parse response data, handle errors, etc.
    return response;
  },
  (error) => {
    // Handle response error
    return Promise.reject(error);
  }
);

// Export the Axios instance so that it can be imported and used throughout your application
export default instance;
